<pm-review #pmReview *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-title>{{getPageTitle()}}</pm-title>
  <pm-app-reject-status-header [candidate]="candidate"></pm-app-reject-status-header>
  <div *ngIf="candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages.length > 0 ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div class="upc-header">
            UPC: {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
          </div>
          <div class="attribute-description">
            <p>{{candidateProduct.description}}</p>
            <p>{{getProductInfoString()}}</p>
          </div>
        </div>
      </div>

      <div *ngIf="isNonReplenishItem" class="row pb-3 ml-0" style="padding-top: 32px;">
        <div class="col-md-9 pending-candidates-box">
          <h6 class="ml-2 mt-3">
            Non-replenish item
          </h6>
          <p class="ml-2">
            The supplier set up this UPC as a non-replenishable item in an MRT. This UPC doesn’t have a case and H-E-B will only receive it in the MRT.
          </p>
          <div class="ml-2 attribute-description">
            <p> MRT: {{mrtParentDescription}} | Case UPC: {{candidateProduct.caseUpc}}</p>
          </div>
        </div>
      </div>

      <!--   cost & retail  -->
      <pm-attribute-grid [title]="'Cost & retail'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate?.masterListCost) | emptyToValue : '&#8212;'}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate?.innerListCost) | emptyToValue : '&#8212;'}}<br>
                Penny profit: {{costService.getPennyProfit(candidate) | emptyToValue : '&#8212;'}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isMarginNegativeOrZero(candidate)}">{{costService.getMarginString(candidate) | emptyToValue : '&#8212;'}}</a>
                <img *ngIf="costService.isMarginNegativeOrZero(candidate)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP">
              </pm-grid-cell-info>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate?.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Cost linked'" [canEdit]="false">
              {{candidate?.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="false">
              {{candidate?.costLink | emptyToValue : '&#8212;'}}
            </pm-grid-cell>

          </pm-grid-column>

          <pm-grid-column *ngIf="candidateUtilService.isSellable(candidate)">
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="false">
              {{costService.getRetailPricing(candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="false">
              {{candidate.retailType | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{costService.getHebPennyProfit(candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              {{costService.getHebMargin(candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column *ngIf="candidateUtilService.isSellable(candidate)">
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false">
              {{costService.getSuggestedRetailPricing(candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false">
              {{candidate?.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false">
              {{candidate?.mapRetail ? costService.toCurrency(candidate?.mapRetail) : '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>

          </pm-grid-column>

          <pm-grid-column *ngIf="!candidateUtilService.isSellable(candidate)">
            <pm-grid-cell [label]="'Retail pricing'">
              N/A
              <pm-grid-cell-info>
                Retail fields don't apply to non-sellable products
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>


        </pm-grid-group>
      </pm-attribute-grid>


      <pm-attribute-grid *ngIf="showCasePackInfo()" [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="false"
                          (editClicked)="editCaseDescription(attributeType.CaseDescription)"
                          [errorMessages]="[candidateProductError?.caseDescription]">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false"
                          (editClicked)="showEditCandidateProductModal(attributeType.CountryOfOrigin)"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [errorMessage]="candidateProductError?.vendorProductCode">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <!--   Master Pack   -->
      <pm-attribute-grid *ngIf="showCasePackInfo()" [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidate.masterLength}}in x
              {{candidate.masterWidth}}in x
              {{candidate.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterLength,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="codeDateEditable()" [errorMessage]="candidateError?.codeDate" (editClicked)="editCandidate(attributeType.CodeDate)">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.maxShelfLife" (editClicked)="editCandidate(attributeType.MaxShelfLife)">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.inboundSpecDays"  (editClicked)="editCandidate(attributeType.InboundSpecDays)">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <!--  inner packs -->
      <pm-attribute-grid *ngIf="showCasePackInfo()" [title]="'Inner packs'">
        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false">
              {{candidate.innerPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidate.innerLength}}in x
              {{candidate.innerWidth}}in x
              {{candidate.innerHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group *ngIf="!candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false">
              {{candidate.masterPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidate.masterLength}}in x
              {{candidate.masterWidth}}in x
              {{candidate.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterLength,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="candidate.displayReadyUnit && candidateUtilService.isSellable(candidate)" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false">
              {{candidate.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false">
              {{candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false" [errorMessage]="candidateError?.brand"  (editClicked)="editBrandModal()">
              {{candidate.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false" [errorMessage]="candidateProductError?.subBrand">
              {{candidateProduct.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false" [errorMessage]="candidateError?.costOwner"  (editClicked)="editCostOwner()">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false" [errorMessage]="candidateError?.topToTop"   (editClicked)="editCostOwner()">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="!isNonReplenishItem" [label]="'Warehouse supplier'" [canEdit]="false">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false" [errorMessage]="candidateError?.buyer" (editClicked)="editBuyer()">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>

            <pm-grid-cell [label]="'Sellable'" [canEdit]="false">
              {{getSellableString()}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid *ngIf="candidateUtilService.isSellable(candidate)" [title]="'Product line, packaging, & regulatory info'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false">
              {{candidate.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false">
              {{candidate.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'FSA/HSA eligible'">
              {{candidate.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidate.productLength}}in x
              {{candidate.productWidth}}in x
              {{candidate.productHeight}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line & Packaging  -->
      <pm-attribute-grid *ngIf="!candidateUtilService.isSellable(candidate)" [title]="'Product line & Packaging'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidate.productLength}}in x
              {{candidate.productWidth}}in x
              {{candidate.productHeight}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false">
              {{candidate.totalVolume}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid *ngIf="candidateUtilService.isSellable(candidate)" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
              {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false" [errorMessage]="candidateProductError?.description"  (editClicked)="editProductDescription()">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false" [errorMessage]="candidateProductError?.customerFriendlyDescription1"  (editClicked)="editCandidateProduct(attributeType.ShelfTag1)">
              {{candidateProduct.customerFriendlyDescription1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false" [errorMessage]="candidateProductError?.customerFriendlyDescription2"  (editClicked)="editCandidateProduct(attributeType.ShelfTag2)">
              {{candidateProduct.customerFriendlyDescription2}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false"  [errorMessage]="candidateProductError?.romanceCopy" (editClicked)="editCandidateProduct(attributeType.ECommCopy)">
              {{candidateProduct.romanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div *ngIf="!!productImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!productImages?.length">
                {{candidateUtilService.getImageLinksOmissionReasons(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid *ngIf="!candidateUtilService.isSellable(candidate)" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
              {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false" [errorMessage]="candidateProductError?.description"  (editClicked)="editProductDescription()">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div *ngIf="!!productImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!productImages?.length">
                {{candidateUtilService.getImageLinksOmissionReasons(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <div *ngIf="showMatAttributes">
        <div *ngIf="candidateUtilService.isInProgressCandidate(candidate)">
          <!--  Global Attributes    -->
          <div>
            <pm-attribute-grid [title]="'Extended attributes: Product'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid [title]="'Extended attributes: UPC'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="candidate?.warehouseSwitch" [title]="'Extended attributes: Case'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>

          <!--   Mat Hierarchy   -->
          <div>
            <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(candidate)?.length" [title]="'Category attributes'">
              <div style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
            </pm-attribute-grid>
            <div *ngIf="!!hierarchyNumberToAttributesMap?.size">
              <div *ngFor="let hierarchy of candidate.candidateProducts[0].matHierarchyList">
                <pm-attribute-grid *ngIf="hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
                  <pm-grid-group>
                    <pm-grid-column>
                      <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>

                  </pm-grid-group>
                </pm-attribute-grid>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!candidateUtilService.isInProgressCandidate(candidate)">
          <div>
            <pm-attribute-grid *ngIf="!!productAttributes?.length" [title]="'Extended attributes: Product'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="!!upcAttributes?.length" [title]="'Extended attributes: UPC'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && !!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>


          <!--   Mat Hierarchy   -->
          <div *ngIf="!!candidate?.candidateProducts[0]?.matHierarchyList?.length">
            <div *ngFor="let hierarchy of candidate.candidateProducts[0].matHierarchyList">
              <pm-attribute-grid *ngIf="!!hierarchy.attributes?.length" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>
      </div>


      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="false"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.buyerComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate.supplierComment">
                <b>{{candidate.contactEmail}}</b> <br>
                {{candidate.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>

  <pm-footer>
    <p-button class="ghost m-2" label="Back to home" (onClick)="onClickBackToHome()"></p-button>
    <p-button class="m-2" label="Print" (onClick)="onClickPrint()"></p-button>
  </pm-footer>
</pm-review>
