import {Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output} from '@angular/core';

import {CandidateError} from 'pm-models';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-new-warehouse-product-stepper',
  templateUrl: './new-warehouse-product-stepper.component.html',
  styleUrls: ['./new-warehouse-product-stepper.component.scss']
})
export class NewWarehouseProductStepperComponent implements OnInit {
  private errorCandidateDiffer: KeyValueDiffer<string, any>;

  private readonly errorToolTip = 'This page contains errors or is missing information in required fields.';
  private readonly errorIcon = 'warning';

  @Input()
  candidateErrors: CandidateError[];

  @Input()
  isSellable: boolean;

  @Input()
  stepIndex: number;

  @Output() indexClick = new EventEmitter<any>();

  public static UPC_ITEM_CODE = 'UPC/Item code';
  public static SUPPLIER_HEB_SETUP = 'Supplier & H-E-B setup';
  public static PRODUCT_DETAILS = 'Product details';
  public static CASE_PACK = 'Case pack';
  public static EXTENDED_ATTRIBUTES = 'Extended attributes';
  public static REVIEW_PAGE = 'Review';

  steps;

  constructor(private differs: KeyValueDiffers, private permissionService: NgxPermissionsService) {}

  ngOnInit() {
    if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.steps = [{ text: NewWarehouseProductStepperComponent.UPC_ITEM_CODE }, { text: NewWarehouseProductStepperComponent.SUPPLIER_HEB_SETUP },
        { text: NewWarehouseProductStepperComponent.PRODUCT_DETAILS }, { text: NewWarehouseProductStepperComponent.CASE_PACK },
        { text: NewWarehouseProductStepperComponent.EXTENDED_ATTRIBUTES }, { text: NewWarehouseProductStepperComponent.REVIEW_PAGE }];
    } else {
      this.steps = [{ text: NewWarehouseProductStepperComponent.UPC_ITEM_CODE }, { text: NewWarehouseProductStepperComponent.SUPPLIER_HEB_SETUP },
        { text: NewWarehouseProductStepperComponent.PRODUCT_DETAILS }, { text: NewWarehouseProductStepperComponent.CASE_PACK },
        { text: NewWarehouseProductStepperComponent.REVIEW_PAGE }];
    }
  }

  /**
   * Change detection that compares candidate errors with previous version.
   * If the candidate differ doesn't exist, it creates it
   * Runs apply errors if any changes have been detected
   * @memberof CandidateCreationStepperComponent
   */
  ngDoCheck(): void {
    let candidateHasChanges = false;

    if (this.errorCandidateDiffer) {
      candidateHasChanges = this.errorCandidateDiffer.diff(this.candidateErrors) ? true : false;
    } else if (this.candidateErrors) {
      this.errorCandidateDiffer = this.differs.find(this.candidateErrors).create();
    }

    if (candidateHasChanges) {
      this.applyErrors();
    }
  }

  /**
   * Runs through each step of menu and runs validation on each step
   *
   * @memberof CandidateCreationStepperComponent
   */
  applyErrors() {
    this.steps.forEach((x, i) => {
      switch (i) {
        case 0:
          this.validateNewProductSetup(x, this.candidateErrors[i]);
          break;
        case 1:
          this.validateSupplierHEBSetup(x, this.candidateErrors[i]);
          break;
        case 2:
          this.validateProductDetails(x, this.candidateErrors[i]);
          break;
        case 3:
          this.validateCasePack(x, this.candidateErrors[i]);
          break;
        case 4:
          this.validateExtendedAttributes(x, this.candidateErrors[i]);
          break;
      }
    });
  }

  /**
   * Validation for first screen
   *
   * @param {*} stepItem
   * @memberof CandidateCreationStepperComponent
   */
  validateNewProductSetup(stepItem, candidateErrors) {
    const candidateKeys = ['description', 'warehouseSwitch', 'dsdSwitch', 'productType'];
    const candidateProductKeys = ['upc', 'categorySelectionError'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, candidateErrors);
  }

  /**
   * Vaidation for Supplier Setup
   *
   * @param {*} stepItem - Menu Item
   * @memberof CandidateCreationStepperComponent
   */
  validateSupplierHEBSetup(stepItem, candidateErrors) {
    const candidateKeys = ['buyer', 'brand', 'subBrand', 'vendor', 'lane'];
    const candidateProductKeys = [];
    if (!this.isSellable) {
      candidateProductKeys.push('description');
    }
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, candidateErrors);
  }

  /**
   * Validation for third step, product details
   *
   * @param {*} stepItem - Menu Item
   * @memberof CandidateCreationStepperComponent
   */
  validateProductDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'suggestedXFor',
      'suggestedRetailPrice',
      'mapRetail',
      'packageType',
      'productWidth',
      'productHeight',
      'productLength',
      'productWeight',
      'unitOfMeasure',
      'retailSize',
      'totalVolume',
      'retailLink'
    ];
    const candidateProductKeys = [
      'customerFriendlyDescription1',
      'customerFriendlyDescription2',
      'romanceCopy',
      'imageLinks',
      'imageLinksOmissionDate',
      'labelInsightsImageLinks',
      'termsAndConditions',
      'depositScanCodeId',
      'depositScanCodeCheckDigit'
    ];
    if (this.isSellable) {
      candidateProductKeys.push('description');
    }
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, candidateErrors);
  }

  /**
   * Runs validation for Final Case pack step
   *
   * @param {*} stepItem - Menu Item
   * @memberof CandidateCreationStepperComponent
   */
  validateCasePack(stepItem, candidateErrors) {
    const candidateKeys = [
      'itemWeightType',
      'maxShelfLife',
      'inboundSpecDays',
      'masterPack',
      'masterWidth',
      'masterHeight',
      'masterLength',
      'masterWeight',
      'cubeAdjustedFactor',
      'vendorTie',
      'vendorTier',
      'innerPackSelected',
      'innerPack',
      'innerWidth',
      'innerHeight',
      'innerLength',
      'innerWeight',
      'displayReadyUnit',
      'displayReadyUnitRowsDeep',
      'displayReadyUnitRowsHigh',
      'displayReadyUnitRowsFacing',
      'displayReadyUnitOrientation',
      'costLink',
      'masterListCost',
      'innerListCost',
      'unitCost',
      'innerCost',
      'pennyProfit',
      'marginPercent',
      'attachments'
    ];
    const candidateProductKeys = [
      'vendorProductCode',
      'countryOfOrigin',
      'upc',
      'caseUpc',
      'caseDescription',
      'caseUpcCheckDigit'];

    this.validateStep(stepItem, candidateKeys, candidateProductKeys, candidateErrors);
  }

  validateExtendedAttributes(stepItem, candidateErrors) {
    this.validateStep(stepItem, [], [], candidateErrors, true);
  }

  /**
   * Validates step with associated keys, applies icon and error message if found
   *
   * @private
   * @param {*} stepItem - Menu Items
   * @param {*} candidateKeys - Candidate Error Keys associated with this step
   * @param {*} candidateProductKeys - Candidate Product Error Keys associated with this step
   * @memberof CandidateCreationStepperComponent
   */
  private validateStep(stepItem, candidateKeys, candidateProductKeys, candidateErrors, validateMatAttributes?: boolean) {
    if (!this.validateKeys(candidateKeys, candidateProductKeys, candidateErrors, validateMatAttributes)) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    } else {
      stepItem.icon = undefined;
      stepItem.message = undefined;
    }
  }

  /**
   * Checks each batch of keys to see if candidateErrors or candidate Product errors
   * contain any values. Immediate return false if error found for performance.
   *
   * @private
   * @param {*} candidateKeys
   * @param {*} candidateProductKeys
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private validateKeys(candidateKeys, candidateProductKeys, candidateErrors, validateMatAttributes?: boolean) {
    for (let i = 0; i < candidateKeys.length; i++) {
      if (this.candidateErrors && this.fieldHasValue(candidateErrors[candidateKeys[i]])) {
        return false;
      }
    }
    for (let i = 0; i < candidateProductKeys.length; i++) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && this.fieldHasValue(value[candidateProductKeys[i]])) {
            return false;
          }
        }
      }
    }

    if (validateMatAttributes) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && value['matAttributeErrors'] &&  Object.entries(value['matAttributeErrors']).length) {
            return false;
          }
        }
      }
    }

    return true;
  }

  /**
   * Takes  a property and immediately checks if it has any sort of value
   * returns 'true' if any value is found or present.
   * @private
   * @param {*} field
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private fieldHasValue(field) {
    return field !== undefined && field !== null && field !== '';
  }

  click(event: any) {
    this.indexClick.emit(event);
  }
}
