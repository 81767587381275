<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-header>
    <app-mrt-inner-stepper
      [candidateErrors]="supplierMrtService.getAllCandidateInnerErrorsByCandidateId()"
      [stepIndex]="2" (indexClick)="onClickStepper($event)">
    </app-mrt-inner-stepper>
  </pm-header>

  <pm-title>Tell us about this product.</pm-title>
  <pm-sub-title *ngIf="supplierMrtService.innerCandidate">UPC: {{supplierMrtService.innerCandidate.candidateProducts[0].upc}}-{{supplierMrtService.innerCandidate.candidateProducts[0].upcCheckDigit}}</pm-sub-title>

  <ng-container *ngIf="supplierMrtService.innerCandidate">
    <pm-product-descriptions-card [(model)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                                  [permissions]="productDescriptionsCardPermissions"
                                  [errorModel]="innerCandidateProductError">
    </pm-product-descriptions-card>

    <pm-product-images-card [(model)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                            [permissions]="productImagesCardPermissions"
                            [showImageOmissionReasons]="true"
                            [errorModel]="innerCandidateProductError">
    </pm-product-images-card>

    <pm-label-insights-image-card *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS-EDIT']"
                                  [(model)]="supplierMrtService.innerCandidate.candidateProducts[0]"
                                  [permissions]="labelInsightsImagesCardPermissions"
                                  [errorModel]="innerCandidateProductError">
    </pm-label-insights-image-card>

    <pm-regulatory-card [(model)]="supplierMrtService.innerCandidate"
                        [permissions]="regulatoryCardPermissions"
                        [errorModel]="innerCandidateError">
    </pm-regulatory-card>

    <pm-product-pricing-card [(model)]="supplierMrtService.innerCandidate"
                             [permissions]="productPricingCardPermissions"
                             [errorModel]="innerCandidateError">
    </pm-product-pricing-card>


    <pm-retail-link *ngIf="candidateUtilService.isSellable(supplierMrtService.innerCandidate)" [permissions]="retailLinkCardPermissions"
                    [(model)]="supplierMrtService.innerCandidate"
                    [errorModel]="innerCandidateError"
                    (validateRetailLink)="validateRetailLink()"
                    [retailLinkState]="retailLinkState">
    </pm-retail-link>

    <pm-product-packaging-card [(model)]="supplierMrtService.innerCandidate"
                               [permissions]="productPackagingCardPermissions"
                               [packageTypes]="packageTypes"
                               [unitOfMeasure]="unitsOfMeasures"
                               [errorModel]="innerCandidateError">
    </pm-product-packaging-card>

  </ng-container>

  <pm-footer>
      <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()"></p-button>
      <p-button class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
    </pm-footer>
</pm-editor>
