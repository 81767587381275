<ng-container *ngIf="model && attribute">
  <pm-attribute-display-image-upload [attribute]="imageUploadConfiguration"
                                     [(model)]="model.imageLinks"
                                     [attributeError]="attributeError?.imageLinks"
                                     (change)="imageChange($event)"
                                     (imageUploaded)="clearImageLinksOmissionReason()">
  </pm-attribute-display-image-upload>

  <ng-container *ngIf="displayOmissionReasons()">
    <div class="omission-reasons-header">Not uploading an image? Let the Buyer know why below.</div>
    <div class="attribute-group attribute-required">
      <div class="attribute-label">
        <label>Reason</label>
      </div>
      <div class="attribute-control">
        <div *ngIf="imageLinkOmissionReasons" class="attribute-radios attribute-radios-block">
          <div *ngFor="let omissionReason of imageLinkOmissionReasons" class="attribute-radio">
            <p-radioButton [name]="omissionReason.imageLinksOmissionReasonDescription"
                           [id]="omissionReason.imageLinksOmissionReasonCode"
                           [label]="omissionReason.imageLinksOmissionReasonDescription"
                           [value]="omissionReason"
                           [disabled]="false"
                           [ngModel]="selectedOmissionReason"
                           (ngModelChange)="onImageLinksOmissionReasonSelection($event)">
            </p-radioButton>
            <div *ngIf="omissionReason.imageLinksOmissionDateRequired" class="date-required-row">
              <pm-attribute-display-date-picker [attribute]="dateRequiredConfiguration"
                                                [(model)]="model.imageLinksOmissionDate"
                                                (change)="dateRequiredChange($event)"
                                                [attributeError]="attributeError?.imageLinksOmissionDate">
              </pm-attribute-display-date-picker>
            </div>
            <div *ngIf="omissionReason.imageLinksOmissionReasonCode === CandidateProduct.OTHER_OMISSION_REASON_CODE" class="other-reason-row">
              <pm-attribute-display-text-input [(model)]="otherOmissionReason"
                                               (modelChange)="setOtherOmissionReason()"
                                               [attribute]="imageLinksOtherOmissionReasonConfig"
                                               [attributeError]="getOtherOmissionReasonError()">
              </pm-attribute-display-text-input>
            </div>
          </div>
          <p *ngIf="showGenericOmissionReasonError()" class="attribute-error-message pl-4">
            {{ attributeError?.imageLinks }}
          </p>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>
</ng-container>
