<pm-review #pmReview *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-title *ngIf="!isReplacementUpc">Review new bonus</pm-title>
  <pm-title *ngIf="isReplacementUpc">Review replacement product</pm-title>

  <div *ngIf="candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages[0] ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div class="upc-header">
            UPC: {{productData?.primaryScanCodeId}}-{{upcCheckDigit}}
          </div>
          <div class="attribute-description">
            <p>{{productData?.productDescription}}</p>
            <p>{{productInfoString}}</p>
            <div class="row" style="padding-left: 15px;">
              <button *ngIf="!displayingExistingCasesDrawer" pButton type="button" label="Show existing cases" (click)="showExistingCasePacks()" class="ui-button-link"></button>
              <button *ngIf="displayingExistingCasesDrawer" pButton type="button" label="Hide existing cases" (click)="collapse()" class="ui-button-link"></button>
            </div>
          </div>
        </div>
      </div>

      <!--   Bonus UPC Details  -->
      <pm-attribute-grid [title]="getUpcDetailLabel()">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'UPC'" [canEdit]="false">
              {{candidate.candidateProducts[currentCandidateProductIndex].upc}}-{{candidate.candidateProducts[currentCandidateProductIndex].upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false">
              {{candidate.candidateProducts[currentCandidateProductIndex].subBrand?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidate.productLength}}in x
              {{candidate.productWidth}}in x
              {{candidate.productHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.productLength, candidate.productWidth, candidate.productHeight, decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail Size'" [canEdit]="false">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'">
              <div *ngIf="!!candidateProductImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="candidateProductImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!candidateProductImages?.length">
                {{ candidateUtilService.getImageLinksOmissionReasons(candidateProduct) }}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Cost set by supplier   -->
      <pm-attribute-grid [title]="'Cost set by supplier'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false">
              {{productData?.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="false">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="false">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.getCaseCostAsCurrency(this.candidate)}}<br>
                Penny profit: {{pennyProfit}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)}"></a>{{margin}}
                <img *ngIf="costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--Retail set by buyer-->
      <pm-attribute-grid [title]="'Retail set by buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="false">
              {{getPricingType()}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              <a [ngClass]="{'grid-cell-error':costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)}"></a>{{margin}}
                <img *ngIf="costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="false">
              {{upc?.xfor}} for {{this.costService.toCurrency(upc?.retailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{pennyProfit}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Case summary   -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [errorMessage]="candidateProductError?.vendorProductCode">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidate.masterLength}}in x
              {{candidate.masterWidth}}in x
              {{candidate.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'"  [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--  inner packs -->
      <pm-attribute-grid [title]="'Inner Packs'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs'" [canEdit]="false" [errorMessage]="candidateError?.innerPackSelected">
              {{candidate.innerPackSelected | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false">
              {{candidate.innerPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidate.innerLength}}in x
              {{candidate.innerWidth}}in x
              {{candidate.innerHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="candidate.displayReadyUnit" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false">
              {{candidate.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false">
              {{candidateUtilService.getDRUOrientation(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false">
              {{candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--      Product details from buyer-->
      <pm-attribute-grid [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <!-- Column 1 -->
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false">
              {{productData?.productTypeId}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{item ? item?.hierarchy?.commodity?.description : dsdItem?.hierarchy?.commodity?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{item ? item?.hierarchy?.description : dsdItem?.hierarchy?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <!-- Column 2 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'Like item code'" [canEdit]="false">
              {{item ? item?.itemCode : dsdItem?.upc}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season & Year'" [canEdit]="false">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Estimated store count'" [canEdit]="false">
              {{candidate.numberOfStores}}
            </pm-grid-cell>
          </pm-grid-column>
          <!-- Column 3 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'Sold by weight'" [canEdit]="false">
              {{upc?.weightSw}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <!--   Global Attributes   -->
        <div>
          <pm-attribute-grid *ngIf="isLoadingMatData || !!bonusSizeService.upcAttributes?.length" [title]="'Extended attributes: UPC'">
            <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>

          <pm-attribute-grid *ngIf="isLoadingMatData || !!bonusSizeService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
            <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
        <!--   Mat Hierarchy attributes   -->
        <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(candidate)?.length" [title]="'Category attributes'">
          <div style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
        </pm-attribute-grid>
        <div *ngIf="!!bonusSizeService.hierarchyNumberToAttributesMap?.size">
          <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
            <pm-attribute-grid *ngIf="bonusSizeService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </div>
      </div>

      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="false">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="false">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <h2 *ngIf="!isReplacementUpc" style="padding-top: 25px;">Details for original (non-bonus) UPC</h2>
      <h2 *ngIf="isReplacementUpc" style="padding-top: 25px;">Details for original (non-replacement) UPC</h2>

      <!--   Supplier and H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false">
              {{productData?.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false">
              {{upc?.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false" [errorMessage]="candidateError?.costOwner"  (editClicked)="editCostOwner()">
              {{item ? item?.supplierItems[0]?.costOwner?.costOwnerName : dsdItem?.dsdSupplierItems[0]?.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false" [errorMessage]="candidateError?.topToTop"   (editClicked)="editCostOwner()">
              <input *ngIf="item" type="text" pInputText value="{{item?.supplierItems[0]?.costOwner?.topToTopName}}"  readonly="readonly"/>
              <input *ngIf="!item && dsdItem" type="text" pInputText value="{{dsdItem?.dsdSupplierItems[0]?.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [editNoHover]="isPageEditable" (editClicked)="editWarehouseSupplier()" [errorMessage]="candidateError?.lane">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false" [errorMessage]="candidateError?.buyer">
              {{item ? item?.hierarchy?.commodity?.bdm.fullName : dsdItem?.hierarchy?.commodity?.bdm.fullName}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Sellable'" [canEdit]="false">
              {{productData?.productTypeId === 'GOODS'| yesNo}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid [title]="'Product line, Packaging, & Regulatory Info'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false">
              {{productData?.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false">
              {{productData?.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'FSA/HSA eligible'">
              {{productData?.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{productData?.packageDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{upc?.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{upc?.size}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{upc?.length}}in x
              {{upc?.width}}in x
              {{upc?.height}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(upc?.weight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false">
              {{item ? item?.totalVolume : dsdItem?.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid [title]="'Unit details'">
        <pm-grid-group style="margin-bottom: 0;">

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
              {{upc?.scanCodeId}}-{{upc?.scanCodeId | upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product description'">
              {{productData?.productDescription}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(productData?.productDescription) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false" >
              {{productData?.customerFriendlyDescriptionOne}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false">
              {{productData?.customerFriendlyDescriptionTwo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false">
              {{productData?.signRomanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="isPageEditable"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.scaComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate.supplierComment">
                <b>{{candidate.contactEmail}}</b> <br>
                {{candidate.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>

              <br>

              <div *ngIf="!!candidate.scaComment">
                <b>{{candidate.scaName}}</b> <br>
                {{candidate.scaComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>


  <pm-review-drawer-container>
    <!--  Existing Cases  -->
    <ng-container *ngIf="displayingExistingCasesDrawer">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>Existing cases</h2>
        <ng-template *ngIf="displayingExistingCasesDrawer" ngFor let-casePack [ngForOf]="productData?.items">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!casePack.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(casePack)"></i>
              <i *ngIf="casePack.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(casePack)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{casePack.caseUpc}}-{{casePack.caseUpc | upcCheckDigit}}
                </label>
                <i *ngIf="casePack.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                WHS | {{casePack.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="casePack.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{casePack.supplierItems[0]?.pack}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
            <!--  Inner Pack -->
            <pm-attribute-grid [title]="'Inner Packs'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Inner pack quantity'" [canEdit]="false">
                    {{casePack.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>
        <ng-template *ngIf="productData?.dsdItems" ngFor let-dsdItem [ngForOf]="productData?.dsdItems">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!dsdItem.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(dsdItem)"></i>
              <i *ngIf="dsdItem.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(dsdItem)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{dsdItem.upc}}-{{dsdItem.upc | upcCheckDigit}}
                </label>
                <i *ngIf="dsdItem.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                DSD | {{dsdItem.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="dsdItem.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{dsdItem?.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>

      </div>
    </ng-container>

    <!--  SCA info  -->
    <ng-container *ngIf="displayingScaInfo && !openCommentDrawer">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link" (click)="save()">
            </button>
          </div>
        </div>

        <h2>Warehouse Details</h2>
        <p>Configure one or more of the warehouses below for this product.</p>

        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="maxShipConfiguration"
                                           [(model)]="candidate.maxShip"
                                           [attributeError]="candidateError?.maxShip">
          </pm-attribute-display-text-input>
        </div>

        <hr/>
        <ng-template ngFor let-warehouse [ngForOf]="warehouseData">

          <div class="attribute-group">
            <div>
              <p-checkbox id="whsCheckbox"
                          [(ngModel)]="warehouse.checked"
                          (onChange)="selectedWarehouseChange($event, warehouse)"
                          binary="true">
              </p-checkbox>
            </div>

            <div>
              <div class="attribute-label">
                <label>
                  {{warehouse.name}}
                </label>
              </div>
              <p class="attribute-description">
                ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
              </p>
              <p *ngIf="warehouse.checked && candidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
                 class="error-message bicep-error">
                {{candidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
              </p>
            </div>

          </div>


          <div *ngIf="warehouse.checked">
            <div class="attributes-stacked">
              <pm-attribute-display-radio [attribute]="warehouse.orderUnitConfig"
                                          [(model)]="warehouse.orderUnitId"
                                          (change)="orderUnitChange($event, warehouse)"
                                          [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
              </pm-attribute-display-radio>
              <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                              [(model)]="warehouse.orderRestriction"
                                              (selection)="orderRestrictionChange($event, warehouse)"
                                              [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
              </pm-attribute-display-typeahead>
            </div>

          </div>
          <hr/>

        </ng-template>

        <div class="attribute-group">
          <div>
            <p-checkbox id="whsMissingCheckbox"
                        [(ngModel)]="candidate.missingWarehouses"
                        (onChange)="warehouseMissingCheckboxChange($event)"
                        binary="true">
            </p-checkbox>
          </div>

          <div>
            <div class="attribute-label">
              <label style="font-size: 18px !important;">
                Warehouse missing?
              </label>
            </div>
          </div>
        </div>

        <div *ngIf="candidate.missingWarehouses">
          <p style="font-size: 14px;">Not seeing the warehouse you need? This could happen if the bicep or AP number is wrong, or if a warehouse wasn't tied to a particular bicep.</p>
          <p style="font-size: 14px;">Right now, only Procurement Support can fix this issue. Let them know which warehouse you want to select and if they need to change the bicep or AP number referenced by this candidate.</p>
          <div class="attributes-stacked">
            <pm-attribute-display-text-input [attribute]="missingWarehousesCommentsConfiguration"
                                             [attributeError]="candidateError?.missingWarehousesComment"
                                             [(model)]="candidate.missingWarehousesComment"
                                             (keypress) ="updatedMissingWarehousesComment()">
            </pm-attribute-display-text-input>
          </div>
        </div>

        <hr/>

        <div class="text-right">
          <p-button class="m-2" label="Approve" (onClick)="onClickApprove()"
                    [disabled]="this.isApproveDisabled ||
                                (!this.isSelectedWarehouse() && !warehouseMissingNotesEntered)"
          ></p-button>
        </div>

      </div>
    </ng-container>

  </pm-review-drawer-container>

  <pm-review-drawer-container>
    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="scaComments"
                                           [(model)]="tempCommentHolder"
                                           [attributeError]="candidateError?.scaComment">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.scaComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.scaComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button *ngIf="isPageEditable" class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-review>
