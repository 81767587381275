import {Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output} from '@angular/core';
import {CandidateError} from 'pm-models';

@Component({
  selector: 'app-supplier-bonus-stepper',
  templateUrl: './supplier-bonus-stepper.component.html',
  styleUrls: ['./supplier-bonus-stepper.component.scss']
})
export class SupplierBonusStepperComponent implements OnInit {

  private errorCandidateDiffer: KeyValueDiffer<string, any>;
  private errorCandidateProductDiffer: KeyValueDiffer<string, any>;

  public static UPC_ITEM_CODE = 'UPC/Item code';
  public static BONUS_DETAILS = 'Bonus details';
  public static REPLACEMENT_DETAILS = 'Replacement details';
  public static CASE_DETAILS = 'Case details';
  public static EXTENDED_ATTRIBUTES = 'Extended attributes';

  private readonly errorToolTip = 'This page contains errors or is missing information in required fields.';
  private readonly errorIcon = 'warning';

  @Input()
  candidateErrors: CandidateError[];

  @Input()
  stepIndex: number;

  @Output() indexClick = new EventEmitter<any>();

  @Input()
  steps;

  modelSteps;

  constructor(private differs: KeyValueDiffers) {}

  ngOnInit() {
    this.modelSteps = this.steps;
  }

  /**
   * Change detection that compares candidate errors with previous version.
   * If the candidate differ doesn't exist, it creates it
   * Runs apply errors if any changes have been detected
   * @memberof StepperComponent
   */
  ngDoCheck(): void {
    let candidateHasChanges = false;

    if (this.errorCandidateDiffer) {
      candidateHasChanges = this.errorCandidateDiffer.diff(this.candidateErrors) ? true : false;
    } else if (this.candidateErrors) {
      this.errorCandidateDiffer = this.differs.find(this.candidateErrors).create();
    }

    if (candidateHasChanges) {
      this.applyErrors();
    }
  }

  /**
   * Runs through each step of menu and runs validation on each step
   *
   * @memberof StepperComponent
   */
  applyErrors() {
    this.modelSteps.forEach((x, i) => {
      switch (i) {
        case 0:
          this.validateUPCItemCode(x, this.candidateErrors[i]);
          break;
        case 1:
          this.validateBonusDetails(x, this.candidateErrors[i]);
          break;
        case 2:
          this.validateCaseDetails(x, this.candidateErrors[i]);
          break;
        case 3:
          // supplier doesn't have a warehouse page, so skip this to get the extended attributes error
          this.validateExtendedAttributes(x, this.candidateErrors[i + 1]);
          break;
      }
    });
  }

  /**
   * Validation for first screen
   *
   * @param {*} stepItem
   * @memberof StepperComponent
   */
  validateUPCItemCode(stepItem, candidateErrors) {
    const candidateKeys = ['description'];
    const candidateProductKeys = ['upc'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, candidateErrors);
  }

  /**
   * Validation for third screen
   *
   * @param {*} stepItem
   * @memberof StepperComponent
   */
  validateCaseDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'itemWeightType',
      'maxShelfLife',
      'inboundSpecDays',
      'masterPack',
      'masterWidth',
      'masterHeight',
      'masterLength',
      'masterWeight',
      'cubeAdjustedFactor',
      'vendorTie',
      'vendorTier',
      'innerPackSelected',
      'innerPack',
      'innerWidth',
      'innerHeight',
      'innerLength',
      'innerWeight',
      'displayReadyUnit',
      'displayReadyUnitRowsDeep',
      'displayReadyUnitRowsHigh',
      'displayReadyUnitRowsFacing',
      'displayReadyUnitOrientation',
      'costLink',
      'masterListCost',
      'innerListCost',
      'costOwner'];
    const candidateProductKeys = [
      'caseUpc',
      'caseDescription',
      'vendorProductCode',
      'countryOfOrigin',
      'attachments'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, candidateErrors);
  }


  validateExtendedAttributes(stepItem, candidateErrors) {
    this.validateStep(stepItem, [], [], candidateErrors, true);
  }
  /**
   * Validation for second screen screen
   *
   * @param {*} stepItem
   * @memberof StepperComponent
   */
  validateBonusDetails(stepItem, candidateErrors) {
    const candidateKeys = [
      'packageType',
      'productHeight',
      'productLength',
      'productWeight',
      'productWidth',
      'retailSize',
      'totalVolume',
      'unitOfMeasure'];

    const candidateProductKeys = [
      'upc',
      'imageLinks',
      'imageLinksOmissionDate',
      'labelInsightsImageLinks',
      'termsAndConditions',
      'upcCheckDigit',
      'attachments'];
    this.validateStep(stepItem, candidateKeys, candidateProductKeys, candidateErrors);
  }

  /**
   * Validates step with associated keys, applies icon and error message if found
   *
   * @private
   * @param {*} stepItem - Menu Items
   * @param {*} candidateKeys - Candidate Error Keys associated with this step
   * @param {*} candidateProductKeys - Candidate Product Error Keys associated with this step
   * @param candidateErrors
   * @param validateMatAttributes
   * @memberof CandidateCreationStepperComponent
   */
  private validateStep(stepItem, candidateKeys, candidateProductKeys, candidateErrors, validateMatAttributes?: boolean) {
    if (!this.validateKeys(candidateKeys, candidateProductKeys, candidateErrors, validateMatAttributes)) {
      stepItem.icon = this.errorIcon;
      stepItem.message = this.errorToolTip;
    }
  }

  /**
   * Checks each batch of keys to see if candidateErrors or candidate Product errors
   * contain any values. Immediate return false if error found for performance.
   *
   * @private
   * @param {*} candidateKeys
   * @param {*} candidateProductKeys
   * @param candidateErrors
   * @param validateMatAttributes
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private validateKeys(candidateKeys, candidateProductKeys, candidateErrors, validateMatAttributes?: boolean) {
    for (let i = 0; i < candidateKeys.length; i++) {
      if (this.candidateErrors && this.fieldHasValue(candidateErrors[candidateKeys[i]])) {
        return false;
      }
    }
    for (let i = 0; i < candidateProductKeys.length; i++) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && this.fieldHasValue(value[candidateProductKeys[i]])) {
            return false;
          }
        }
      }
    }

    if (validateMatAttributes) {
      if (candidateErrors.candidateProductErrors) {
        for (const [key, value] of Object.entries(candidateErrors.candidateProductErrors)) {
          if (value && value['matAttributeErrors'] &&  Object.entries(value['matAttributeErrors']).length) {
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * Takes  a property and immediately checks if it has any sort of value
   * returns 'true' if any value is found or present.
   * @private
   * @param {*} field
   * @returns
   * @memberof CandidateCreationStepperComponent
   */
  private fieldHasValue(field) {
    return field !== undefined && field !== null && field !== '';
  }

  click(event: any) {
    this.indexClick.emit(event);
  }
}
