import { BaseAttribute } from './base-attribute';
import { AttributeProductImagesConfig, CandidateProduct } from 'pm-models';

// tslint:disable-next-line:max-line-length
import {
  AttributeDisplayProductImagesComponent
} from 'pm-components/lib/shared/attribute-display/attribute-display-product-images/attribute-display-product-images.component';

export class ProductImages implements BaseAttribute {
  getComponentType() {
    return AttributeDisplayProductImagesComponent;
  }

  getAttributeConfig(overrides?): AttributeProductImagesConfig {
    return {
      showOmissionReasons: true,
      ...overrides
    };
  }

  setupComponent(
    componentInstance: AttributeDisplayProductImagesComponent,
    candidateProduct: CandidateProduct,
    configOverrides: any = {}
  ) {
    componentInstance.attribute = this.getAttributeConfig(configOverrides);

    componentInstance.model = candidateProduct;
    componentInstance.modelChange.subscribe(x => {
      candidateProduct = x;
    });
  }

  updateModel(componentInstance: AttributeDisplayProductImagesComponent, candidate: CandidateProduct) {
    if (componentInstance.model !== candidate.imageLinks) {
      componentInstance.model = candidate.imageLinks;
    }
  }
}
