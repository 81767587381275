import {Component, OnInit} from '@angular/core';
import {
  CandidateError,
  CandidateProductError,
  CandidateValidatorType,
  ProductDescriptionsCardPermissions,
  ProductImagesCardPermissions,
  ProductPackagingCardPermissions,
  ProductPricingCardPermissions,
  RegulatoryCardPermissions,
  RetailLinkCardPermissions
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierMrtService} from '../../service/supplier-mrt.service';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {MrtInnerStepperComponent} from '../mrt-inner-stepper/mrt-inner-stepper.component';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {LabelProductImagesCardPermissions} from 'pm-models/lib/card-models/label-product-images-model';
import {InputState} from 'pm-components';
import {GrowlService} from '../../growl/growl.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-inner-product-details',
  templateUrl: './inner-product-details.component.html',
  styleUrls: ['./inner-product-details.component.scss']
})
export class InnerProductDetailsComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private lookupService: LookupService,
              public candidateUtilService: CandidateUtilService, private growlService: GrowlService,
              private permissionsService: NgxPermissionsService) { }

  private taskSubscription$: any;
  public innerCandidateError: CandidateError;
  public innerCandidateProductError: CandidateProductError;
  public isViewingPage = true;
  public unitsOfMeasures: any;
  public packageTypes: any;
  public retailLinkState: InputState;

  readonly productDescriptionsCardPermissions: ProductDescriptionsCardPermissions = {
    isReadOnly: false
  };

  readonly regulatoryCardPermissions: RegulatoryCardPermissions = {
    isReadOnly: false,
    taxable: {
      isHidden: true
    },
    foodStamp: {
      isHidden: true
    },
    taxCategory: {
      isHidden: true
    },
    showCalories: {
      isHidden: true
    }
  };

  readonly productPricingCardPermissions: ProductPricingCardPermissions = {
    isReadOnly: false
  };

  readonly retailLinkCardPermissions: RetailLinkCardPermissions = {
    isReadOnly: false
  };

  readonly productPackagingCardPermissions: ProductPackagingCardPermissions = {
    isReadOnly: false
  };

  readonly productImagesCardPermissions: ProductImagesCardPermissions = {
    isReadOnly: false
  };

  readonly labelInsightsImagesCardPermissions: LabelProductImagesCardPermissions = {
    isReadOnly: false
  };


  ngOnInit() {
    // If there's a previous task/candidate, get it. Else create a new candidate.
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (CandidateUtilService.isMissingInnerCandidateParams(params)) {
        this.router.navigate(['/tasks']);
        this.supplierMrtService.resetService();
      }

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      if (CandidateUtilService.shouldRefetchMrtAndInnerCandidate(params, taskId, this.supplierMrtService.getInnerCandidateId())) {

        // if the inner candidate id is different, reset the inner candidate info.
        if (!this.supplierMrtService.getInnerCandidateId()  ||
          +params['params']['candidateId'] !== this.supplierMrtService.getInnerCandidateId()) {
          this.supplierMrtService.resetInnerFlow();
          // Else task in service is empty, or different, in such case, reset entire service.
        } else {
          this.supplierMrtService.resetService();
        }
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          // if the candidate is an mrt, and contains the inner set the inner candidate.
          if (CandidateUtilService.isCandidateAnMrtAndContainsInner(candidate, +params['params']['candidateId'])) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.getCandidateByCandidateId(params['params']['candidateId']).subscribe((innerCandidate) => {
              this.supplierMrtService.setOriginalAndCurrentInnerCandidate(innerCandidate);
              this.initializeData();
            });
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidMrtCandidateInnerAndTaskData(taskId, this.supplierMrtService.getCandidate(),
          this.supplierMrtService.getInnerCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierMrtService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.innerCandidateError = this.supplierMrtService.getProductDetailsError(this.supplierMrtService.getInnerCandidate().candidateId);
    if (this.innerCandidateError) {
      this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
        this.supplierMrtService.getInnerCandidateProduct(0).id];
    } else {
      this.innerCandidateError = new CandidateError();
      this.innerCandidateProductError = new CandidateProductError();
    }
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });
  }


  onClickBack() {
    // if there's changes to the inner, and they're not validating (on back), then set valid to false.
    if (this.supplierMrtService.hasInnerChanged()) {
      this.supplierMrtService.getCandidateInnerByCandidateId(this.supplierMrtService.getInnerCandidate().candidateId).valid = false;
      this.supplierMrtService.saveMrtCandidate().subscribe();
    }
    this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), '/supplierAndHebDetails',
      {queryParams: {taskId: this.supplierMrtService.getTaskIdString(), candidateId:
          this.supplierMrtService.getInnerCandidate().candidateId}}, false);
  }

  onClickNext() {
    const validators: CandidateValidatorType[] = [CandidateValidatorType.MRT_INNER_PRODUCT_DETAILS_VALIDATOR];
    if (this.permissionsService.getPermission('ROLE_IMAGE_OMISSION_REASONS')) {
      validators.push(CandidateValidatorType.IMAGE_LINKS_OMISSION_REASON_VALIDATOR);
    }
    this.jumpToPage('/addNewMrtCase', validators);
  }

  jumpToPage(url: string, validators: CandidateValidatorType[]) {
    // validate the candidate for this page and send errors to components to display, if any
    this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(), validators).subscribe(data => {
      this.innerCandidateError = this.supplierMrtService.setProductDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
        new CandidateError());
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
        {queryParams: {taskId: this.supplierMrtService.getTaskIdString(), candidateId:
            this.supplierMrtService.getInnerCandidate().candidateId}}, false);
    }, (error) => {
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.innerCandidateError = this.supplierMrtService.setProductDetailsError(this.supplierMrtService.getInnerCandidate().candidateId,
          error.error.candidateErrors);
        this.innerCandidateProductError = this.innerCandidateError.candidateProductErrors[
          this.supplierMrtService.getInnerCandidateProduct(0).id];
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
          {queryParams: {taskId: this.supplierMrtService.getTaskIdString(),
              candidateId: this.supplierMrtService.getInnerCandidate().candidateId}}, false, true);
      } else {
        this.growlService.addError(error.message);
      }
   });
  }

  onClose() {
    this.supplierMrtService.onInnerClose();
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case MrtInnerStepperComponent.SUPPLIER_HEB_SETUP:
        this.onClickBack();
        break;
      case MrtInnerStepperComponent.CASE_PACK:
        this.onClickNext();
        break;
      case MrtInnerStepperComponent.EXTENDED_ATTRIBUTES:
        const validators: CandidateValidatorType[] = [CandidateValidatorType.MRT_INNER_PRODUCT_DETAILS_VALIDATOR];
        if (this.permissionsService.getPermission('ROLE_IMAGE_OMISSION_REASONS')) {
          validators.push(CandidateValidatorType.IMAGE_LINKS_OMISSION_REASON_VALIDATOR);
        }
        this.jumpToPage('/innerExtendedAttributes', validators);
        break;
    }
  }

  validateRetailLink() {
    this.retailLinkState = InputState.loading;
    this.innerCandidateError.retailLink = undefined;
    this.candidateService.validateCandidate(this.supplierMrtService.innerCandidate, [CandidateValidatorType.RETAIL_LINK_VALIDATOR]).subscribe((candidate) => {
      this.retailLinkState = InputState.valid;

      this.supplierMrtService.innerCandidate.retailLink = candidate.retailLink;
      this.supplierMrtService.innerCandidate.retailXFor = candidate.retailXFor;
      this.supplierMrtService.innerCandidate.retailPrice = candidate.retailPrice;

      this.innerCandidateError.retailLink = undefined;
    }, (error) => {
      this.retailLinkState = InputState.invalid;
      this.supplierMrtService.innerCandidate.retailXFor = null;
      this.supplierMrtService.innerCandidate.retailPrice = null;
      if (error.error.candidateErrors) {
        this.innerCandidateError.retailLink = error.error.candidateErrors.retailLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }
}
