import {WarehouseError} from 'pm-models/lib/warehouseError';
import {LocationGroupStoresError} from 'pm-models/lib/locationGroupStoresError';
import {ScaleInformationError} from 'pm-models/lib/scaleInformationError';
import {WarehouseSupplierItemError} from 'pm-models/lib/warehouseSupplierItemError';

export class CandidateProductError {
  id: string;
  upc: string;
  description: string;
  caseDescription: string;
  upcCheckDigit: string;
  caseUpc: string;
  caseUpcCheckDigit: string;
  vendorProductCode: string;
  countryOfOrigin: string;
  subBrand: string;
  warehouseErrors: Map<number, WarehouseError> = new Map<number, WarehouseError>();
  errors: string[];
  remark1: string;
  remark2: string;
  customerFriendlyDescription1: string;
  customerFriendlyDescription2: string;
  romanceCopy: string;
  imageLinks: string;
  imageLinksOmissionDate?: string;
  labelInsightsImageLinks: string;
  termsAndConditions: string;
  itemCode: string;
  factory: string;
  locationGroupStoresErrors: Map<number, LocationGroupStoresError> = new Map<number, LocationGroupStoresError>();
  candidateIdToGroupStoreListMap?: Map<any, any> = new Map<number, Map<number, number[]>>();
  warehouseSupplierItemErrors?: Map<any, WarehouseSupplierItemError> = new Map<String, WarehouseSupplierItemError>();
  matAttributeErrors?: Map<string, string[]> = new Map<string, string[]>();
  missingStoresError?: string;
  missingWarehouses?: string;
  scaleInformation?: ScaleInformationError;
  depositScanCodeId?: string;
  categorySelectionError?: string;
  addComplimentaryPlu?: string;
}
