import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BaseAttributeDisplayComponent } from '../base-attribute-display/base-attribute-display.component';

@Component({
  selector: 'pm-attribute-display-image-upload',
  templateUrl: './attribute-display-image-upload.component.html',
  styleUrls: ['../attribute-display.component.scss', './attribute-display-image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttributeDisplayImageUploadComponent extends BaseAttributeDisplayComponent implements OnInit {
  @Output() imageUploaded = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit() {}

  updateCallback(event) {
    this.model = event;
    this.modelChange.emit(this.model);
  }

  onImageUploaded(event: Event) {
    this.imageUploaded.emit();
  }
}
