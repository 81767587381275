import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';
import {AttributeTypes, ReviewComponent} from 'pm-components';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  DsdItem,
  Item,
  Product,
  Task,
  TextInputType,
  Upc,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {AuthService} from '../auth/auth.service';
import {GrowlService} from '../growl/growl.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {CandidateService} from '../service/candidate.service';
import {CostService} from '../service/cost.service';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {FileService} from '../service/file.service';
import {LookupService} from '../service/lookup.service';
import {ProductService} from '../service/product.service';
import {WorkflowService} from '../service/workflow.service';
import {calculateCheckDigit} from '../shared/upc.utils';
import {LabelInsightService} from '../service/label-insight.service';
import {MatUtilService} from '../service/mat-util.service';
import {Observable} from 'rxjs';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {BonusSizeService} from '../service/bonus-size.service';

@Component({
  selector: 'app-sca-bonus-size-review',
  templateUrl: './sca-bonus-size-review.component.html',
  styleUrls: ['./sca-bonus-size-review.component.scss']
})
export class SCABonusSizeReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public warehouseMissingNotesEntered: boolean = false;

  public WAREHOUSE_TASK_NAME = 'Assign Warehouse';
  public CASE_ID = 'C';
  public EACH_ID = 'E';

  public KEY_RETAIL = 'Key Retail';
  public RETAIL_LINK = 'Retail Link';
  public PRICE_REQUIRED = 'Price Required';
  public UPC = 'UPC';
  public isApproveDisabled = false;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public productData: Product;
  public upc: Upc;
  public item: Item;
  public dsdItem: DsdItem;
  public productInfoString: string;
  public displayingExistingCasesDrawer = false;
  public displayingScaInfo = false;
  public departmentId = undefined;
  public upcCheckDigit: number;

  public currentCandidateProductIndex = 1;
  public searchedCandidateProductIndex = 0;
  showMatAttributes = false;
  isLoadingMatData = true;
  public openCommentDrawer: boolean;
  public tempCommentHolder: string;

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute, public router: Router,
              public candidateService: CandidateService, public lookupService: LookupService, public growlService: GrowlService,
              public costService: CostService, public productService: ProductService, public fileService: FileService,
              public editCandidateModalService: EditCandidateModalService, public candidateUtilService: CandidateUtilService,
              public authService: AuthService, public permissionService: NgxPermissionsService, public labelInsightService: LabelInsightService,
              public matUtilService: MatUtilService, public bonusSizeService: BonusSizeService) {
  }

  public candidate: Candidate;
  public candidateProduct: CandidateProduct;

  public originalCandidate: any = {};

  private taskSubscription$: any;
  private task: Task;
  public isViewingPage = true;
  public commodities: any;

  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;

  public productImages = [];
  public candidateProductImages = [];
  public labelInsightImages = [];

  public isRejectDisabled = false;

  public pennyProfit: any;
  public margin;

  public warehouseData = [];
  public warehouses: any = undefined;
  public orderRestrictions: any[];
  public decimalCount = 1;

  public isPageEditable: boolean = false;
  public isReplacementUpc: boolean;

  missingWarehousesCommentsConfiguration: AttributeTextInputConfig = {
    label: 'Notes for Procurement Support',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-half-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: '',
    name: 'missingWarehouseCommentId',
    maxLength: 4000,
    isRequired: true
  };

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'Verify the value provided by the supplier or enter a value if empty .',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '# of days',
    isRequired: true,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer
  };

  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
    isHidden: () => true
  };

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    isHidden: () => true
  };

  seasonConfiguration: AttributeTypeaheadConfig = {
    label: 'Season',
    description: '',
    isRequired: false,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    idRef: 'seasonId',
    displayRef: 'seasonDescription',
    placeholderText: 'Season',
    searchUrl: '/lookup/season',
    showAddlInfo: true
  };

  yearConfiguration: AttributeTextInputConfig = {
    label: 'Year',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'ui-calculated-text-input',
    placeholderText: 'Year',
    name: '',
    textInputType: TextInputType.integer,
    maxLength: 4
  };

  remark1Configuration: AttributeTextInputConfig = {
    label: 'Remark 1 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  remark2Configuration: AttributeTextInputConfig = {
    label: 'Remark 2 for OMI',
    charLimit: 72,
    description: `Provide comments for internal use.`,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'text-area-resize-disabled',
    textInputType: TextInputType.textarea
  };

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  scaComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'scaCommentId',
    maxLength: 4000
  };

  private setIsPageEditable() {
    this.isPageEditable = this.authService.isSca();
  }

  ngOnInit() {

    this.bonusSizeService.resetService();
    this.setIsPageEditable();

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id and process instance id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.WAREHOUSE_TASK_NAME) {
              this.router.navigate(
                ['/tasks'], { queryParams: { growlMessage: 'Candidate is not in ' + this.WAREHOUSE_TASK_NAME + ' status.',
                    growlToUse: GrowlService.SEVERITY_ERROR }
                }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                if (candidate.candidateType !== Candidate.BONUS_SIZE && candidate.candidateType !== Candidate.REPLACEMENT_UPC) {
                  this.router.navigate(['/tasks'], {
                    queryParams: {growlMessage: 'Invalid task type.', growlToUse: GrowlService.SEVERITY_ERROR}
                  }).then();
                }
                this.costService.updateCostLink(candidate).subscribe((updatedCandidate) => {
                  this.setInitialValues(updatedCandidate);
                });
              });

          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
      this.candidate.candidateProducts[this.currentCandidateProductIndex] = this.candidateProduct;
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.isViewingPage = false;
        this.router.navigate(['/tasks']).then();
      });
    } else {
      this.isViewingPage = false;
      this.router.navigate(['/tasks']);
    }
  }

  private setInitialValues(candidate: Candidate) {
    this.setOriginalAndCurrentCandidate(candidate);
    this.candidateError = new CandidateError();
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
    this.setProductData().subscribe(() => {
        this.setupMatAttributes();
      }, (error) => {
        this.growlService.addError(error.message);
      }
    );
    this.getWarehouses(candidate);
    this.getOrderRestrictions();
    this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe();
    if (this.candidate.missingWarehousesComment && this.candidate.missingWarehousesComment.length > 0) {
      this.warehouseMissingNotesEntered = true;
    }
    this.isReplacementUpc = candidate.candidateType === Candidate.REPLACEMENT_UPC;
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[this.currentCandidateProductIndex];

    this.candidate.scaName = this.authService.getUser();
    if (this.candidate.scaComment) {
      this.tempCommentHolder = this.candidate.scaComment;
    }
  }

  setProductData(): Observable<any> {
    if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_UPC) {
      return this.lookupService.getProductByUpcAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].upc, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.getCheckDigit();
          this.setInformationFromProductData(productData);
        })
      );
    } else if (this.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType === CandidateProduct.SEARCHED_ITEM) {
      return this.lookupService.getProductByItemCodeAndApNumbers(this.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, []).pipe(
        tap((productData) => {
          this.productData = productData;
          this.getCheckDigit();
          this.setInformationFromProductData(productData);
        })
      );
    }
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }
    this.showMatAttributes = true;
    this.matUtilService.updateMatHierarchyFromProduct(this.candidateProduct, this.productData).pipe(
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
        this.bonusSizeService.globalAttributes, this.bonusSizeService.hierarchyAttributes)),
      tap(() => {
        this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.bonusSizeService.hierarchyAttributes,
          this.bonusSizeService.hierarchyNumberToAttributesMap);
        this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.bonusSizeService.globalAttributes,
          [], this.bonusSizeService.warehouseItemAttributes, this.bonusSizeService.upcAttributes);
      }),
      finalize(() => {
        this.isLoadingMatData = false;
      })).subscribe();

  }

  private setInformationFromProductData(productData: Product) {
    this.candidate.productId = productData.productId;
    this.item = this.productService.getPrimaryItem(this.candidate.candidateProducts[0], productData);
    this.dsdItem = this.productService.getPrimaryDsdItem(this.candidate.candidateProducts[0], productData);
    this.productService.getUpcBySearchedValue(this.candidate.candidateProducts[0], productData).subscribe(upc => {
      this.upc = upc;
      this.setProductRetailInfo();
    });
    this.productService.setBuyerAndHierarchyInformation(this.candidate, productData);
    this.productImages.push(this.productService.getProductImageUrl(productData));
    this.candidateProductImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[1].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[1].labelInsightsImageLinks);
    this.departmentId = this.productService.getDepartment(productData);
  }

  setProductRetailInfo() {
    const tempCandidate: Candidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.unitCost = this.costService.getUnitCost(this.candidate);
    tempCandidate.retailPrice = this.upc.retailPrice;
    tempCandidate.retailXFor = this.upc.xfor;
    if (!this.productData.priceRequired) {
      tempCandidate.retailType = 'KEY_RETAIL';
    }
    this.pennyProfit = this.costService.getHebPennyProfit(tempCandidate);
    this.margin = this.costService.getHebMargin(tempCandidate);
    if (this.candidate.candidateType === Candidate.REPLACEMENT_UPC) {
      this.productInfoString =
        'Retail size: ' + this.candidate.retailSize +
        ' | Original size: ' + this.upc.size +
        ' | Suggested retail: ' + this.costService.toCurrency(this.upc.retailPrice);
    } else {
      this.productInfoString =
        'Bonus size: ' + this.candidate.retailSize +
        ' | Original size: ' + this.upc.size +
        ' | Suggested retail: ' + this.costService.toCurrency(this.upc.retailPrice);
    }
  }

  showExistingCasePacks() {
    this.displayingExistingCasesDrawer = true;
    this.displayingScaInfo = false;
    this.pmReview.openDrawer();
  }

  collapse() {
    this.displayingExistingCasesDrawer = false;
    this.displayingScaInfo = false;
    this.pmReview.closeDrawer();
  }

  get attributeType() {
    return AttributeTypes;
  }

  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  showEditCandidateProductModal(type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidateProduct, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
      }
    });
  }
  onClickCasePack(casePack) {
    casePack.isViewing = !casePack.isViewing;
  }

  onClickNext() {
    this.displayingScaInfo = true;
    this.displayingExistingCasesDrawer = false;
    this.pmReview.openDrawer();
  }

  seasonChange(event) {
    this.candidate.season = event;
  }

  onClickApprove() {
    this.isApproveDisabled = true;
    this.candidateProduct.warehouses = this.getSelectedWarehouses();
    this.candidate.candidateProducts[this.currentCandidateProductIndex] = this.candidateProduct;
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.SCA_PRODUCT_REVIEW_VALIDATOR]).subscribe(() => {
      this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE, 'Successfully completed assign warehouse task.');
    }, (error) => {
      this.isApproveDisabled = false;
      if (error.error.candidateErrors) {
        this.candidateError = error.error.candidateErrors;
        this.candidateProductError  = this.candidateError.candidateProductErrors[this.candidateProduct.id];
        if (this.candidateProductError.errors.length > 0) {
          let errorString = '';
          for (let x = 0; x < this.candidateError.candidateProductErrors[this.candidateProduct.id].errors.length; x++) {
            errorString += this.candidateError.candidateProductErrors[this.candidateProduct.id].errors[x];
          }
          this.growlService.addError(errorString);
        }
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  /**
   * Saves candidate.
   */
  save() {
    this.candidateProduct.warehouses = this.getSelectedWarehouses();
    this.candidate.candidateProducts[this.currentCandidateProductIndex] = this.candidateProduct;
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: { collections : this.candidate.brand.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: { collections : this.candidate.brand.costOwners}}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  editCandidateProduct(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, growlMessage: string) {
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
      this.workflowService.completeTaskWithAction(this.task, action)
        .subscribe(() => {
          this.router.navigate(['/tasks'], {queryParams: {growlMessage: growlMessage}}).then();
        }, (error) => {
          this.growlService.addError(error);
          this.isApproveDisabled = false;
        });
    });
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, add existing selected warehouse information to selected data.
    if (this.candidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            // Used to keep selected warehouses and warehouse list referring to the same object (deep copy w/ methods).
            currentWarehouse = Object.assign({},
              this.candidateProduct.warehouses[y], JSON.parse(JSON.stringify(this.candidateProduct.warehouses[y])));
            currentWarehouse.checked = true;
            if (currentWarehouse.orderUnit) {
              currentWarehouse.orderUnitId = currentWarehouse.orderUnit.id;
            }
            currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
            this.warehouseData.push(currentWarehouse);
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }


  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }
  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }

  getPricingType() {
    return this.productService.getPricingType(this.productData, this.upc);
  }

  editWarehouseSupplier() {
    if (this.candidate.vendor && !this.candidate.vendor.lanes && this.candidate.vendor.apNumber) {
      this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe(() => {
        this.showEditCandidateModal(AttributeTypes.Warehouse, {collections: this.candidate.vendor.lanes});
      });
    } else {
      this.showEditCandidateModal(AttributeTypes.Warehouse,
        {collections: this.candidate.vendor ? this.candidate.vendor.lanes : []});
    }
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.resetWarehouseData();
      }
    });
  }

  private resetWarehouseData() {
    if (this.candidate.vendor.lanes && this.candidate.lane.id !== null && this.candidate.lane.id !== undefined) {
      this.lookupService.findWarehousesByVendorApNumberAndLaneId(this.candidate.vendor.apNumber,
        this.candidate.lane.id).subscribe(warehouses => {
        this.warehouses = warehouses;
        this.getWarehouses(this.candidate);
        if (this.candidate.vendor.apNumber !== undefined) {
          this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe();
        }
      });
    } else {
      this.warehouses = [];
      this.warehouseData = [];
      this.candidateProduct.warehouses = [];
    }
    this.setWarehouseData();
  }

  getCheckDigit() {
    this.upcCheckDigit = calculateCheckDigit(this.productData.primaryScanCodeId);
  }

  updatedMissingWarehousesComment() {
    this.warehouseMissingNotesEntered = true;
  }

  warehouseMissingCheckboxChange(event) {
    const checked = event.checked;
    if (!checked) {
      this.warehouseMissingNotesEntered = false;
      this.candidate.missingWarehousesComment = null;
      this.candidate.missingWarehousesCommentUser = null;
      this.candidate.missingWarehousesCommentTime = null;
      this.candidateError.missingWarehousesComment = undefined;
    } else {
      this.candidate.missingWarehousesCommentUser = this.authService.getUser();
      this.candidate.missingWarehousesCommentTime = new Date().getTime();
    }
  }

  getUpcDetailLabel() {
    if (this.isReplacementUpc) {
      return 'Replacement UPC details';
    } else {
      return 'Bonus UPC Details';
    }
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.scaComment;  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.scaComment = this.tempCommentHolder;
    this.openCommentDrawer = false;
    this.pmReview.closeDrawer();
  }
}
