<pm-editor *ngIf="isViewingPage" (closeEvent)="onClose()">
  <pm-header>
    <app-new-warehouse-product-stepper *ngIf="supplierProductService.candidate && !supplierProductService.isDsdOnly()"
                                       [candidateErrors]="supplierProductService.getAllWhsCandidateErrors()"
                                       [stepIndex]="3"
                                       [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                       (indexClick)="onClickStepper($event)">
    </app-new-warehouse-product-stepper>
    <app-new-dsd-product-stepper *ngIf="supplierProductService.candidate && supplierProductService.isDsdOnly()"
                                 [candidateErrors]="supplierProductService.getAllDsdCandidateErrors()"
                                 [stepIndex]="3"
                                 [isSellable]="supplierProductService.isSellable(supplierProductService.candidate)"
                                 (indexClick)="onClickStepper($event)">
    </app-new-dsd-product-stepper>
  </pm-header>
  <!-- Page Titles -->
  <pm-title>Tell us about this product.</pm-title>
  <pm-sub-title *ngIf="supplierProductService.candidate">UPC: {{supplierProductService.candidate.candidateProducts[0].upc}}-{{supplierProductService.candidate.candidateProducts[0].upc | upcCheckDigit}}</pm-sub-title>
  <!-- Page Cards -->
  <!--
    We're using  an ng-container to not add additional
    DOM elements and only show the cards when the candidate is loaded
  -->
  <ng-container *ngIf="supplierProductService.candidate">

    <pm-product-descriptions-card *ngIf="candidateUtilService.isSellable(supplierProductService.candidate)"
                                  [(model)]="supplierProductService.candidate.candidateProducts[0]"
                                  [permissions]="productDescriptionsCardPermissions"
                                  [errorModel]="candidateProductError">
    </pm-product-descriptions-card>

    <pm-product-images-card [(model)]="supplierProductService.candidate.candidateProducts[0]"
                            [permissions]="productImagesCardPermissions"
                            [showImageOmissionReasons]="true"
                            [errorModel]="candidateProductError">
    </pm-product-images-card>

    <pm-label-insights-image-card *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS-EDIT']"
                                  [(model)]="supplierProductService.candidate.candidateProducts[0]"
                                  [permissions]="labelInsightsImagesCardPermissions"
                                  [errorModel]="candidateProductError">
    </pm-label-insights-image-card>
    <pm-regulatory-card [(model)]="supplierProductService.candidate"
                        [permissions]="regulatoryCardPermissions"
                        [errorModel]="candidateError">
    </pm-regulatory-card>

    <pm-product-pricing-card *ngIf="candidateUtilService.isSellable(supplierProductService.candidate)"
                             [(model)]="supplierProductService.candidate"
                             [permissions]="productPricingCardPermissions"
                             [errorModel]="candidateError">
    </pm-product-pricing-card>

    <pm-retail-link *ngIf="candidateUtilService.isSellable(supplierProductService.candidate)"
                    [permissions]="retailLinkCardPermissions"
                    [(model)]="supplierProductService.candidate"
                    [errorModel]="candidateError"
                    (validateRetailLink)="validateRetailLink()"
                    [retailLinkState]="retailLinkState">
    </pm-retail-link>

    <pm-product-packaging-card [(model)]="supplierProductService.candidate"
                               [(productModel)]="supplierProductService.candidate.candidateProducts[0]"
                               [permissions]="productPackagingCardPermissions"
                               [packageTypes]="packageTypes"
                               [unitOfMeasure]="unitsOfMeasures"
                               [errorModel]="candidateError"
                               [productErrorModel]="candidateProductError"
                               (validateDepositUPC)="validateDepositUpc()"
                               [state]="upcState">
    </pm-product-packaging-card>
  </ng-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back" (onClick)="onClickBack()" [disabled]="!canNavigate"></p-button>
    <p-button class="m-2" label="Next" (onClick)="onClickNext()" [disabled]="!canNavigate"></p-button>
  </pm-footer>
</pm-editor>
