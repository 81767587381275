import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
// PrimeNG Imports
import {InputSwitchModule} from 'primeng/inputswitch';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextareaModule} from 'primeng/inputtextarea';

import {TypeaheadModule} from '../typeahead/typeahead.module';
import {DecimalInputModule} from '../decimal-input/decimal-input.module';
import {PipesModule} from '../pipes/pipes.module';

import {AttributeDisplayToggleComponent} from './attribute-display-toggle/attribute-display-toggle.component';
import {AttributeDisplayRadioComponent} from './attribute-display-radio/attribute-display-radio.component';
import {AttributeDisplayTypeaheadComponent} from './attribute-display-typeahead/attribute-display-typeahead.component';
import {AttributeDisplayDropdownComponent} from './attribute-display-dropdown/attribute-display-dropdown.component';
import {AttributeDisplayTextinputComponent} from './attribute-display-textinput/attribute-display-textinput.component';
import {AttributeDisplayAreaComponent} from './attribute-display-area/attribute-display-area.component';
import {AttributeDisplayVolumeComponent} from './attribute-display-volume/attribute-display-volume.component';
import {AttributeDisplayUpcComponent} from './attribute-display-upc/attribute-display-upc.component';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayMuliSourceInputComponent} from './attribute-display-muli-source-input/attribute-display-muli-source-input.component';
import {AttributeDisplayImageUploadComponent} from './attribute-display-image-upload/attribute-display-image-upload.component';
import {ImageUploadModule} from '../image-upload/image-upload.module';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayAttachmentUploadComponent} from './attribute-display-attachment-upload/attribute-display-attachment-upload.component';
import {AttachmentUploadModule} from '../attachment-upload/attachment-upload.module';
// tslint:disable-next-line:max-line-length
import {AttributeDisplayDualNumericInputComponent} from './attribute-display-dual-numeric-input/attribute-display-dual-numeric-input.component';
import {NumericProcessorsService} from './attribute-utilities/numeric-processors.service';
import {AttributeDisplayCheckboxComponent} from './attribute-display-checkbox/attribute-display-checkbox.component';
import {CheckboxModule} from 'primeng/checkbox';
import {AttributeDisplayTaxCategoryComponent} from './attribute-display-tax-category/attribute-display-tax-category.component';
import {AttributeDisplayDatePickerComponent} from './attribute-display-date-picker/attribute-display-date-picker.component';
import {CalendarModule} from 'primeng/calendar';
import {AttributeDisplayAssociateUpcComponent} from './attribute-display-associate-upc/attribute-display-associate-upc.component';
import {DeleteButtonsModule} from '../delete-button/delete-button.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {SharedModule} from '../../../../../../src/app/2.0.0/shared/shared.module';
// tslint:disable-next-line:max-line-length
import {LabelInsightsImageUploadModule} from '../label-insights-image-upload/label-insights-image-upload.module';
import { AttributeDisplayPluComponent } from './attribute-display-plu/attribute-display-plu.component';
import {
  AttributeDisplayRadioBooleanComponent
} from 'pm-components/lib/shared/attribute-display/attribute-display-radio-boolean/attribute-display-radio-boolean.component';
import { AttributeDisplayProductImagesComponent } from './attribute-display-product-images/attribute-display-product-images.component';

const components = [
  AttributeDisplayToggleComponent,
  AttributeDisplayRadioComponent,
  AttributeDisplayTypeaheadComponent,
  AttributeDisplayDropdownComponent,
  AttributeDisplayTextinputComponent,
  AttributeDisplayAreaComponent,
  AttributeDisplayVolumeComponent,
  AttributeDisplayUpcComponent,
  AttributeDisplayMuliSourceInputComponent,
  AttributeDisplayImageUploadComponent,
  AttributeDisplayAttachmentUploadComponent,
  AttributeDisplayDualNumericInputComponent,
  AttributeDisplayCheckboxComponent,
  AttributeDisplayTaxCategoryComponent,
  AttributeDisplayDatePickerComponent,
  AttributeDisplayAssociateUpcComponent,
  AttributeDisplayPluComponent,
  AttributeDisplayRadioBooleanComponent,
  AttributeDisplayProductImagesComponent
];

const primeNGModules = [
  InputSwitchModule,
  RadioButtonModule,
  AutoCompleteModule,
  InputTextModule,
  InputTextareaModule,
  DropdownModule,
  CalendarModule
];

@NgModule({
  providers: [DecimalPipe, NumericProcessorsService],
  declarations: [...components],
  exports: [
    TypeaheadModule,
    DecimalInputModule,
    ImageUploadModule,
    LabelInsightsImageUploadModule,
    AttachmentUploadModule,
    PipesModule,
    ...components,
    ...primeNGModules
  ],
  entryComponents: [...components],
    imports: [
        CommonModule,
        PipesModule,
        FormsModule,
        ImageUploadModule,
        LabelInsightsImageUploadModule,
        DecimalInputModule,
        TypeaheadModule,
        ...primeNGModules,
        AttachmentUploadModule,
        CheckboxModule,
        DeleteButtonsModule,
        NgxPermissionsModule,
        SharedModule
    ]
})
export class AttributeDisplayModule {}
