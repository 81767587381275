import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  LocationGroupStores,
  TaskDecision,
  TextInputType
} from 'pm-models';
import {WorkflowService} from '../../service/workflow.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../service/candidate.service';
import {LookupService} from '../../service/lookup.service';
import {GrowlService} from '../../growl/growl.service';
import {EditCandidateModalService} from '../../service/edit-candidate-modal.service';
import {CostService} from '../../service/cost.service';
import {FileService} from '../../service/file.service';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {SupplierProductService} from '../../service/supplier-product.service';
import {HistoryService} from '../../utils/history.service';
import {ReviewComponent, UpcService} from 'pm-components';
import {NgxPermissionsService} from 'ngx-permissions';
import {NewWarehouseProductStepperComponent} from '../new-warehouse-product-stepper/new-warehouse-product-stepper.component';
import {NewDsdProductStepperComponent} from '../new-dsd-product-stepper/new-dsd-product-stepper.component';
import {MatUtilService} from '../../service/mat-util.service';
import {finalize, tap} from 'rxjs/operators';
import {LabelInsightService} from '../../service/label-insight.service';
import {CandidateHistoryService} from '../../service/candidate-history.service';

@Component({
  selector: 'app-new-product-review-page',
  templateUrl: './new-product-review-page.component.html',
  styleUrls: ['./new-product-review-page.component.scss']
})
export class NewProductReviewPageComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public KEY_RETAIL = 'Key Retail';
  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public UPC = 'UPC';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  canNavigate = true;
  public canClickSubmit = true;
  public canEditAndResubmit = true;

  constructor(public workflowService: WorkflowService, public route: ActivatedRoute,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService,
              public growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public fileService: FileService, public candidateUtilService: CandidateUtilService,
              public supplierProductService: SupplierProductService, public historyService: HistoryService,
              public permissionService: NgxPermissionsService, public upcService: UpcService,
              public matUtilService: MatUtilService, public labelInsightService: LabelInsightService,
              public candidateHistoryService: CandidateHistoryService) { }

  public resubmittedCandidate: Candidate;
  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;
  private candidateSubscription$: any;
  public isViewingPage = true;
  public opened = true;
  public merchandiseTypes: any;
  public commodities: any;
  public subCommodities: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public productImages = [];
  public labelInsightImages = [];
  public tempCommentHolder: string;
  public decimalCount = 1;
  public currentHistoryResults: any = undefined;
  public showHistoryPanel: boolean = false;
  showMatAttributes = false;
  isLoadingMatData = true;
  private validators: CandidateValidatorType[];
  public suppliers = new Map();
  public isClosedOrUnderReview = true;
  public displayingExtendedAttributes;
  public dsdStepperIndex;
  public whsStepperIndex;

  guaranteeToStoreDaysConfiguration: AttributeTextInputConfig = {
    label: 'Guarantee to store days',
    description: 'The minimum number of days stores have to sell the product before the code date.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    name: 'guaranteeToStoreDaysId',
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    isHidden: () => true
  };

  reactionDaysConfiguration: AttributeTextInputConfig = {
    label: 'Reaction days',
    description: 'The minimum # of days before the expiration date required to notify partners that the code date is approaching.',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    numberCount: 7,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of days',
    name: 'reactionDaysId',
    isHidden: () => true
  };

  inboundSpecConfiguration: AttributeTextInputConfig = {
    label: 'Inbound spec',
    description: 'The number of days between the product\'s arrival date to the warehouse and the product\'s ' +
      'expiration date that H-E-B is guaranteed to sell through the product.',
    isDisabled: () => false,
    isReadOnly: () => false,
    placeholderText: '# of days',
    isRequired: true,
    name: 'inboundSpecDaysId',
    textInputType: TextInputType.integer
  };

  merchandiseTypeConfiguration: AttributeTypeaheadConfig = {
    label: 'Merchandise type',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'description',
    placeholderText: 'Select a merchandise type...',
    collections: this.merchandiseTypes
  };

  commodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Commodity',
    description: 'Choose an appropriate category.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'commodityId',
    displayRef: 'commodityName',
    placeholderText: 'Select a commodity...',
    collections: this.commodities
  };

  subCommodityConfiguration: AttributeTypeaheadConfig = {
    label: 'Sub-commodity',
    description: 'Choose an appropriate subcategory.',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: 'subcommodityId',
    displayRef: 'displayName',
    placeholderText: 'Select a sub-commodity...',
    collections: this.subCommodities
  };

  ngOnInit() {
    this.candidateSubscription$ = this.route.queryParamMap.subscribe(params => {
      if (params.has('candidateId') && params.has('taskId')) {
        this.workflowService.getAllTasksForCandidates([+params.get('candidateId')]).subscribe((tasks) => {

          if (tasks && tasks.length > 0) {
            this.supplierProductService.setTaskId(tasks[0].id);
          } else {
            this.supplierProductService.resetService();
            this.router.navigate(['/tasks'], {
              queryParams: {growlMessage: 'Unable to find a task associated with candidate id: ' +
                  params.get('candidateId'), growlToUse: GrowlService.SEVERITY_ERROR}
            }).then();
          }

          const candidateId: number = parseInt(params.get('candidateId'), 10);
          this.candidateService.getCandidate(candidateId).subscribe((candidate) => {
            if (candidate.candidateType !== Candidate.NEW_PRODUCT && candidate.candidateType !== Candidate.PLU) {
              this.router.navigate(['/tasks'], {
                queryParams: {growlMessage: 'Invalid candidate type.', growlToUse: GrowlService.SEVERITY_ERROR}
              }).then();
            } else {
              this.supplierProductService.setOriginalAndCurrentCandidate(candidate);
              this.candidateHistoryService.setLastSupplierChangedCandidate(this.supplierProductService.getCandidate());
              this.setInitialValues(this.supplierProductService.getCandidate());
              if (this.supplierProductService.getAllCandidateErrors().length <= 0) {
                this.supplierProductService.setCandidateTypeError(new CandidateError());
              }
            }

          });
        });
      } else if (params.has('taskId')) {
        const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierProductService.getTaskId(),
          this.supplierProductService.getTask());
        if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
          this.supplierProductService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
            if (candidate.candidateType === Candidate.NEW_PRODUCT) {
              this.candidateError = this.supplierProductService.getCasePackError();
              this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
              this.isClosedOrUnderReview = false;
              this.setInitialValues(candidate);
              this.candidateHistoryService.setLastSupplierChangedCandidate(candidate);
            }
          });
        } else {
          this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierProductService.getCandidate()).subscribe(
            (isValid) => {
              if (isValid) {
                this.isClosedOrUnderReview = false;
                this.setInitialValues(this.supplierProductService.getCandidate());
                this.candidateHistoryService.setLastSupplierChangedCandidate(this.supplierProductService.getCandidate());
              } else {
                this.supplierProductService.resetService();
                this.router.navigate(['/tasks']);
              }
            });
        }
      } else {
        this.supplierProductService.resetService();
        this.router.navigate(['/tasks'], {
          queryParams: {growlMessage: 'Invalid URL Parameters!', growlToUse: GrowlService.SEVERITY_ERROR}
        });
      }
    });
  }


  private setInitialValues(candidate: Candidate) {
    this.displayingExtendedAttributes = this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT');
    if (this.displayingExtendedAttributes) {
      this.whsStepperIndex = 6;
      this.dsdStepperIndex = 7;
    } else {
      this.whsStepperIndex = 5;
      this.dsdStepperIndex = 6;
    }
    this.candidateError = new CandidateError();
    this.candidateProductError  = this.candidateError.candidateProductErrors[this.supplierProductService.getCurrentCandidateProduct().id];
    this.guaranteeToStoreDaysConfiguration.isHidden = () => !this.supplierProductService.getCandidate().codeDate;
    this.reactionDaysConfiguration.isHidden = () => !this.supplierProductService.getCandidate().codeDate;
    this.inboundSpecConfiguration.isHidden = () => !this.supplierProductService.getCandidate().codeDate;
    if (this.supplierProductService.getOriginalCandidate().productType) {
      this.findMerchandiseTypesAndSetDefault();
    }
    if (candidate.buyer && candidate.buyer.buyerId) {
      this.setCommoditiesAndSubCommodities(candidate.buyer.buyerId);
    }
    if (candidate.brand && candidate.brand.brandId !== undefined) {
      this.setBrandAndCostOwners(candidate.brand.brandId);
    }
    if (this.supplierProductService.getCandidate().buyerComment) {
      this.tempCommentHolder = this.supplierProductService.getCandidate().buyerComment;
    }
    this.productImages = this.candidateUtilService.getImages(this.supplierProductService.getCandidate().candidateProducts[0].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(
      this.supplierProductService.getCandidate().candidateProducts[0].labelInsightsImageLinks);

    if (this.supplierProductService.getCandidate().dsdSwitch) {
      this.setAuthGroups();
    }
    this.currentHistoryResults = this.historyService.getCandidateHistory(this.supplierProductService.getCandidate().candidateId, true);
    this.setupMatAttributes();
  }

  setupMatAttributes() {
    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatData = false;
      return;
    }
    this.supplierProductService.resetMatHierarchyFields();

    if (this.candidateUtilService.isInProgressCandidate(this.supplierProductService.candidate)) {
      this.showMatAttributes = true;
      this.matUtilService.setMatAttributesListsIfNotPresent(this.supplierProductService.candidate, this.supplierProductService.globalAttributes,
        this.supplierProductService.hierarchyAttributes).pipe(
        tap(() => {
          this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.supplierProductService.hierarchyAttributes,
            this.supplierProductService.hierarchyNumberToAttributesMap);
          this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierProductService.globalAttributes,
            this.supplierProductService.productAttributes, this.supplierProductService.warehouseItemAttributes, this.supplierProductService.upcAttributes);
        }),
        finalize(() => {
          this.isLoadingMatData = false;
        })
      ).subscribe();
    } else {
      this.matUtilService.addGlobalAttributesToApplicableTypeLists(this.supplierProductService.getCurrentCandidateProduct().globalAttributes,
        this.supplierProductService.productAttributes, this.supplierProductService.warehouseItemAttributes, this.supplierProductService.upcAttributes);
      this.showMatAttributes = true;
      this.isLoadingMatData = false;
    }
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.supplierProductService.getOriginalCandidate().productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.supplierProductService.getOriginalCandidate());
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      this.merchandiseTypeConfiguration.collections = merchandiseTypes;
      if (!this.supplierProductService.getOriginalCandidate().merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.supplierProductService.getOriginalCandidate().merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.supplierProductService.getCandidate().merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });
    if (!isSellable) {
      this.setNonSellableRetailValues();
    }
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      this.commodityConfiguration.collections = this.commodities;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
        this.subCommodityConfiguration.collections = this.subCommodities;
      }
    });
  }

  /**
   * Sets available selectable cost owners
   * @param brandId the brandId id.
   */
  private setBrandAndCostOwners(brandId) {
    this.lookupService.findBrandsById(brandId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.supplierProductService.getCandidate().brand.brandId === data[index].brandId) {
          this.supplierProductService.getCandidate().brand.costOwners = data[index].costOwners;
          this.supplierProductService.getCandidate().brand.subBrands = data[index].subBrands;
          break;
        }
      }
    });
  }

  /**
   * Sets non sellable default values.
   */
  private setNonSellableRetailValues() {
    this.supplierProductService.getOriginalCandidate().retailXFor = 1;
    this.supplierProductService.getOriginalCandidate().retailPrice = '0.00';
    this.supplierProductService.getOriginalCandidate().retailType = this.KEY_RETAIL;
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.supplierProductService.getCandidate().commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.supplierProductService.getCandidate().commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
    this.subCommodityConfiguration.collections = this.subCommodities;
  }

  getPageTitle() {
    if (this.candidateUtilService.isSellable(this.supplierProductService.getCandidate())) {
      return 'Review new product';
    } else if (this.supplierProductService.getCandidate() && !this.candidateUtilService.isSellable(this.supplierProductService.getCandidate())) {
      return 'Review non-sellable product';
    }
  }

  getProductInfoString() {
    let infoString = '';

    infoString += this.supplierProductService.getCandidate().retailSize + ' | ' + 'Inner case cost: ' +
      this.costService.toCurrencyForCost(this.supplierProductService.getCandidate().innerListCost) + ' |  Unit cost: ' +
      this.costService.toCurrencyForCost(this.supplierProductService.getCandidate().unitCost);

    if (this.candidateUtilService.isSellable(this.supplierProductService.getCandidate())) {
      infoString +=
        ' | Suggested Retail: '  +
        this.supplierProductService.getCandidate().suggestedXFor + ' for ' +
        this.costService.toCurrency(this.supplierProductService.getCandidate().suggestedRetailPrice);
    }

    return infoString;
  }

  onClose() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  getSellableString(): string {
    return this.supplierProductService.getOriginalCandidate().productType === 'SELLABLE' ? 'Yes' : 'No';
  }

  getTitleSubHeading(): String {
    let infoString = '';

    infoString += 'Status: ';
    infoString += CandidateUtilService.getUIStatusFromCandidateStatus(this.supplierProductService.getCandidate().status);
    if (this.supplierProductService.getCurrentCandidateProduct().itemCode) {
      infoString += ' | Item code: ' + this.supplierProductService.getCurrentCandidateProduct().itemCode;
    }

    return infoString;
  }

  private resetInitialValues() {
    this.isViewingPage = false;
  }

  onClickBackToHome() {
    this.resetInitialValues();
    this.router.navigate(['/tasks']).then();
  }

  onClickPrint() {
    window.print();
  }

  resubmitCandidate() {
    this.canEditAndResubmit = false;
    this.resubmittedCandidate = JSON.parse(JSON.stringify(this.supplierProductService.getCandidate()));
    this.resubmittedCandidate.candidateId = null;
    this.resubmittedCandidate.status = null;
    this.resubmittedCandidate.vendorComment = null;
    this.resubmittedCandidate.buyerComment = null;
    this.resubmittedCandidate.scaComment = null;

    // if there's a task, then a candidate has already been created. Else one needs to be created. Name must be required.
    this.supplierProductService.createCandidateAndNavigate(this.resubmittedCandidate, '/setupCandidateType');
    this.workflowService.deleteHistoricInstance(this.supplierProductService.getTaskId()).subscribe();

  }

  isRejected() {
    return (CandidateUtilService.getUIStatusFromCandidateStatus(this.supplierProductService.getCandidate().status) === 'Rejected');
  }

  setAuthGroups() {
    if (!this.supplierProductService.getAuthGroups()) {
      this.supplierProductService.findAllAuthGroupsByParms(
        this.supplierProductService.getCandidate().vendor.apNumber,
        this.supplierProductService.getCandidate().commodity.departmentId,
        this.supplierProductService.getCandidate().commodity.subDepartmentId)
        .subscribe((authGroups) => {
          this.supplierProductService.setAuthGroups(authGroups);
          this.loadVendors();
          for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
            this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
          }
        });
    } else {
      this.loadVendors();
      for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
        this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
      }
    }
  }

  getAuthGroupConfiguration(index): AttributeConfig  {
    return {
      label: 'Group ' + this.supplierProductService.getAuthGroups()[index].splrLocationGroupId,
      description: this.getAuthGroupLabel(this.supplierProductService.getAuthGroups()[index]),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: `authGroup_${index}`,
      defaultValue: false
    };
  }

  getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  loadVendors() {
    for (let x = 0; x < this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length; x++) {
      this.lookupService.findDsdVendor(
        this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores[x].splrLocationNumber)
        .subscribe(vendor => {
          this.suppliers.set(
            this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores[x].splrLocationNumber, vendor[0]);
        });
    }
  }

  getVendor(vendorId: number) {
    return this.suppliers.get(vendorId);
  }

  getNumberOfStoresForGroup(groupId) {
    if (this.supplierProductService.authGroups) {
      for (let x = 0; x < this.supplierProductService.authGroups.length; x++) {
        if (this.supplierProductService.authGroups[x].splrLocationGroupId === groupId) {
          return this.supplierProductService.authGroups[x].stores.length;
        }
      }
    }
  }

  historyPanelOpen() {
    this.permissionService.hasPermission('ROLE_VIEW_CANDIDATE_HISTORY').then((hasPermission) => {
      if (hasPermission) {
        this.historyService.getCandidateHistory(this.supplierProductService.getCandidate().candidateId, true);
        this.currentHistoryResults = this.historyService.sortedAudits;
        this.showHistoryPanel = true;
        this.pmReview.openDrawer();
      }
    });
  }

  historyPanelClose() {
    this.showHistoryPanel = false;
    this.pmReview.closeDrawer();
  }

  onClickSubmit() {
    // validate the candidate for this page and send errors to components to display, if any
    this.canClickSubmit = false;
    this.setValidators();
    this.candidateService.validateCandidate(this.supplierProductService.getCandidate(),
      this.validators).toPromise().then(data => {
      this.supplierProductService.saveAndCompleteTaskAndRouteToTasks(WorkflowService.ACTION_COMPLETE,
        TaskDecision.KEY_VENDOR_DATA_DECISION_NO, this.supplierProductService.getCandidate().candidateId);

    }, (error) => {
      this.supplierProductService.scrollToTop();
      // set the errors on the page
      if (error.error.candidateErrors?.hasErrors) {
        this.supplierProductService.updatePageErrors(error.error.candidateErrors);
        this.candidateError = this.supplierProductService.getCasePackError();
        this.candidateProductError = this.supplierProductService.getCurrentCandidateProductError(this.candidateError);
        this.supplierProductService.setCandidateTypeError(this.candidateError);
      }
      this.canClickSubmit = true;
    });
  }

  onClickBack() {
    this.canNavigate = false;
    if (this.supplierProductService.getCandidate().dsdSwitch) {
      this.supplierProductService.saveCandidateAndNavigate(this.displayingExtendedAttributes ? '/extendedAttributes' : '/setUpStores', false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    } else {
      this.supplierProductService.saveCandidateAndNavigate(this.displayingExtendedAttributes ? '/extendedAttributes' : '/casePack', false).pipe(
        finalize(() => this.canNavigate = true)
      ).subscribe();
    }
  }

  onClickStepper(stepperItem: any) {

    let urlToNavigate;
    switch (stepperItem.text) {
      case NewWarehouseProductStepperComponent.UPC_ITEM_CODE:
        urlToNavigate = '/setupCandidateType';
        break;
      case NewWarehouseProductStepperComponent.SUPPLIER_HEB_SETUP:
        urlToNavigate = '/supplierDetails';
        break;
      case NewWarehouseProductStepperComponent.PRODUCT_DETAILS:
        urlToNavigate = '/basicItemSetup';
        break;
      case NewWarehouseProductStepperComponent.CASE_PACK:
        urlToNavigate = '/casePack';
        break;
      case NewDsdProductStepperComponent.STORE_AUTHORIZATION:
        if (!this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores ||
          this.supplierProductService.getCandidate().candidateProducts[0].locationGroupStores.length === 0) {
          urlToNavigate = '/storeAuthorization';
        } else {
          urlToNavigate = '/setUpStores';
        }
        break;
      case NewWarehouseProductStepperComponent.EXTENDED_ATTRIBUTES:
        urlToNavigate = '/extendedAttributes';
        break;
    }
    this.supplierProductService.saveCandidateAndNavigate(urlToNavigate, false).subscribe();
  }

  private setValidators() {
    if (this.supplierProductService.isDsdOnly()) {
      this.validators = [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR, CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR,
        CandidateValidatorType.SUPPLIER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_CASE_PACK_VALIDATOR,
        CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR];
    } else {
      this.validators =
        [CandidateValidatorType.SUPPLIER_NEW_PRODUCT_SETUP_VALIDATOR, CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR,
          CandidateValidatorType.SUPPLIER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.SUPPLIER_CASE_PACK_VALIDATOR];
    }
    if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.validators.push(CandidateValidatorType.MAT_ATTRIBUTES_VALIDATOR);
    }
    if (this.permissionService.getPermission('ROLE_IMAGE_OMISSION_REASONS')) {
      this.validators.push(CandidateValidatorType.IMAGE_LINKS_OMISSION_REASON_VALIDATOR);
    }
  }
}
