import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplierMrtService } from '../../service/supplier-mrt.service';
import { AttributeTextInputConfig, CandidateError, CandidateValidatorType, TextInputType } from 'pm-models';
import { CandidateInner } from 'pm-models/lib/candidateInner';
import { CandidateService } from '../../service/candidate.service';
import { CandidateInnerError } from 'pm-models/lib/candidateInnerError';
import { GrowlService } from '../../growl/growl.service';
import { MrtInnerStepperComponent } from '../mrt-inner-stepper/mrt-inner-stepper.component';
import { CandidateUtilService } from '../../service/candidate-util.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-add-new-mrt-case',
  templateUrl: './add-new-mrt-case.component.html',
  styleUrls: ['./add-new-mrt-case.component.scss']
})
export class AddNewMrtCaseComponent implements OnInit {


  constructor(private route: ActivatedRoute, private router: Router, public supplierMrtService: SupplierMrtService,
              private candidateService: CandidateService, private growlService: GrowlService,
              private candidateUtilService: CandidateUtilService, private permissionService: NgxPermissionsService,
              private permissionsService: NgxPermissionsService) {
  }

  public currentCandidateInner: CandidateInner;
  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateInnerError: CandidateInnerError;
  public isViewingPage = true;
  public selectedValue = '';
  radiosChoices = [
    {
      value: 'replenishable',
      label: 'Add a new case',
      subText: 'I may also send this item to H-E-B in its own case'
    },
    {
      value: 'nonReplenishable',
      label: 'Use only the MRT case',
      subText: 'I only plan to send this item to H-E-B in the MRT case.'
    }
  ];

  unitCostConfiguration: AttributeTextInputConfig = {
    label: '',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    placeholderText: `Enter unit cost`,
    textInputType: TextInputType.decimal,
    inputGroupClass: 'ui-narrow-input',
    numberCount: 7,
    decimalCount: 2,
    name: 'unitCostId'
  };

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {

      if (CandidateUtilService.isMissingInnerCandidateParams(params)) {
        this.router.navigate(['/tasks']);
        this.supplierMrtService.resetService();
      }

      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierMrtService.getTaskId(),
        this.supplierMrtService.getTask());

      if (CandidateUtilService.shouldRefetchMrtAndInnerCandidate(params, taskId, this.supplierMrtService.getInnerCandidateId())) {

        // if the inner candidate id is different, reset the inner candidate info.
        if (!this.supplierMrtService.getInnerCandidateId() ||
          +params['params']['candidateId'] !== this.supplierMrtService.getInnerCandidateId()) {
          this.supplierMrtService.resetInnerFlow();
          // Else task in service is empty, or different, in such case, reset entire service.
        } else {
          this.supplierMrtService.resetService();
        }
        this.supplierMrtService.setCandidateByUrlParameters(params).subscribe((candidate) => {
          // if the candidate is an mrt, and contains the inner set the inner candidate.
          if (CandidateUtilService.isCandidateAnMrtAndContainsInner(candidate, +params['params']['candidateId'])) {
            this.supplierMrtService.setOriginalAndCurrentCandidate(candidate);
            this.supplierMrtService.getCandidateByCandidateId(params['params']['candidateId']).subscribe((innerCandidate) => {
              this.supplierMrtService.setOriginalAndCurrentInnerCandidate(innerCandidate);
              this.initializeData();
            });
          } else {
            this.supplierMrtService.resetService();
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidMrtCandidateInnerAndTaskData(taskId, this.supplierMrtService.getCandidate(),
          this.supplierMrtService.getInnerCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierMrtService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  initializeData() {
    this.setCandidateInner();
    this.candidateError = this.supplierMrtService.getInnerCasePackError(this.supplierMrtService.getInnerCandidate().candidateId);
    if (this.candidateError) {
      this.candidateInnerError =
        this.candidateError.mrtInfoError.candidateInnerErrors[this.supplierMrtService.getInnerCandidate().candidateId];
    }
    this.supplierMrtService.setInnerCandidates().subscribe();
  }

  setCandidateInner() {
    for (let x = 0; x < this.supplierMrtService.getCandidate().mrtInfo.candidateInners.length; x++) {
      if (this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x].candidateId ===
        this.supplierMrtService.getInnerCandidate().candidateId) {
        this.currentCandidateInner = this.supplierMrtService.getCandidate().mrtInfo.candidateInners[x];
        if (this.currentCandidateInner.replenishable) {
          this.selectedValue = 'replenishable';
        } else if (this.currentCandidateInner.replenishable === false) {
          this.selectedValue = 'nonReplenishable';
        }
        return;
      }
    }
  }

  onClickBack() {
    this.jumpBack('/innerProductDetails');
  }

  jumpBack(url) {
    // if there's changes to the inner, and they're not validating (on back), then set valid to false.
    if (this.supplierMrtService.hasInnerChanged()) {
      this.currentCandidateInner.valid = false;
    }
    this.supplierMrtService.saveMrtCandidate().subscribe(() => {
      this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
        {
          queryParams: {
            taskId: this.supplierMrtService.getTaskIdString(),
            candidateId: this.supplierMrtService.getInnerCandidate().candidateId
          }
        }, false);
    });
  }

  onClickNext() {
    if (this.currentCandidateInner.replenishable) {
      this.jumpToPage('/innerCaseDetails');
    } else if (this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.jumpToPage('/innerExtendedAttributes');
    } else {
      this.jumpToPage('/setUpNewMrtItems');
    }
  }

  jumpToPage(url: string) {
    if (this.currentCandidateInner.replenishable) {
      this.supplierMrtService.saveMrtCandidate().subscribe(() => {
        this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
          {
            queryParams: {
              taskId: this.supplierMrtService.getTaskIdString(),
              candidateId: this.supplierMrtService.getInnerCandidate().candidateId
            }
          }, false);
      });
    } else {
      const validators: CandidateValidatorType[] = [CandidateValidatorType.SUPPLIER_HEB_SETUP_VALIDATOR,
        CandidateValidatorType.MRT_INNER_PRODUCT_DETAILS_VALIDATOR, CandidateValidatorType.UNIT_COST_VALIDATOR];
      if (this.permissionsService.getPermission('ROLE_IMAGE_OMISSION_REASONS')) {
        validators.push(CandidateValidatorType.IMAGE_LINKS_OMISSION_REASON_VALIDATOR);
      }
      this.candidateService.validateCandidate(this.supplierMrtService.getInnerCandidate(), validators).toPromise().then(() => {
        this.candidateError = this.supplierMrtService.setInnerCasePackError(this.supplierMrtService.getInnerCandidate().candidateId, null);
        this.supplierMrtService.saveMrtCandidate().subscribe(() => {
          this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
            {
              queryParams: {
                taskId: this.supplierMrtService.getTaskIdString(),
                candidateId: this.supplierMrtService.getInnerCandidate().candidateId
              }
            }, false);
        });
      }, (error) => {
        if (error.error.candidateErrors) {
          this.candidateError = this.supplierMrtService.setInnerCasePackError(this.supplierMrtService.getInnerCandidate().candidateId,
            error.error.candidateErrors);
        } else {
          this.growlService.addError(error.message);
        }
        this.supplierMrtService.getCandidateInnerByCandidateId(this.supplierMrtService.getInnerCandidate().candidateId).valid = false;
        this.supplierMrtService.saveMrtCandidate().subscribe(() => {
          this.supplierMrtService.saveInnerCandidateAndNavigate(this.supplierMrtService.getInnerCandidate(), url,
            {
              queryParams: {
                taskId: this.supplierMrtService.getTaskIdString(),
                candidateId: this.supplierMrtService.getInnerCandidate().candidateId
              }
            }, false);
        });
      });
    }
  }

  onClose() {
    this.supplierMrtService.onInnerClose();
  }

  onClickSelection() {
    if (this.selectedValue === 'replenishable') {
      this.currentCandidateInner.replenishable = true;
      this.currentCandidateInner.unitCost = null;
      this.supplierMrtService.getInnerCandidate().candidateProducts[0].caseUpc = null;
      this.supplierMrtService.getInnerCandidate().candidateProducts[0].caseUpcCheckDigit = null;
    } else {
      this.currentCandidateInner.replenishable = false;
      // changing from replenishable to non replenishable
      this.supplierMrtService.setNonReplenishableValues();
    }
  }

  showUnitCost() {
    return this.currentCandidateInner && this.currentCandidateInner.replenishable === false;
  }

  onClickStepper(stepperItem) {
    switch (stepperItem.text) {
      case MrtInnerStepperComponent.PRODUCT_DETAILS:
        this.onClickBack();
        break;
      case MrtInnerStepperComponent.SUPPLIER_HEB_SETUP:
        this.jumpBack('/supplierAndHebDetails');
        break;
      case MrtInnerStepperComponent.EXTENDED_ATTRIBUTES:
        this.jumpToPage('/innerExtendedAttributes');
        break;
    }
  }

  unitCostChange(event) {
    this.supplierMrtService.getInnerCandidate().unitCost = event;
    // the bdm and pia see the unit cost as master list cost but activation needs unit cost
    this.supplierMrtService.getInnerCandidate().masterListCost = event;
  }

  getMissingCaseError() {
    if (this.candidateInnerError?.replenishable) {
      return this.candidateInnerError?.replenishable;
    } else if (this.candidateError?.unitCost &&
      (this.currentCandidateInner.replenishable === null || this.currentCandidateInner.replenishable === undefined)) {
      return 'Please select whether to add a case or not.';
    } else {
      return null;
    }
  }
}
