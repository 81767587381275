<pm-review class="supplierReview" *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-title>{{getPageTitle()}}</pm-title>
  <pm-sub-title>{{getTitleSubHeading()}}</pm-sub-title>
  <pm-app-reject-status-header [candidate]="candidate"></pm-app-reject-status-header>
  <div *ngIf="candidate" class="row pt-3 justify-content-md-center pb-3">
    <div>
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImageUrl ? productImageUrl : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div class="upc-header">
            UPC: {{productData?.primaryScanCodeId}}-{{upc?.scanCodeId | upcCheckDigit}}
          </div>
          <div class="attribute-description">
            <p>{{productData?.productDescription}}</p>
            <p>{{productInfoString}}</p>
            <div class="row" style="padding-left: 15px;">
              <button *ngIf="!displayingExistingCasesDrawer" pButton type="button" label="Show existing cases" (click)="showExistingCasePacks()" class="ui-button-link"></button>
              <button *ngIf="displayingExistingCasesDrawer" pButton type="button" label="Hide existing cases" (click)="collapse()" class="ui-button-link"></button>
            </div>
            <div class="row" style="padding-left: 15px;">
              <button pButton type="button"
                      [label]="'View candidate history'"
                      class="ui-button-link" (click)="historyPanelOpen()">
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--   Bonus UPC Details  -->
      <pm-attribute-grid [title]="getUpcDetailLabel()">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'UPC'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'upc')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.candidateProducts[currentCandidateProductIndex].upc}}-{{candidate.candidateProducts[currentCandidateProductIndex].upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'subBrand')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.candidateProducts[currentCandidateProductIndex].subBrand?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.productLength}}in x
              {{candidate.productWidth}}in x
              {{candidate.productHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.productLength, candidate.productWidth, candidate.productHeight, decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail Size'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'retailSize')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'unitOfMeasure', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'totalVolume')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'">
              <div *ngIf="!!candidateProductImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="candidateProductImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!candidateProductImages?.length">
                {{candidateUtilService.getImageLinksOmissionReasons(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download pi-cloud-download-pdf"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Cost set by supplier   -->
      <pm-attribute-grid [title]="'Cost set by supplier'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'prePrice')"
                          (historyClicked)="historyPanelOpen()">
              {{productData?.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="false"  [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'costLinked')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'costLink')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'suggestedRetailPrice')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterListCost')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.getCaseCostAsCurrency(candidate)}}<br>
                Penny profit: {{pennyProfit}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isMarginNegativeOrZero(candidate)}">{{costService.getMargin(candidate)}}%</a>
                <img *ngIf="costService.isMarginNegativeOrZero(candidate)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'mapRetail')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Case summary   -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'caseDescription')"
                          [errorMessages]="[candidateProductError?.caseDescription]"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'caseUpc')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false"
                          [errorMessage]="candidateProductError?.countryOfOrigin"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'countryOfOrigin', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [errorMessage]="candidateProductError?.vendorProductCode"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterPack')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.masterLength}}in x
              {{candidate.masterWidth}}in x
              {{candidate.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTie') ||
                                                                                      candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTier')"
                          (historyClicked)="historyPanelOpen()">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'itemWeightType')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'cubeAdjustedFactor')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'codeDate')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'maxShelfLife')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--  inner packs -->
      <pm-attribute-grid [title]="'Inner Packs'">
        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerPack')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.innerPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.innerLength}}in x
              {{candidate.innerWidth}}in x
              {{candidate.innerHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group *ngIf="!candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterPack')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.masterPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.masterLength}}in x
              {{candidate.masterWidth}}in x
              {{candidate.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="candidate.displayReadyUnit" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnit')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitOrientation')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDRUOrientation(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsDeep') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsFacing') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsHigh')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <div *ngIf="showMatAttributes">
        <div *ngIf="candidateUtilService.isInProgressCandidate(candidate)">
          <!--   Global Attributes   -->
          <div>
            <pm-attribute-grid *ngIf="isLoadingMatData || !!bonusSizeService.upcAttributes?.length" [title]="'Extended attributes: UPC'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="isLoadingMatData || !!bonusSizeService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>
          <!--   Mat Hierarchy attributes   -->
          <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(candidate)?.length" [title]="'Category attributes'">
            <div style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
          </pm-attribute-grid>
          <div *ngIf="!!bonusSizeService.hierarchyNumberToAttributesMap?.size">
            <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
              <pm-attribute-grid *ngIf="bonusSizeService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>
        <div *ngIf="!candidateUtilService.isInProgressCandidate(candidate)">

          <!--  Global Attributes    -->
          <div>
            <pm-attribute-grid  *ngIf="bonusSizeService.upcAttributes?.length" [title]="'Extended attributes: UPC'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="bonusSizeService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>

          <!--   Mat Hierarchy   -->
          <div *ngIf="candidateProduct?.matHierarchyList.length > 0">
            <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
              <pm-attribute-grid *ngIf="hierarchy.attributes.length > 0" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>

      </div>

      <h2 *ngIf="!isReplacementUpc" style="padding-top: 25px;">Details for original (non-bonus) UPC</h2>
      <h2 *ngIf="isReplacementUpc" style="padding-top: 25px;">Details for original (non-replacement) UPC</h2>

      <!--   Supplier and H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'brand', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{productData?.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'subBrand')"
                          (historyClicked)="historyPanelOpen()">
              {{upc?.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false" [errorMessage]="candidateError?.costOwner"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCostOwner(candidate)"
                          (historyClicked)="historyPanelOpen()">
              {{item ? item?.supplierItems[0]?.costOwner?.costOwnerName : dsdItem?.dsdSupplierItems[0]?.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'contactName') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'contactEmail')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false" [errorMessage]="candidateError?.topToTop"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForTopToTop(candidate)"
                          (historyClicked)="historyPanelOpen()">
              <input *ngIf="item" type="text" pInputText value="{{item?.supplierItems[0]?.costOwner?.topToTopName}}"  readonly="readonly"/>
              <input *ngIf="!item && dsdItem" type="text" pInputText value="{{dsdItem?.dsdSupplierItems[0]?.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate,'lane', 'name')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false">
              {{item ? item?.hierarchy?.commodity?.bdm.fullName : dsdItem?.hierarchy?.commodity?.bdm.fullName}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Sellable'" [canEdit]="false">
              {{productData?.productTypeId === 'GOODS'| yesNo}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid [title]="'Product line, Packaging, & Regulatory Info'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false">
              {{productData?.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false">
              {{productData?.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'FSA/HSA eligible'">
              {{productData?.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{productData?.packageDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{upc?.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{upc?.size  }}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{upc?.length}}in x
              {{upc?.width}}in x
              {{upc?.height}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(upc?.weight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false">
              {{item ? item?.totalVolume : dsdItem?.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid [title]="'Unit details'">
        <pm-grid-group style="margin-bottom: 0;">

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
              {{upc?.scanCodeId}}-{{upc?.scanCodeId | upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false">
              {{productData?.productDescription}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(productData?.productDescription) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false">
              {{productData?.customerFriendlyDescriptionOne}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false">
              {{productData?.customerFriendlyDescriptionTwo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false">
              {{productData?.signRomanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="false">
              <div *ngIf="!!candidate?.supplierComment">
                <b>{{candidate?.contactEmail}}</b> <br>
                {{candidate?.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>

              <br>

              <div *ngIf="!!candidate.scaComment">
                <b>{{candidate.scaName}}</b> <br>
                {{candidate.scaComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.piaComment">
                <b>{{candidate.piaCommentUser}}</b> <br>
                {{candidate.piaComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>

  <!--  Audit History -->
  <pm-review-drawer-container >
    <ng-container *ngIf="showHistoryPanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="historyService.sortedAudits">
            <div *ngIf="historyService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{historyService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="displayingExistingCasesDrawer">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>Existing cases</h2>
        <ng-template *ngIf="productData?.items" ngFor let-casePack [ngForOf]="productData?.items">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!casePack.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(casePack)"></i>
              <i *ngIf="casePack.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(casePack)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{casePack.caseUpc}}-{{casePack.caseUpc | upcCheckDigit}}
                </label>
                <i *ngIf="casePack.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                WHS | {{casePack.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="casePack.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{casePack.supplierItems[0]?.pack}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
            <!--  Inner Pack -->
            <pm-attribute-grid [title]="'Inner Packs'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Inner pack quantity'" [canEdit]="false">
                    {{casePack.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>


        <ng-template *ngIf="productData?.dsdItems" ngFor let-dsdItem [ngForOf]="productData?.dsdItems">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!dsdItem.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(dsdItem)"></i>
              <i *ngIf="dsdItem.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(dsdItem)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{dsdItem.upc}}-{{dsdItem.upc | upcCheckDigit}}
                </label>
                <i *ngIf="dsdItem.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                DSD | {{dsdItem.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="dsdItem.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{dsdItem?.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>

      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back To Home" (onClick)="onClickBackToHome()" [disabled]="!canEditAndResubmit"></p-button>
    <p-button class="ghost m-2" label="Print" (onClick)="print()"></p-button>
    <p-button class="ghost m-2" label="Edit & Resubmit" *ngIf="isRejected()" (onClick)="resubmitCandidate()" [disabled]="!canEditAndResubmit"></p-button>
  </pm-footer>
</pm-review>
