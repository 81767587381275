<pm-review #pmReview *ngIf="isViewingPage" (closeEvent)="onClose()">
  <!-- Page Titles -->
  <pm-title *ngIf="!isReplacementUpc">Review new bonus</pm-title>
  <pm-title *ngIf="isReplacementUpc">Review replacement products</pm-title>

  <!-- Missing warehouses content -->
  <div *ngIf="candidate && candidate.missingWarehouses && !isWarehouseMissingResolved" class="row pt-2 pb-3">
    <div class="col-md-1"></div>
    <div class="ml-3 borderLeft col col-md-6 p-2 justify-content-md-left">
      <div class="row ml-2">
        <img style="width: 20px; height: 20px;" src="/assets/images/triangle_exclamation.svg">
        <div class="ml-2 bold">Missing warehouse</div>
      </div>
    </div>
  </div>

  <div *ngIf="candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImageUrl ? productImageUrl : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div class="upc-header">
            UPC: {{productData?.primaryScanCodeId}}-{{upcCheckDigit}}
          </div>
          <div class="attribute-description">
            <p>{{productData?.productDescription}}</p>
            <p>{{productInfoString}}</p>
            <div class="row" style="padding-left: 15px;">
              <button *ngIf="!displayingExistingCasesDrawer" pButton type="button" label="Show existing cases" (click)="showExistingCasePacks()" class="ui-button-link"></button>
              <button *ngIf="displayingExistingCasesDrawer" pButton type="button" label="Hide existing cases" (click)="collapse()" class="ui-button-link"></button>
            </div>
            <div class="row" style="padding-left: 15px;">
              <button pButton type="button"
                      [label]="'View candidate history'"
                      class="ui-button-link" (click)="historyPanelOpen()">
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--   Bonus UPC Details  -->
      <pm-attribute-grid [title]="getUpcDetailLabel()">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'UPC'" [canEdit]="true" (editClicked)="editProductUPC()" [errorMessages]="[candidateProductError?.upc, candidateProductError?.upcCheckDigit]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'upc') || candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'upcCheckDigit')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="true" (editClicked)="showEditCandidateProductModal(attributeType.SubBrand)" [errorMessages]="[candidateProductError?.subBrand]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'subBrand, displayName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.subBrand?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="true" (editClicked)="editCandidate(attributeType.ProductDimensions)" [errorMessages]="[candidateError?.productLength, candidateError?.productWidth, candidateError?.productHeight]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.productLength}}in x
              {{candidate.productWidth}}in x
              {{candidate.productHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.productLength, candidate.productWidth, candidate.productHeight, decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail Size'" [canEdit]="true" (editClicked)="editCandidate(attributeType.RetailSize)" [errorMessage]="candidateError?.retailSize"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'retailSize')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="true" (editClicked)="editUnitOfMeasure()" [errorMessage]="candidateError?.unitOfMeasure"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'unitOfMeasure', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="true" (editClicked)="editCandidate(attributeType.TotalVolume)" [errorMessage]="candidateError?.totalVolume"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'totalVolume')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="true" (editClicked)="editImages()"  [errorMessages]="[candidateProductError?.imageLinks, candidateProductError?.imageLinksOmissionDate]">
              <div *ngIf="!!candidateProductImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="candidateProductImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!candidateProductImages?.length">
                {{ candidateUtilService.getImageLinksOmissionReasons(candidateProduct) }}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>

      </pm-attribute-grid>

      <!--   Cost set by supplier  -->
      <pm-attribute-grid [title]="'Cost set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false">
              {{candidate.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="true" [errorMessage]="candidateError?.costLink" (editClicked)="editCandidate(attributeType.CostLinked)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'costLinked')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="true" [errorMessage]="candidateError?.costLink" (editClicked)="editCostLink()"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'costLink')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="true" [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]"  (editClicked)="costService.editCostRelatedFields(attributeType.MasterListCost, candidate)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterListCost')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.getCaseCostAsCurrency(this.candidate)}}<br>
                Penny profit: {{costService.getHebPennyProfitFromCandidateAndUpc(candidate, upc)}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)}"></a>{{costService.getHebMarginFromCandidateAndUpc(candidate, upc)}}
                  <img *ngIf="costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--Retail set by buyer-->
      <pm-attribute-grid [title]="'Retail set by buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="false">
              {{getPricingType()}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              Margin: <a [ngClass]="{'grid-cell-error':costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)}"></a>{{costService.getHebMarginFromCandidateAndUpc(candidate, upc)}}
                <img *ngIf="costService.isHebMarginNegativeOrZeroByCandidateAndUpc(candidate, upc)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP"><br>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="false">
              {{upc?.xfor}} for {{this.costService.toCurrency(upc?.retailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{costService.getHebPennyProfitFromCandidateAndUpc(candidate, upc)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <!--   Case summary   -->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="true"
                          (editClicked)="editCaseDescription(attributeType.CaseDescription)"
                          [errorMessages]="[candidateProductError?.caseDescription]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'caseDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'" [canEdit]="true" (editClicked)="editCandidate(attributeType.Channel)"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'channel')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [canEdit]="true" (editClicked)="editCaseUPC()" [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'caseUpc')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="true" (editClicked)="showEditCandidateProductModal(attributeType.CountryOfOrigin)" [errorMessage]="candidateProductError?.countryOfOrigin"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'countryOfOrigin', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'"  [canEdit]="true" (editClicked)="editCandidateProduct(attributeType.VendorProductCode)"[errorMessage]="candidateProductError?.vendorProductCode"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="true" [errorMessage]="candidateError?.masterPack" (editClicked)="costService.editCostRelatedFields(attributeType.TotalRetail, candidate)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterPack')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="true" [errorMessages]="[candidateError?.masterLength, candidateError?.masterWidth, candidateError?.masterHeight]" (editClicked)="editCandidate(attributeType.Dimensions)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="true" [errorMessages]="[candidateError?.vendorTie,candidateError?.vendorTier]" (editClicked)="editCandidate(attributeType.BoxesOnPallet)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTie') || candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTier')"
                          (historyClicked)="historyPanelOpen()">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="isTiedToCatchOrVariableWeightBuyer()" [errorMessage]="candidateError?.itemWeightType" (editClicked)="editCandidate(attributeType.ItemWeightType)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'weightType')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="true" [errorMessage]="candidateError?.masterWeight" (editClicked)="editCandidate(attributeType.Weight)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="true" [errorMessage]="candidateError?.cubeAdjustedFactor" (editClicked)="editCandidate(attributeType.OrderFactor)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'cubeAdjustedFactor')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="true"  [errorMessage]="candidateError?.codeDate" (editClicked)="editCandidate(attributeType.CodeDate)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'codeDate')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.maxShelfLife" (editClicked)="editCandidate(attributeType.MaxShelfLife)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'maxShelfLife')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.inboundSpecDays"  (editClicked)="editCandidate(attributeType.InboundSpecDays)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'inboundSpecDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  inner packs -->
      <pm-attribute-grid [title]="'Inner packs'">
        <pm-grid-group style="margin-bottom: 0;">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs'" [canEdit]="true" [errorMessage]="candidateError?.innerPackSelected" (editClicked)="costService.editCostRelatedFields(attributeType.InnerPackSelected, candidate)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'innerPackSelected')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.innerPackSelected | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="true" [errorMessage]="candidateError?.innerPack" (editClicked)="costService.editCostRelatedFields(attributeType.InnerPackQuantity, candidate)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'innerPack')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.innerPack }}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="candidate.innerPackSelected"   [errorMessages]="[candidateError?.innerLength, candidateError?.innerWidth, candidateError?.innerHeight]" (editClicked)="editCandidate(attributeType.InnerPackDimensions)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerWidth')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getInnerDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="candidate.innerPackSelected"  [errorMessage]="candidateError?.innerWeight" (editClicked)="editCandidate(attributeType.InnerPackWeight)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid [title]="'Display ready units'">
        <pm-grid-group style="margin-bottom: 0;">
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="true" [errorMessage]="candidateError?.displayReadyUnit" (editClicked)="editCandidate(attributeType.DisplayReadyUnit)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnit')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnit | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group *ngIf="candidate.displayReadyUnit">
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="true" [errorMessage]="candidateError?.displayReadyUnitOrientation" (editClicked)="editCandidate(attributeType.Orientation)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitOrientation')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="true" [errorMessages]="[candidateError?.displayReadyUnitRowsDeep, candidateError?.displayReadyUnitRowsFacing, candidateError?.displayReadyUnitRowsHigh]" (editClicked)="editCandidate(attributeType.RetailUnitCount)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsDeep') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsFacing') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsHigh')"
                          (historyClicked)="historyPanelOpen()">
              {{getNumRetailUnits()}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit type'" [canEdit]="true" [errorMessage]="candidateError?.displayReadyUnitType" (editClicked)="editCandidate(attributeType.DisplayReadyUnitType)"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitType')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDruType(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--      Product details from buyer-->
      <pm-attribute-grid [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <!-- Column 1 -->
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false">
              {{productData?.productTypeId}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{item ? item?.hierarchy?.commodity?.description : dsdItem?.hierarchy?.commodity?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{item ? item?.hierarchy?.description : dsdItem?.hierarchy?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <!-- Column 2 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'Like item code'" [canEdit]="false">
              {{item ? item?.itemCode : dsdItem?.upc}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season & Year'" [canEdit]="true" (editClicked)="editSeasonYear()" [errorMessages]="[candidateError?.season, candidateError?.seasonYear]"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'seasonYear') || candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'season', 'seasonDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Estimated store count'" [canEdit]="true" (editClicked)="editCandidate(attributeType.EstimatedStoreCount)"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'numberOfStores')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.numberOfStores}}
            </pm-grid-cell>
          </pm-grid-column>
          <!-- Column 3 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'Sold by weight'" [canEdit]="false">
              {{upc?.weightSw}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Reaction days'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.warehouseReactionDays" (editClicked)="editCandidate(attributeType.ReactionDays)"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'warehouseReactionDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="candidate?.codeDate" [errorMessage]="candidateError?.guaranteeToStoreDays" (editClicked)="editCandidate(attributeType.GuaranteeDays)"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'guaranteeToStoreDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Warehouses -->
      <pm-attribute-grid [title]="'Warehouses'">

        <!-- Missing warehouses content -->
        <div *ngIf="candidate && candidate.missingWarehouses" class="row pt-2 pb-3">
          <div [ngClass]="{'borderLeft': !isWarehouseMissingResolved, 'borderLeftResolved' : isWarehouseMissingResolved}"
               class="ml-3 col col-md-10 p-2 justify-content-md-left">
            <div class="row">
              <div class="col col-md-10">
                <img *ngIf="!isWarehouseMissingResolved" style="width: 20px; height: 20px;" src="/assets/images/triangle_exclamation.svg">
                <img *ngIf="isWarehouseMissingResolved" style="width: 20px; height: 20px;" src="/assets/images/circle_check.svg">
                <div *ngIf="isWarehouseMissingResolved" class="inline-block ml-2 mb-2 bold">(Resolved)</div>
                <div class="inline-block ml-2 mb-2 bold">Missing warehouse</div>
              </div>
              <div *ngIf="isWarehouseMissingResolved" class="col col-md-2">
                <button type="button" (click)="toggleExpandCollapseMissingWarehouse()" class="ml-2 float-right btn btn-link" style="font-size: 12px;">{{expandCollapseMissingWarehouseButtonText}}</button>
              </div>
            </div>

            <div *ngIf="isExpandedWarehouse">
              <p class="ml-3">An SCA was unable to select the desired warehouse (see their note below). You may need to modify one or more fields related to the bicep or adjust the warehouse configuration outside of PAM.</p>
              <hr/>
              <div class="float-left ml-2 mb-0 bold">{{candidate.missingWarehousesCommentUser}}</div>
              <div style="font-size: 14px;" class="mt-2 float-right text-muted">{{candidate.missingWarehousesCommentTime | dateStandard}}</div>
              <div style="clear: both;"></div>

              <div class="ml-2 mb-2">{{candidate.missingWarehousesComment}}</div>

              <div *ngIf="candidateProduct.warehouses.length > 0" class="ml-2 mb-2 mt-2" style="font-size: 14px;">
                <button type="button" style="font-size: 1em;" class="btn btn-link pl-0" (click)="toggleResolved()" *ngIf="!isWarehouseMissingResolved">Mark as resolved</button>
                <div *ngIf="isWarehouseMissingResolved" class="text-body">Resolved by {{candidate.missingWarehousesResolveUser}}
                  <span (click)="toggleResolved()" class="btn btn-link ml-2 pl-0" style="font-size: 12px;">Undo</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- Warehouses grid content -->
        <pm-grid-group style="margin-bottom: 0;" *ngIf="candidateProduct.warehouses.length > 0">
          <pm-grid-column>
            <pm-grid-cell [label]="'Max ship'" [canEdit]="true" [errorMessage]="candidateError?.maxShip"
                          (editClicked)="editCandidate(attributeType.MaxShip)"
                          [hasChanged]="candidateHistoryService.hasScaValueChangedForCandidate(candidate, 'maxShip')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.maxShip}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <ng-template ngFor let-warehouse [ngForOf]="candidateProduct.warehouses" let-index="index">

          <pm-grid-group style="margin-bottom: 0;">
            <pm-grid-column>
              <pm-grid-cell [label]="'Warehouse'" [canEdit]="false" class="warehouse-grid-cell" [errorMessage]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep">
                {{warehouse.name}}
                <pm-grid-cell-info>
                  ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
                </pm-grid-cell-info>
                <button pButton *ngIf="candidateProduct.warehouses?.length !== 1" type="button" label="Remove" class="ui-button-link" (click)="removeWarehouse(index)"></button>
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order unit'" [canEdit]="true" [errorMessage]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit"
                            (editClicked)="editWarehouse(warehouse, attributeType.WarehouseOrderUnit)"
                            [hasChanged]="candidateHistoryService.hasScaValueChangedForWarehouse(candidateProduct, 'orderUnit', 'description')"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderUnit?.description}}
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order restriction'" [canEdit]="true"  [errorMessage]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction"
                            (editClicked)="editOrderRestrictions(warehouse)"
                            [hasChanged]="candidateHistoryService.hasScaValueChangedForWarehouse(candidateProduct, 'orderRestriction', 'description')"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderRestriction?.displayName}}
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <div *ngIf="index !== candidateProduct.warehouses?.length - 1" class="grid-divider"></div>
        </ng-template>
      </pm-attribute-grid>
      <div class="row">
        <div class="col-6">
          <button pButton type="button" label="View additional warehouse options" class="ui-button-link" (click)="openWarehouseDrawer()"></button>
        </div>
      </div>
      <div class="row">
        <div class="inline-block ml-2 mb-2 bold warehouseError" *ngIf="candidateProductError?.missingWarehouses">{{candidateProductError?.missingWarehouses}}</div>
      </div>

      <div *ngIf="showMatAttributes">
        <!--   Global Attributes   -->
        <div>
          <pm-attribute-grid *ngIf="isLoadingMatData || !!bonusSizeService.upcAttributes?.length" [title]="'Extended attributes: UPC'">
            <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.upcAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>

          <pm-attribute-grid *ngIf="isLoadingMatData || !!bonusSizeService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
            <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
              <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                   [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
              <h5 class="ml-3">Fetching extended attribute data...</h5>
            </div>
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of bonusSizeService.warehouseItemAttributes; let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
        <!--   Mat Hierarchy attributes   -->
        <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(candidate)?.length" [title]="'Category attributes'">
          <div style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="true" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
        </pm-attribute-grid>
        <div *ngIf="!!bonusSizeService.hierarchyNumberToAttributesMap?.size">
          <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
            <pm-attribute-grid *ngIf="bonusSizeService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                  (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                  (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of bonusSizeService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="true"
                                  (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                  [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                      <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </div>

      </div>


      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="true" (editClicked)="editCandidateProduct(attributeType.Remark1)" [errorMessage]="candidateProductError?.remark1">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="true" (editClicked)="editCandidateProduct(attributeType.Remark2)" [errorMessage]="candidateProductError?.remark2">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <h2 *ngIf="!isReplacementUpc" style="padding-top: 25px;">Details for original (non-bonus) UPC</h2>
      <h2 *ngIf="isReplacementUpc" style="padding-top: 25px;">Details for original (non-replacement) UPC</h2>

      <!--   Supplier and H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false">
              {{productData?.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false">
              {{upc?.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="true" [errorMessage]="candidateError?.costOwner" (editClicked)="editCostOwner()"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCostOwner(candidate)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForTopToTop(candidate)"
                          (historyClicked)="historyPanelOpen()">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [canEdit]="true" (editClicked)="editWarehouseSupplier()" [errorMessage]="candidateError?.lane"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate,'lane', 'name')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false">
              {{item ? item?.hierarchy?.commodity?.bdm.fullName : dsdItem?.hierarchy?.commodity?.bdm.fullName}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Sellable'" [canEdit]="false">
              {{productData?.productTypeId === 'GOODS'| yesNo}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid [title]="'Product line, Packaging, & Regulatory Info'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false">
              {{productData?.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false">
              {{productData?.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'FSA/HSA eligible'">
              {{productData?.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{productData?.packageDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{upc?.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{upc?.size  }}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{upc?.length}}in x
              {{upc?.width}}in x
              {{upc?.height}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(upc?.weight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false">
              {{item ? item?.totalVolume : dsdItem?.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid [title]="'Unit details'">
        <pm-grid-group style="margin-bottom: 0;">

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
              {{upc?.scanCodeId}}-{{upc?.scanCodeId | upcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product description'">
              {{productData?.productDescription}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(productData?.productDescription) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false" >
              {{productData?.customerFriendlyDescriptionOne}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false">
              {{productData?.customerFriendlyDescriptionTwo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false">
              {{productData?.signRomanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <pm-attachment-upload-review-page [attachments]="candidate.attachments" (updateCallback)="addAttachments($event)"></pm-attachment-upload-review-page>
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="true"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.piaComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate.supplierComment">
                <b>{{candidate.contactEmail}}</b> <br>
                {{candidate.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>

              <br>

              <div *ngIf="!!candidate.scaComment">
                <b>{{candidate.scaName}}</b> <br>
                {{candidate.scaComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.piaComment">
                <b>{{candidate.piaCommentUser}}</b> <br>
                {{candidate.piaComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>

  <pm-review-drawer-container>
    <!--  Existing Cases  -->
    <ng-container *ngIf="displayingExistingCasesDrawer">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
        </div>
        <h2>Existing cases</h2>
        <ng-template *ngIf="productData?.items" ngFor let-casePack [ngForOf]="productData?.items">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!casePack.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(casePack)"></i>
              <i *ngIf="casePack.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(casePack)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{casePack.caseUpc}}-{{casePack.caseUpc | upcCheckDigit}}
                </label>
                <i *ngIf="casePack.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                WHS | {{casePack.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="casePack.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{casePack.supplierItems[0]?.pack}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
            <!--  Inner Pack -->
            <pm-attribute-grid [title]="'Inner Packs'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Inner pack quantity'" [canEdit]="false">
                    {{casePack.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>
        <ng-template *ngIf="productData?.dsdItems" ngFor let-dsdItem [ngForOf]="productData?.dsdItems">
          <hr/>
          <div class="attribute-group">
            <div>
              <i *ngIf="!dsdItem.isViewing" class="pi pi-caret-right" (click)="onClickCasePack(dsdItem)"></i>
              <i *ngIf="dsdItem.isViewing" class="pi pi-caret-down" (click)="onClickCasePack(dsdItem)"></i>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{dsdItem.upc}}-{{dsdItem.upc | upcCheckDigit}}
                </label>
                <i *ngIf="dsdItem.mrt" class="chip">MRT</i>
              </div>
              <p class="attribute-description">
                DSD | {{dsdItem.description}}
              </p>
            </div>
          </div>
          <div class="grid-group" *ngIf="dsdItem.isViewing">
            <!--  Master Pack -->
            <pm-attribute-grid [title]="'Master Pack'">
              <pm-grid-group>
                <pm-grid-column>
                  <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
                    {{dsdItem?.innerPackQuantity}}
                  </pm-grid-cell>
                </pm-grid-column>
              </pm-grid-group>
            </pm-attribute-grid>
          </div>
        </ng-template>

      </div>
    </ng-container>

    <!--  warehouses  -->
    <ng-container *ngIf="displayingWarehouseInfo">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link" (click)="save()">
            </button>
          </div>
        </div>
        <h2>Additional warehouses</h2>
        <hr/>
        <ng-template ngFor let-warehouse [ngForOf]="warehouseData">
          <div class="attribute-group">
            <div>
              <p-checkbox id="whsCheckbox"
                          [(ngModel)]="warehouse.checked"
                          (onChange)="selectedWarehouseChange($event, warehouse)"
                          binary="true">
              </p-checkbox>
            </div>
            <div>
              <div class="attribute-label">
                <label>
                  {{warehouse.name}}
                </label>
              </div>
              <p class="attribute-description">
                ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
              </p>
              <p *ngIf="warehouse.checked && warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
                 class="error-message bicep-error">
                {{warehouseCandidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
              </p>
            </div>
          </div>
          <div *ngIf="warehouse.checked">
            <div class="attributes-stacked">
              <pm-attribute-display-radio [attribute]="warehouse.orderUnitConfig"
                                          [(model)]="warehouse.orderUnitId"
                                          (change)="orderUnitChange($event, warehouse)"
                                          [attributeError]="warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
              </pm-attribute-display-radio>
              <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                              [(model)]="warehouse.orderRestriction"
                                              (selection)="orderRestrictionChange($event, warehouse)"
                                              [attributeError]="warehouseCandidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
              </pm-attribute-display-typeahead>
            </div>
          </div>
          <hr/>
        </ng-template>
        <div class="text-right">
          <p-button class="m-2" label="Cancel" (onClick)="collapse()"></p-button>
          <p-button class="m-2" label="Update" (onClick)="update()" [disabled]="!this.isSelectedWarehouse()"></p-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="piaComments"
                                           [(model)]="tempCommentHolder"
                                           [attributeError]="candidateError?.piaComment">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.piaComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.piaComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>

  </pm-review-drawer-container>

  <!--  Audit History -->
  <pm-review-drawer-container>
    <ng-container *ngIf="showHistoryPanel && !displayingExistingCasesDrawer && !showWarehousePanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="currentHistoryResults">
            <div *ngIf="candidateHistoryService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{candidateHistoryService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Reject" (onClick)="showRejectPanel($event, rejectOverlay, rejectCandidateDiv)" [disabled]="areButtonsDisabled"></p-button>
    <p-button class="m-2" label="{{getActivateButtonTitle()}}" (onClick)="onClickActivate()" [disabled]="areButtonsDisabled || isActivateButtonDisabled()"></p-button>
  </pm-footer>
</pm-review>
<div>
  <div #rejectCandidateDiv></div>
  <pm-static-overlay-panel #rejectOverlay appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
    <pm-reject-candidate-overlay (cancelClicked)="rejectOverlay.hide()" (rejectButtonClicked)="reject($event, rejectOverlay, rejectCandidateDiv)"></pm-reject-candidate-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatConfirmDiv></div>
  <pm-static-overlay-panel #rnaMatConfirmOverlayPanel appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'" [hideTransitionOptions]="'0ms'">
    <pm-request-new-attribute-confirm-overlay *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                              [title]="'Request a new ' + requestNewMatAttributeOverrideWrapper?.requestNewMatAttributeForm?.attributeBusinessFriendlyDescription + ' value'"
                                              [classOverride]="'col-md-5'"
                                              (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatConfirmOverlayPanel, requestNewMatAttributeOverrideWrapper)"
                                              (continueButtonClicked)="matUtilService.showRequestNewAttributeFormPanel($event, rnaMatFormOverlay, rnaMatFormOverlayDiv, rnaMatConfirmOverlayPanel)">
    </pm-request-new-attribute-confirm-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatFormOverlayDiv></div>
  <pm-static-overlay-panel #rnaMatFormOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'">
    <pm-request-new-mat-attribute-form *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                       (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatFormOverlay, requestNewMatAttributeOverrideWrapper)"
                                       [(model)]="requestNewMatAttributeOverrideWrapper.requestNewMatAttributeForm"
                                       (sendRequestClicked)="matUtilService.sendRequestAndCloseModal(this.candidate, requestNewMatAttributeOverrideWrapper, rnaMatFormOverlay)">
    </pm-request-new-mat-attribute-form>
  </pm-static-overlay-panel>
</div>
