<pm-review #pmReview *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-title>{{getPageTitle()}}</pm-title>
  <div *ngIf="candidateErrorUtils.hasCandidateProductErrorMessages(candidateError)">
    <div class="row ml-0 justify-content-md-center">
      <div class="col col-md-10 same-store-error-container">
        <div class="row ml-1">
          <img class="ban-icon" src="/assets/images/ban-icon.svg">
          <div class="header-row">
            Missing Store Errors
          </div>
        </div>
        <ui class="row error-list-row">
          <li *ngFor="let error of candidateErrorUtils.getCandidateProductErrorMessages(candidateError)">
            {{error}}
          </li>
        </ui>
      </div>
    </div>
  </div>
  <div *ngIf="candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages.length > 0 ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div *ngIf="!candidateUtilService.isPlu(candidate)" class="upc-header">
            UPC: {{candidate.candidateProducts[0].upc}}-{{candidate.candidateProducts[0].upcCheckDigit}}
          </div>
          <div *ngIf="candidateUtilService.isPlu(candidate)" class="upc-header">
            <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
              PLU: {{candidate.candidateProducts[0].upc | upc}}-{{candidate.candidateProducts[0].upc | upcCheckDigit}}<br>
            </div>
            <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
              UPC: {{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc)}}-{{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc) | upcCheckDigit}}
            </div>
          </div>
          <div class="attribute-description">
            <p>{{candidateProduct.description}}</p>
            <p>{{getProductInfoString()}}</p>
          </div>
        </div>
      </div>

      <div *ngIf="isNonReplenishItem" class="row pb-3 ml-0" style="padding-top: 32px;">
        <div class="col-md-9 pending-candidates-box">
          <h6 class="ml-2 mt-3">
            Non-replenish item
          </h6>
          <p class="ml-2">
            The supplier set up this UPC as a non-replenishable item in an MRT. This UPC doesn’t have a case and H-E-B will only receive it in the MRT.
          </p>
          <div class="ml-2 attribute-description">
            <p> MRT: {{mrtParentDescription}} | Case UPC: {{candidateProduct.caseUpc}}</p>
          </div>
        </div>
      </div>

      <!--   cost   -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Cost'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.prePrice" (editClicked)="editCandidate(attributeType.PrePrice)">
              {{candidate.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.costLink" (editClicked)="editCandidate(attributeType.CostLinked)">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.costLink" (editClicked)="editCostLink()">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.suggestedXFor, candidateError?.suggestedRetailPrice]" (editClicked)="editCandidate(attributeType.SuggestedRetail)">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="!isNonReplenishItem" [label]="'Master list cost'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]" (editClicked)="costService.editCostRelatedFields(attributeType.MasterListCost, candidate)">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
                Penny profit: {{costService.getPennyProfit(candidate)}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isMarginNegativeOrZero(candidate)}">{{costService.getMargin(candidate)}}%</a>
                <img *ngIf="costService.isMarginNegativeOrZero(candidate)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP">
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell *ngIf="isNonReplenishItem" [label]="'Master list cost'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]" (editClicked)="costService.editCostRelatedFields(attributeType.MasterListCost, candidate)">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Penny profit: {{costService.getPennyProfit(candidate)}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isMarginNegativeOrZero(candidate)}">{{costService.getMargin(candidate)}}%</a>
                <img *ngIf="costService.isMarginNegativeOrZero(candidate)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP">
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.mapRetail" (editClicked)="editCandidate(attributeType.MapRetail)">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   cost   -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Cost'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.prePrice" (editClicked)="editCandidate(attributeType.PrePrice)">
              {{candidate.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.costLink" (editClicked)="editCandidate(attributeType.CostLinked)">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.costLink" (editClicked)="editCostLink()">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.masterListCost, candidateError?.innerListCost]"  (editClicked)="costService.editCostRelatedFields(attributeType.MasterListCost, candidate)">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
                Penny profit: {{costService.getPennyProfit(candidate)}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isMarginNegativeOrZero(candidate)}">{{costService.getMargin(candidate)}}%</a>
                <img *ngIf="costService.isMarginNegativeOrZero(candidate)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP">
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.mapRetail" (editClicked)="editCandidate(attributeType.MapRetail)">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>



      <pm-attribute-grid *ngIf="candidate?.dsdSwitch" [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="isPageEditable"
                          (editClicked)="editCaseDescription(attributeType.CaseDescription)"
                          [errorMessages]="[candidateProductError?.caseDescription]">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell *ngIf="!candidate.overrideDepartment" [label]="'Department'" [canEdit]="isPageEditable" (editClicked)="editDepartment()">
              {{candidate.commodity?.subDepartmentDescription}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="candidate.overrideDepartment" [label]="'Department'" [canEdit]="isPageEditable" (editClicked)="editDepartment()">
              {{candidate.overrideSubDepartment?.subDepartmentDescription}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Channel'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.Channel)"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [canEdit]="isPageEditable" (editClicked)="editCandidateProduct(attributeType.VendorProductCode)"
                          [errorMessage]="candidateProductError?.vendorProductCode">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="isPageEditable"
                          (editClicked)="showEditCandidateProductModal(attributeType.CountryOfOrigin)"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <pm-attribute-grid *ngIf="showWarehouseCasePackInfo()" [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="isPageEditable"
                          (editClicked)="editCaseDescription(attributeType.CaseDescription)"
                          [errorMessages]="[candidateProductError?.caseDescription]">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.Channel)"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [canEdit]="isPageEditable" (editClicked)="editCaseUPC()"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="isPageEditable"
                          (editClicked)="showEditCandidateProductModal(attributeType.CountryOfOrigin)"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [canEdit]="isPageEditable" (editClicked)="editCandidateProduct(attributeType.VendorProductCode)"
                          [errorMessage]="candidateProductError?.vendorProductCode">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack  DSD -->
      <pm-attribute-grid *ngIf="candidate?.dsdSwitch" [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.masterPack" (editClicked)="costService.editCostRelatedFields(attributeType.TotalRetail, candidate)">
              {{candidate.masterPack}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack  Warehouse -->
      <pm-attribute-grid *ngIf="showWarehouseCasePackInfo()" [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.masterPack" (editClicked)="costService.editCostRelatedFields(attributeType.TotalRetail, candidate)">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.masterLength, candidateError?.masterWidth, candidateError?.masterHeight]" (editClicked)="editCandidate(attributeType.Dimensions)">
              {{candidate.masterLength}}in x
              {{candidate.masterWidth}}in x
              {{candidate.masterHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterLength,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.vendorTie,candidateError?.vendorTier ]" (editClicked)="editCandidate(attributeType.BoxesOnPallet)">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="isTiedToCatchOrVariableWeightBuyer() && isPageEditable" [errorMessage]="candidateError?.itemWeightType" (editClicked)="editCandidate(attributeType.ItemWeightType)">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.masterWeight" (editClicked)="editCandidate(attributeType.Weight)">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.cubeAdjustedFactor" (editClicked)="editCandidate(attributeType.OrderFactor)">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="codeDateEditable() && isPageEditable" [errorMessage]="candidateError?.codeDate" (editClicked)="editCandidate(attributeType.CodeDate)">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="candidate?.codeDate && isPageEditable" [errorMessage]="candidateError?.maxShelfLife" (editClicked)="editCandidate(attributeType.MaxShelfLife)">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="candidate?.codeDate && isPageEditable" [errorMessage]="candidateError?.inboundSpecDays"  (editClicked)="editCandidate(attributeType.InboundSpecDays)">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <!--  inner packs -->
      <pm-attribute-grid *ngIf="showWarehouseCasePackInfo() && !candidate.dsdSwitch" [title]="'Inner packs'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs'" [canEdit]="true" [errorMessage]="candidateError?.innerPackSelected" (editClicked)="costService.editCostRelatedFields(attributeType.InnerPackSelected, candidate)">
              {{candidate.innerPackSelected | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.innerPack" (editClicked)="costService.editCostRelatedFields(attributeType.InnerPackQuantity, candidate)">
              {{candidate.innerPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="candidate.innerPackSelected && isPageEditable"   [errorMessages]="[candidateError?.innerLength, candidateError?.innerWidth, candidateError?.innerHeight]" (editClicked)="editCandidate(attributeType.InnerPackDimensions)">
              {{candidate.innerLength}}in x
              {{candidate.innerWidth}}in x
              {{candidate.innerHeight}}in
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="candidate.innerPackSelected && isPageEditable"  [errorMessage]="candidateError?.innerWeight" (editClicked)="editCandidate(attributeType.InnerPackWeight)">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="candidate.displayReadyUnit && isSellable" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.displayReadyUnit" (editClicked)="editCandidate(attributeType.DisplayReadyUnit)">
              {{candidate.displayReadyUnit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="isPageEditable"  [errorMessage]="candidateError?.displayReadyUnitOrientation" (editClicked)="editCandidate(attributeType.Orientation)">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.displayReadyUnitRowsDeep, candidateError?.displayReadyUnitRowsFacing, candidateError?.displayReadyUnitRowsHigh]" (editClicked)="editCandidate(attributeType.RetailUnitCount)">
              {{candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Authorized stores -->
      <pm-attribute-grid *ngIf="supplierProductService.isDsdOnlyParms(candidate)" [title]="'Authorized stores'">
        <pm-grid-group *ngFor="let locationGroupStore of candidate.candidateProducts[0].locationGroupStores">
          <pm-grid-column>
            <pm-grid-cell [label]="'Distributor'" [canEdit]="isPageEditable" (editClicked)="showStoreSearchPanel($event, authGroupsOverlay, authGroupsDiv)">
              <div>{{getVendor(locationGroupStore.splrLocationNumber)?.displayName}}</div>
              <pm-grid-cell-info>
                <b>Total stores: {{locationGroupStore.stores.length}} of {{getNumberOfStoresForGroup(locationGroupStore.splrLocationGroupId)}} stores</b>
              </pm-grid-cell-info>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Authorization group'">
              <div>{{locationGroupStore.splrLocationGroupId}}</div>
              <pm-grid-cell-info>
                <div *ngIf="locationGroupStore.listCost">${{locationGroupStore.listCost| number : '1.2-2'}}</div>
                <div *ngIf="!locationGroupStore.listCost">${{candidate.masterListCost| number : '1.2-2'}}</div>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Stores'" [errorMessages]="[candidateErrorUtils.get706StoreError(candidateProductError, locationGroupStore.splrLocationGroupId)]">
              <div class="mb-2">{{getAuthGroupLabel(locationGroupStore)}}</div>
              <div *ngFor="let store of locationGroupStore.stores">
                <div class="ui-grid-col-2 mr-2 mb-2" style="background: #f2f2f2; text-align: center;">{{store.custLocationNumber}}</div>
              </div>
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-cell *ngIf="candidateProductError?.missingStoresError"
                      [errorMessage]="candidateProductError?.missingStoresError">
        </pm-grid-cell>
      </pm-attribute-grid>

      <div *ngIf="candidate.dsdSwitch && !candidateUtilService.hasStores(candidate)" class="addDistributors">
        <button pButton label="Set up Stores" class="ui-button-info mt-2" (click)="showStoreSearchPanel($event, authGroupsOverlay, authGroupsDiv)" [disabled]="isStoreSearchDisabled()"></button>
      </div>

      <div *ngIf="supplierProductService.isDsdOnlyParms(candidate)" class="addDistributors">
        <button pButton *ngIf="isPageEditable" label="Add more distributors" class="ui-button-info mt-2" (click)="onClickAddDistributors()" [disabled]="isStoreSearchDisabled()"></button>
      </div>
      <div *ngIf="candidate.dsdSwitch && isStoreSearchDisabled()" >
        <div class="col-md-9 missing-data-box">
          <h6 class="ml-2 mt-3 header-row">
            <img class="ban-icon" src="/assets/images/ban-icon.svg">Missing required data
          </h6>
          <p class="ml-4 pb-3 pl-3">
            You must select a Commodity before you can authorize stores or add more distributors.
          </p>
        </div>
      </div>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.brand"  (editClicked)="editBrandModal()">
              {{candidate.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="isPageEditable" [errorMessage]="candidateProductError?.subBrand"  (editClicked)="showEditCandidateProductModal(attributeType.SubBrand)">
              {{candidateProduct.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.costOwner"  (editClicked)="editCostOwner()">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="isPageEditable" [errorMessages]="[candidateError?.contactName, candidateError?.contactEmail,candidateError?.contactNumber]"
                          (editClicked)="editContact()">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.topToTop"   (editClicked)="editCostOwner()">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.Vendor)" [errorMessage]="candidateError?.vendor">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="!supplierProductService.isDsdOnlyParms(candidate) && !isNonReplenishItem" [label]="'Warehouse supplier'" [canEdit]="isPageEditable" (editClicked)="editWarehouseSupplier()" [errorMessage]="candidateError?.lane">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.buyer" (editClicked)="editBuyer()">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>

            <pm-grid-cell [label]="'Sellable'"  [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.Sellable)" [errorMessage]="candidateError?.productType">
              {{this.candidate.productType === 'SELLABLE' | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product line, packaging, & regulatory info'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.FoodStampEligible)" [errorMessage]="candidateError?.foodStamp">
              {{candidate.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.Taxable)" [errorMessage]="candidateError?.taxable">
              {{candidate.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell (editClicked)="editCandidate(attributeType.FSAEligible)" [canEdit]="isPageEditable"
                          [errorMessage]="candidateError?.flexibleSpendingAccount" [label]="'FSA/HSA eligible'">
              {{candidate.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deposit UPC'" [canEdit]="true" (editClicked)="editDepositUPC()" [errorMessages]="[candidateProductError?.depositScanCodeId]">
              {{candidateProduct.depositScanCodeId}}-{{candidateProduct.depositScanCodeCheckDigit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="isPageEditable" (editClicked)="editPackageType()" [errorMessage]="candidateError?.packageType">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="isPageEditable" (editClicked)="editUnitOfMeasure()" [errorMessage]="candidateError?.unitOfMeasure">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.RetailSize)" [errorMessage]="candidateError?.retailSize">
              {{candidate.retailSize}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Show Calories'" [canEdit]="isPageEditable" (editClicked)="editShowCalories()">
              {{candidate.showCalories | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.ProductDimensions)"
                          [errorMessages]="[candidateError?.productLength, candidateError?.productWidth, candidateError?.productHeight]">
              {{candidate.productLength}}in x
              {{candidate.productWidth}}in x
              {{candidate.productHeight}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.ProductWeight)" [errorMessage]="candidateError?.productWeight">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.TotalVolume)" [errorMessage]="candidateError?.totalVolume">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line & Packaging  -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product line & Packaging'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="isPageEditable" (editClicked)="editPackageType()" [errorMessage]="candidateError?.packageType">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="isPageEditable" (editClicked)="editUnitOfMeasure()" [errorMessage]="candidateError?.unitOfMeasure">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Show Calories'" [canEdit]="isPageEditable" (editClicked)="editShowCalories()">
              {{candidate.showCalories | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.ProductDimensions)">
              {{candidate.productLength}}in x
              {{candidate.productWidth}}in x
              {{candidate.productHeight}}in
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.ProductWeight)" [errorMessage]="candidateError?.productWeight">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.TotalVolume)" [errorMessage]="candidateError?.totalVolume">
              {{candidate.totalVolume}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="isPageEditable" (editClicked)="editCandidate(attributeType.RetailSize)" [errorMessage]="candidateError?.retailSize">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false" [errorMessage]="candidateProductError?.upc">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="true" [errorMessage]="candidateProductError?.upc" (editClicked)="editPlu()">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="isPageEditable" [errorMessage]="candidateProductError?.description"  (editClicked)="editProductDescription()">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="isPageEditable" [errorMessage]="candidateProductError?.customerFriendlyDescription1"  (editClicked)="editCandidateProduct(attributeType.ShelfTag1)">
              {{candidateProduct.customerFriendlyDescription1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="isPageEditable" [errorMessage]="candidateProductError?.customerFriendlyDescription2"  (editClicked)="editCandidateProduct(attributeType.ShelfTag2)">
              {{candidateProduct.customerFriendlyDescription2}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="isPageEditable"  [errorMessage]="candidateProductError?.romanceCopy" (editClicked)="editCandidateProduct(attributeType.ECommCopy)">
              {{candidateProduct.romanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="isPageEditable"  [errorMessage]="candidateProductError?.categorySelectionError" (editClicked)="editCategory()">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Images'" [canEdit]="isPageEditable" (editClicked)="editImages()" [errorMessages]="[candidateProductError?.imageLinks, candidateProductError?.imageLinksOmissionDate]">
              <div *ngIf="!!productImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!productImages?.length">
                {{candidateUtilService.getImageLinksOmissionReasons(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <pm-attachment-upload-review-page [attachments]="candidate.attachments" (updateCallback)="addAttachments($event)"></pm-attachment-upload-review-page>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false" [errorMessage]="candidateProductError?.upc">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="true" [errorMessage]="candidateProductError?.upc" (editClicked)="editPlu()">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="isPageEditable" [errorMessage]="candidateProductError?.description"  (editClicked)="editProductDescription()">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="isPageEditable"  [errorMessage]="candidateProductError?.categorySelectionError" (editClicked)="editCategory()">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="isPageEditable" (editClicked)="editImages()" [errorMessages]="[candidateProductError?.imageLinks, candidateProductError?.imageLinksOmissionDate]">
              <div *ngIf="!!productImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!productImages?.length">
                {{ candidateUtilService.getImageLinksOmissionReasons(candidateProduct) }}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
              <pm-attachment-upload-review-page [attachments]="candidate.attachments" (updateCallback)="addAttachments($event)"></pm-attachment-upload-review-page>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Global Attributes   -->
      <div *ngIf="showMatAttributes">
        <pm-attribute-grid [title]="'Extended attributes: Product'">
          <div *ngIf="isLoadingMatGlobalData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>

        <pm-attribute-grid [title]="'Extended attributes: UPC'">
          <div *ngIf="isLoadingMatGlobalData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>

        <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && isLoadingMatGlobalData" [title]="'Extended attributes: Case'">
          <div style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
        </pm-attribute-grid>
        <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && !isLoadingMatGlobalData && !!supplierProductService.warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of supplierProductService.warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                              (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, true, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                              [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>
      </div>
      <!--   Mat Hierarchy   -->
      <pm-attribute-grid *ngIf="showMatAttributes && isLoadingMatHierarchyData && matUtilService.getMatHierarchyList(candidate).length > 0" [title]="'Category attributes'">
        <div style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingMatHierarchyData" [strokeWidth]="'2'"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
          <h5 class="ml-3">Fetching extended attribute data...</h5>
        </div>
      </pm-attribute-grid>
      <div *ngIf="showMatAttributes && supplierProductService.hierarchyNumberToAttributesMap.size > 0">
        <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
          <pm-attribute-grid *ngIf="supplierProductService.hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of supplierProductService.hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="isPageEditable"
                                (editClicked)="editCandidateModalService.editMatAttribute(candidateProduct, attribute, false, requestNewMatAttributeOverrideWrapper, rnaMatConfirmOverlayPanel, rnaMatConfirmDiv)"
                                [errorMessages]="matUtilService.getAttributeError(attribute.identifiers?.fieldId, candidateProductError)">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="isPageEditable"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.buyerComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate.supplierComment">
                <b>{{candidate.contactEmail}}</b> <br>
                {{candidate.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
    </div>
  </div>

  <pm-review-drawer-container>
    <ng-container *ngIf="candidate && showBuyerReviewDrawer">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link"
                    (click)="save()"></button>
          </div>
        </div>
        <div class="attributes-stacked">

          <h2>Buyer Details</h2>
          <p>Provide the following information and click approve to accept the product and send it to SCA and Procurement
            Support review.</p>
          <h3>Required Fields</h3>
          <!--          // TODO: Remove once bug fixed in component-->
          <pm-attribute-display-typeahead [attribute]="merchandiseTypeConfiguration"
                                          [(model)]="candidate.merchandiseType" [attributeError]="candidateError?.merchandiseType"
                                          (selection)="merchandiseTypeChange($event)">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-typeahead [attribute]="commodityConfiguration" [(model)]="candidate.commodity"
                                          [attributeError]="candidateError?.commodity"
                                          (selection)="commodityChange($event, storeGroupsChangeOverlay, storeGroupsChangeDiv)">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-typeahead [attribute]="subCommodityConfiguration" [(model)]="candidate.subCommodity"
                                          [attributeError]="candidateError?.subCommodity" (selection)="subCommodityChange($event)">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-typeahead [attribute]="pssDepartmentConfiguration" [(model)]="candidate.pssDepartment"
                                          [attributeError]="candidateError?.pssDepartment" (selection)="pssDepartmentChange($event)">
          </pm-attribute-display-typeahead>

          <pm-attribute-display-text-input [attribute]="inboundSpecConfiguration"
                                           [attributeError]="candidateError?.inboundSpecDays"
                                           [(model)]="candidate.inboundSpecDays">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="reactionDaysConfiguration"
                                           [(model)]="candidate.warehouseReactionDays" [attributeError]="candidateError?.warehouseReactionDays">
          </pm-attribute-display-text-input>

          <pm-attribute-display-text-input [attribute]="guaranteeToStoreDaysConfiguration"
                                           [(model)]="candidate.guaranteeToStoreDays" [attributeError]="candidateError?.guaranteeToStoreDays">
          </pm-attribute-display-text-input>

          <pm-attribute-display-radio *ngIf="isSellable" [attribute]="pricingTypeConfiguration" [(model)]="candidate.retailType"
                                      [attributeError]="candidateError?.retailType" (change)="pricingTypeChange($event)">
          </pm-attribute-display-radio>

          <pm-attribute-display-muli-source-input *ngIf="isSellable"
                                                  [attribute]="retailLinkConfiguration"
                                                  [attributeError]="candidateError?.retailLink"
                                                  [model]="candidate?.retailLink?.searchedId"
                                                  (modelChange)="onRetailLinkNumberChange($event)"
                                                  [selectCategory]="candidate?.retailLink?.upcType"
                                                  (selectCategoryChange)="onRetailLinkTypeChange($event)"
                                                  [state]="retailLinkState"
                                                  (validate)="validateRetailLink()">
          </pm-attribute-display-muli-source-input>

          <pm-attribute-display-dual-numeric-input *ngIf="isSellable"
                                                   [attribute]="xForConfiguration"
                                                   [(firstInputModel)]="candidate.retailXFor"
                                                   [(secondInputModel)]="candidate.retailPrice"
                                                   [firstInputModelError]="candidateError?.retailXFor"
                                                   [secondInputModelError]="candidateError?.retailPrice">
          </pm-attribute-display-dual-numeric-input>

          <div *ngIf="isSellable" class="row" style="margin-left: 0; margin-bottom: 25px;">
            <pm-attribute-display-text-input [attribute]="pennyProfitConfiguration"
                                             [model]="costService.getHebPennyProfit(candidate)">
            </pm-attribute-display-text-input>

            <pm-attribute-display-text-input [attribute]="marginPercentConfiguration"
                                             [model]="costService.getHebMargin(candidate)">
            </pm-attribute-display-text-input>
          </div>

          <h3>Additional fields</h3>
          <pm-attribute-display-text-input *ngIf="isSellable" [attribute]="likeItemCodeConfiguration" [(model)]="candidate.likeId"
                                           [attributeError]="candidateError?.likeId">
          </pm-attribute-display-text-input>
          <pm-attribute-display-text-input *ngIf="isSellable" [attribute]="estimatedStoreCountConfiguration" [(model)]="candidate.numberOfStores"
                                           [attributeError]="candidateError?.numberOfStores">
          </pm-attribute-display-text-input>
          <div *ngIf="isSellable" class="row season-year-row">
            <div class="col-9" style="padding-right: 0;">
              <pm-attribute-display-typeahead [attribute]="seasonConfiguration"
                                              [(model)]="candidate.season"
                                              [attributeError]="candidateError?.season"
                                              (selection)="seasonChange($event)"
                                              [showErrorMessage]="false">
              </pm-attribute-display-typeahead>
            </div>
            <div class="col-3" style="padding-left: 0;">
              <pm-attribute-display-text-input [attribute]="yearConfiguration" [(model)]="candidate.seasonYear"
                                               [attributeError]="candidateError?.seasonYear"
                                               [showErrorMessage]="false">
              </pm-attribute-display-text-input>
            </div>
          </div>
          <p *ngIf="isSellable && candidateError?.season" class="error-message pl-2">
            {{candidateError.season}}
          </p>
          <p *ngIf="isSellable && candidateError?.seasonYear" class="error-message pl-2">
            {{candidateError.seasonYear}}
          </p>
          <pm-attribute-display-toggle *ngIf="isSellable" [attribute]="soldByWeightConfiguration"
                                       [(model)]="candidate.weightSwitch"
                                       (change)="soldByWeightChange($event)">
          </pm-attribute-display-toggle>

          <pm-attribute-display-text-input *ngIf="!supplierProductService.isDsdOnlyParms(candidate)" [attribute]="remark1Configuration"
                                           [(model)]="candidateProduct.remark1"
                                           [attributeError]="candidateProductError?.remark1">
          </pm-attribute-display-text-input>
          <pm-attribute-display-text-input  *ngIf="!supplierProductService.isDsdOnlyParms(candidate)" [attribute]="remark2Configuration"
                                            [(model)]="candidateProduct.remark2"
                                            [attributeError]="candidateProductError?.remark2">
          </pm-attribute-display-text-input>
          <pm-attribute-display-checkbox class="checkbox-padding" [attribute]="rushFlagConfiguration"
                                         [(model)]="candidate.rushFlag">
          </pm-attribute-display-checkbox>

          <div class="text-right">
            <p-button class="m-2" label="Approve" (onClick)="onClickApprove()" [disabled]="isApproveDisabled"></p-button>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="showAddMoreSuppliers">
      <div class="container p-3 addMoreDistributorsDrawer">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="closeInviteSuppliersPanel()"></button>
          </div>
        </div>
        <div class="attributes-stacked">
          <h2>Invite Suppliers</h2>
          <p>Choose your Suppliers and we’ll invite them to set up store authorization for this product after it’s activated.</p>

          <div *ngIf="candidate.candidateProducts[0].invitedSuppliers?.length > 0" class="invitedSuppliersContainer">
            <div class="row headerRow">
              <div class="col-md-10 pl-0 invitedHeader">
                Invited Suppliers <br>
                <div class="invitedHeaderSubText">
                  {{getSupplierHeaderText()}}
                </div>
              </div>
              <i *ngIf="!isViewingInvitedVendors" class="pi pi-caret-up col-md-2 text-right" (click)="isViewingInvitedVendors = !isViewingInvitedVendors"></i>
              <i *ngIf="isViewingInvitedVendors" class="pi pi-caret-down col-md-2 text-right" (click)="isViewingInvitedVendors = !isViewingInvitedVendors"></i>

              <div *ngIf="isViewingInvitedVendors" class="invitedBody">
                <ng-template ngFor let-invitedSupplier let-i="index" [ngForOf]="candidate.candidateProducts[0].invitedSuppliers">
                  <div class="mt-3">
                    {{invitedSupplier.vendor.displayName}}<br>
                    <div class="ml-0 row">
                      <div class="invitedBodySubText">
                        Invited
                      </div>
                      <div class="invitedBodyDivider">
                        &nbsp; | &nbsp;
                      </div>
                      <div>
                        <button pButton type="button" label="Remove" class="ui-button-link" (click)="removeInvitedSupplier(invitedSupplier.vendor.apNumber)"></button>

                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

          <pm-attribute-display-typeahead [attribute]="supplierConfiguration"
                                          [(model)]="vendorsToInvite"
                                          (selection)="onInvitedVendorSelection($event)">
          </pm-attribute-display-typeahead>

          <div class="text-right">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="closeInviteSuppliersPanel()"></p-button>
            <p-button type="button" label="Invite" class="m-2" (click)="inviteSuppliers()" [disabled]="!hasVendorsToInvite()"></p-button>
          </div>
        </div>


      </div>
    </ng-container>
    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="buyerComments"
                                           [(model)]="tempCommentHolder"
                                           [attributeError]="candidateError?.buyerComment">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.buyerComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.buyerComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button *ngIf="isPageEditable" class="ghost m-2" label="Reject" (onClick)="showPanel($event, rejectOverlay, rejectCandidateDiv)" [disabled]="isRejectDisabled"></p-button>
    <p-button *ngIf="isPageEditable" class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-review>

<div>
  <div #rejectCandidateDiv></div>
  <pm-static-overlay-panel #rejectOverlay appendTo="body" [style]="{width: '100%', height: '100%', top: 0}">
    <pm-reject-candidate-overlay (cancelClicked)="rejectOverlay.hide()" (rejectButtonClicked)="reject($event, rejectOverlay, rejectCandidateDiv)"></pm-reject-candidate-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #authGroupsDiv></div>
  <pm-static-overlay-panel [hideTransitionOptions]="'0ms ease-in'" [showTransitionOptions]="'0ms ease-out'" #authGroupsOverlay appendTo="body" [style]="{width: '100%', height: '100%'}">
    <app-set-up-stores-overlay *ngIf="showStoreOverlay" [canClickSave]="canClickSave"
                               [candidate]="candidate"
                               [candidateError]="supplierOverlayCandidateError"
                               [candidateProductError]="supplierOverlayCandidateProductError"
                               (cancelButtonClicked)="hideStoreSearchPanel(authGroupsOverlay)" (saveButtonClicked)="saveClicked($event, authGroupsOverlay)">
    </app-set-up-stores-overlay>
  </pm-static-overlay-panel>
</div>
<div>
  <div #storeGroupsChangeDiv></div>
  <pm-static-overlay-panel #storeGroupsChangeOverlay appendTo="body" [style]="{width: '100%', height: '100%'}">
    <ng-container>
      <div class="row align-items-center store-groups-change-overlay-wrapper">
        <div class="col-md-4 store-groups-change-overlay">
          <div class="header-row">
            <h2>Updates required from Supplier</h2>
            <p>
              If you change the commodity to {{tempCommodity?.commodityName}}, we'll need to ask the
              Supplier to update their store authorization requests. You won't be able to finish reviewing
              this item until the Supplier does this.
            </p>
          </div>
          <div class="row button-row">
            <p-button type="button" class="ghost mr-2" label="Cancel" (onClick)="cancelStoreAuth(storeGroupsChangeOverlay)"></p-button>
            <p-button type="button" label="Change commodity & notify Supplier" class="ml-2" (onClick)="changeDsdCommodity()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatConfirmDiv></div>
  <pm-static-overlay-panel #rnaMatConfirmOverlayPanel appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'" [hideTransitionOptions]="'0ms'">
    <pm-request-new-attribute-confirm-overlay *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                              [title]="'Request a new ' + requestNewMatAttributeOverrideWrapper?.requestNewMatAttributeForm?.attributeBusinessFriendlyDescription + ' value'"
                                              [classOverride]="'col-md-5'"
                                              (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatConfirmOverlayPanel, requestNewMatAttributeOverrideWrapper)"
                                              (continueButtonClicked)="matUtilService.showRequestNewAttributeFormPanel($event, rnaMatFormOverlay, rnaMatFormOverlayDiv, rnaMatConfirmOverlayPanel)">
    </pm-request-new-attribute-confirm-overlay>
  </pm-static-overlay-panel>
</div>

<div>
  <div #rnaMatFormOverlayDiv></div>
  <pm-static-overlay-panel #rnaMatFormOverlay appendTo="body" [classOverride]="'pm-overlaypanel-full-size-fixed-top'">
    <pm-request-new-mat-attribute-form *ngIf="requestNewMatAttributeOverrideWrapper?.showPanel"
                                       (cancelClicked)="matUtilService.hideMatRequestAttributePanel(rnaMatFormOverlay, requestNewMatAttributeOverrideWrapper)"
                                       [(model)]="requestNewMatAttributeOverrideWrapper.requestNewMatAttributeForm"
                                       (sendRequestClicked)="matUtilService.sendRequestAndCloseModal(this.candidate, requestNewMatAttributeOverrideWrapper, rnaMatFormOverlay)">
    </pm-request-new-mat-attribute-form>
  </pm-static-overlay-panel>
</div>
