import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ImageLinksOmissionReason } from 'pm-models/lib/imageLinksOmissionReason';

@Injectable({
  providedIn: 'root'
})
export class ImageLinkService {

  constructor(private http: HttpClient) {
  }

  private IMAGE_LINKS_BASE_URL = 'candidates/image_links';
  private OMISSION_REASONS_URL = '/omission_reasons';

  public getImageLinksOmissionReasons(): Observable<ImageLinksOmissionReason[]> {
    return this.http.get<ImageLinksOmissionReason[]>(this.IMAGE_LINKS_BASE_URL + this.OMISSION_REASONS_URL);
  }
}
