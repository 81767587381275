import {Component, OnInit, ViewChild} from '@angular/core';
import {VertexTaxCategoryDefaults} from 'pm-models/lib/vertexTaxCategoriesDefault';
import {GrowlService} from '../growl/growl.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierProductService} from '../service/supplier-product.service';
import {WorkflowService} from '../service/workflow.service';
import {CandidateService} from '../service/candidate.service';
import {LookupService} from '../service/lookup.service';
import {
  AttributeConfig,
  AttributeTextInputConfig,
  AttributeTypeaheadConfig,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  Commodity,
  LocationGroupStores,
  Task,
  TaskDecision,
  TextInputType,
  Vendor,
  WAREHOUSE_MAXSHIP_MAX
} from 'pm-models';
import {AttributeTypes, InputState, ReviewComponent, UpcService} from 'pm-components';
import {EditCandidateModalService} from '../service/edit-candidate-modal.service';
import {CostService} from '../service/cost.service';
import {FileService} from '../service/file.service';
import {CandidateUtilService} from '../service/candidate-util.service';
import {AuthService} from '../auth/auth.service';
import {QualifyingCondition} from 'pm-models/lib/qualifyingCondition';
import {Observable} from 'rxjs';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {UUID} from 'angular2-uuid';
import {EmailService} from '../service/email.service';
import {CandidateErrorUtilService} from '../service/candidate-error-util.service';
import {OwnBrandService} from '../service/ownbrand.service';
import {NewUpcRequest} from 'pm-models/lib/newUpcRequest';
import {ProcessInstanceConstants} from '../utils/constants/process-instance-constants';
import {MatUtilService} from '../service/mat-util.service';
import {ItemWeightTypeService} from '../service/item-weight-type.service';
import {LabelInsightService} from '../service/label-insight.service';
import {ProcessVariables} from 'pm-models/lib/processVariables';
import {NgxPermissionsService} from 'ngx-permissions';
import {CandidateHistoryService} from '../service/candidate-history.service';
import {RequestNewMatAttributeOverrideWrapper} from 'pm-components/lib/attributes/attribute-type';

@Component({
  selector: 'app-procurement-support-product-review',
  templateUrl: './procurement-support-product-review.component.html',
  styleUrls: ['./procurement-support-product-review.component.scss'],
  providers: [ EditCandidateModalService ]
})
export class ProcurementSupportProductReviewComponent implements OnInit {

  @ViewChild(ReviewComponent) pmReview;

  public PROCUREMENT_SUPPORT_TASK_NAME = 'PIA Final Review';
  public CASE_ID = 'C';
  public EACH_ID = 'E';
  public PRICE_REQUIRED = 'Price Required';
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';

  private DSD_INVITED_SUPPLIER_WORKFLOW = 'dsdInvitedSupplierProcess';

  constructor(public route: ActivatedRoute, public workflowService: WorkflowService,
              public router: Router, public candidateService: CandidateService, public lookupService: LookupService,
              public growlService: GrowlService, public editCandidateModalService: EditCandidateModalService,
              public costService: CostService, public fileService: FileService, public candidateUtilService: CandidateUtilService,
              public authService: AuthService, public supplierProductService: SupplierProductService,
              public emailService: EmailService, public candidateErrorUtils: CandidateErrorUtilService,
              public upcService: UpcService, public ownBrandService: OwnBrandService,
              public matUtilService: MatUtilService, private itemWeightTypeService: ItemWeightTypeService,
              public labelInsightService: LabelInsightService, public permissionService: NgxPermissionsService,
              public candidateHistoryService: CandidateHistoryService) { }

  public taskSubscription$: any;
  public task: Task;
  public isActivateDisabled = false;
  public isRejectDisabled = false;
  public candidate: Candidate;
  public candidateProduct: CandidateProduct;
  public originalCandidate: Candidate;
  public lastSupplierChangedCandidate: Candidate;
  public lastSupplierChangedCandidateProduct: CandidateProduct;
  public commodities: any;
  public subCommodities: any;
  public warehouses = [];
  public qualifyingConditions: QualifyingCondition[] = [];
  public merchandiseTypes: any;
  public isViewingPage = true;
  public candidateError: CandidateError = new CandidateError();
  public candidateProductError: CandidateProductError;
  public supplierOverlayCandidateError: CandidateError = new CandidateError();
  public supplierOverlayCandidateProductError: CandidateProductError;
  public warehouseCandidateError: CandidateError;
  public warehouseCandidateProductError: CandidateProductError;
  public orderRestrictions: any[];
  public warehouseData = [];
  public productImages = [];
  public labelInsightImages = [];
  public packageTypes: any;
  public unitsOfMeasures: any;
  public openCommentDrawer = false;
  public tempCommentHolder: string;
  public piaName: string;
  public decimalCount = 1;
  public mrtParentDescription: string;
  public inProgressMrtParentCandidates: Candidate[] = undefined;
  public isNonReplenishItem: boolean = false;
  public defaultTaxCategories: VertexTaxCategoryDefaults;
  public isPartOfMrt: boolean = false;
  public showHistoryPanel: boolean = false;
  public showWarehousePanel: boolean = false;
  public currentHistoryResults: any = undefined;
  public candidateAudits: any = undefined;
  public candidateProductAudits: any = undefined;

  public isWarehouseMissingResolved = false;

  public suppliers = new Map();
  public canClickSave: boolean = true;
  public showAddMoreSuppliers = false;
  public vendorsToInvite: Vendor[];
  public isViewingInvitedVendors: boolean = false;
  public invitedSuppliersString = '';
  public showStoreOverlay: boolean = false;
  showMatAttributes = false;
  isLoadingMatGlobalData = true;
  isLoadingMatHierarchyData = true;
  public pssDepartments: any;
  public costLinkState: InputState;
  public requestNewMatAttributeOverrideWrapper = new RequestNewMatAttributeOverrideWrapper();


  maxShipConfiguration: AttributeTextInputConfig = {
    label: 'Max ship',
    description: 'The maximum # of cases of this product that a store can receive.',
    isDisabled: () => false,
    isReadOnly: () => true,
    isRequired: false,
    textInputType: TextInputType.integer,
    inputGroupClass: 'ui-narrow-input',
    placeholderText: '# of cases',
    maxLength: 5
  };

  getOrderUnitConfiguration(wareHouseId): AttributeConfig {
    return  {
      label: 'Order unit',
      name: `orderUnit_${wareHouseId}`,
      description: '',
      isDisabled: () => false,
      isReadOnly: () => false,
      isRequired: true,
      inputGroupClass: 'attribute-radios-row',
      options: [
        { label: 'Case', value: this.CASE_ID },
        { label: 'Each', value: this.EACH_ID},
      ]
    };
  }

  orderRestrictionConfiguration: AttributeTypeaheadConfig = {
    label: 'Order restriction',
    description: '',
    isRequired: true,
    isDisabled: () => false,
    isReadOnly: () => false,
    name: '',
    displayRef: 'displayName',
    placeholderText: '',
    collections: this.orderRestrictions
  };

  piaComments: AttributeTextInputConfig = {
    label: 'Comments',
    description: ``,
    isDisabled: () => false,
    isReadOnly: () => false,
    inputGroupClass: 'attribute-full-drawer-height',
    textInputType: TextInputType.textarea,
    placeholderText: 'Add notes or instructions for reviewers. This will only be visible in PAM.',
    name: 'piaCommentId',
    maxLength: 4000
  };

  supplierConfiguration: AttributeTypeaheadConfig;

  expandCollapseMissingWarehouseButtonText: string = 'Collapse';
  isExpandedWarehouse: boolean = true;

  ngOnInit() {
    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      // if url params has task id
      if (params.has('taskId')) {
        this.workflowService.getTaskByIdWithVariables(params['params']['taskId'])
          .subscribe((task) => {
            this.task = task;
            if (this.task.name !== this.PROCUREMENT_SUPPORT_TASK_NAME)  {
              this.router.navigate(['/tasks'], {
                queryParams: {
                  growlMessage: 'Candidate is not in ' + this.PROCUREMENT_SUPPORT_TASK_NAME + ' status.',
                  growlToUse: GrowlService.SEVERITY_ERROR
                }
              }).then();
            }
            this.candidateService.getCandidate(task.candidateId)
              .subscribe((candidate) => {
                this.costService.updateCostLink(candidate).subscribe((updatedCandidate) => {
                  this.costService.updateRetailLink(updatedCandidate).subscribe( (upToDateCandidate) => {
                      this.setInitialValues(upToDateCandidate);
                      this.candidateHistoryService.setInitialValues(upToDateCandidate);
                    }
                  );
                });
              });
          }, (error) => {
            // if there was an error retrieving task, route back to tasks page with the error
            this.router.navigate(['/tasks'], {
              queryParams: { growlMessage: error.error.message, growlToUse: GrowlService.SEVERITY_ERROR } }).then();
          });
        // else route back to tasks
      } else {
        this.router.navigate(['/tasks']).then();
      }
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllQualifyingConditions().subscribe(qualifyingConditions => {
      this.qualifyingConditions = qualifyingConditions;
    });
  }

  private getDefaultTaxCategories(candidate: Candidate) {
    if (!candidate.subCommodity) {
      return;
    }

    this.lookupService.findDefaultTaxCategories(candidate.subCommodity.subCommodityId, candidate.taxable.toString())
      .subscribe(defaultTaxCategories => {
          this.defaultTaxCategories = defaultTaxCategories;
        }
      );
  }

  private setInitialValues(candidate: Candidate) {
    // Field migration for graphicsCode to graphicsCodeOne
    if (!!candidate.candidateProducts && !!candidate.candidateProducts.length) {
      candidate.candidateProducts.forEach(cp => {
        if (!!cp.scaleInformation && (cp.scaleInformation.graphicsCode !== undefined && cp.scaleInformation.graphicsCode !== null)) {
          cp.scaleInformation.graphicsCodeOne = cp.scaleInformation.graphicsCode;
          cp.scaleInformation.graphicsCode = null;
        }
      });
    }
    this.supplierProductService.resetService();
    this.setOriginalAndCurrentCandidate(candidate);

    this.getOrderRestrictions();

    if (this.originalCandidate.productType) {
      this.findMerchandiseTypesAndSetDefault();
    }

    if (candidate.brand?.brandId !== undefined) {
      this.setCostOwners(candidate.brand.brandId);
    }

    if (candidate.buyer?.buyerId) {
      this.setCommoditiesAndSubCommodities(candidate.buyer.buyerId);
    }

    this.getDefaultTaxCategories(candidate);

    if (this.candidate.vendor.apNumber !== undefined) {
      this.setVendor(this.candidate.vendor.apNumber);
    }

    // Always have to check if a this 'draft' candidate is part of an MRT
    this.setMrtCaseInfo();

    this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
    this.labelInsightImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].labelInsightsImageLinks);

    this.piaName = this.authService.getUser();
    if (this.candidate.piaComment) {
      this.tempCommentHolder = this.candidate.piaComment;
    }
    if (this.candidate.dsdSwitch) {
      this.setAuthGroups();
    }

    if (!this.candidate.vertexTaxCategoryModel) {
      this.setVertexTaxCategoryModel();
    }

    this.setupMatAttributes();

    this.getPssDepartments(candidate);
  }

  setupMatAttributes() {
    this.isLoadingMatHierarchyData = true;
    this.isLoadingMatGlobalData = true;
    this.supplierProductService.hierarchyNumberToAttributesMap = new Map();

    if (!this.permissionService.getPermission('ROLE_CATEGORY_SELECTION-EDIT')) {
      this.isLoadingMatHierarchyData = false;
      this.isLoadingMatGlobalData = false;
      return;
    }
    this.showMatAttributes = true;

    this.matUtilService.updateMatHierarchy(this.candidateProduct).pipe(
      tap((hasHierarchyChanges) => {
        this.supplierProductService.hasHierarchyUpdateChanges = hasHierarchyChanges;
      }),
      switchMap(() => this.matUtilService.updateMatAttributesAndValues(this.candidate,
        this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes)),
      tap(() => {
        this.matUtilService.setHierarchyNumberToAttributesMapIfEmpty(this.supplierProductService.hierarchyAttributes,
          this.supplierProductService.hierarchyNumberToAttributesMap);
        this.matUtilService.addGlobalAttributesToApplicableTypeListsIfNotPresent(this.supplierProductService.globalAttributes,
          this.supplierProductService.productAttributes, this.supplierProductService.warehouseItemAttributes, this.supplierProductService.upcAttributes);
      }),
      finalize(() => {
        this.isLoadingMatHierarchyData = false;
        this.isLoadingMatGlobalData = false;
      })
    ).subscribe();
  }

  /**
   * Sets the original and current candidate objects. The original represents the original state of the candidate.
   * The current is a copy of the original.
   *
   * @param {Candidate} candidate Candidate received from the back end.
   */
  private setOriginalAndCurrentCandidate(candidate: Candidate) {
    this.originalCandidate = candidate;
    this.candidate = JSON.parse(JSON.stringify(this.originalCandidate));
    this.candidateProduct = this.candidate.candidateProducts[0];
  }

  /**
   * Sets Commodities and sub commodities.
   * @param buyerId the buyer id.
   */
  private setCommoditiesAndSubCommodities(buyerId) {
    this.lookupService.findAllCommoditiesByBuyerId(buyerId).subscribe( data => {
      this.commodities = data;
      const commodity = this.findInitSelectedCommodity(data);
      // if the commodity was initially selected on page load, get the sub commodities related to it.
      if (commodity) {
        this.getSubCommodities(commodity);
      } else {
        this.subCommodities = [];
      }
    });
  }

  private updateRegulatoryFields() {
    this.candidate.foodStamp = CandidateHelper.isFoodStamp(this.candidate);
    this.candidate.taxable = CandidateHelper.isTaxCode(this.candidate);
  }

  /**
   * Finds the initial selected commodity in the given list of commodities. Returns the commodity with the matching
   * commodity id from the given list as the candidate's commodity, or null if not found.
   * @param commodities
   */
  private findInitSelectedCommodity(commodities: Commodity[]) {
    if (!this.candidate.commodity || !commodities) {
      return null;
    }
    for (let index = 0; index < commodities.length; index++) {
      if (this.candidate.commodity.commodityId.toString() === commodities[index].commodityId) {
        return commodities[index];
      }
    }
    return null;
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.candidate.productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.candidate);
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      if (!this.candidate.merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.originalCandidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.candidate.merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });

  }


  /**
   * Retrieve sub commodities from selected commodity
   */
  getSubCommodities(e: Commodity) {
    this.subCommodities = e.subCommodityList;
  }

  /**
   * Sets available selectable cost owners
   * @param brandId the brandId id.
   */
  private setCostOwners(brandId) {
    this.lookupService.findBrandsById(brandId).subscribe(data => {
      for (let index = 0; index < data.length; index++) {
        if (this.candidate.brand.brandId === data[index].brandId) {
          this.candidate.brand.costOwners = data[index].costOwners;
          break;
        }
      }
    });
  }
  /**
   * Sets available selectable cost owners
   * @param vendorId the vendorId id.
   */
  private setVendor(vendorId) {
    this.lookupService.findVendor(vendorId).subscribe(data => {
      if (data) {
        for (let index = 0; index < data.length; index++) {
          if (this.candidate.vendor.apNumber === data[index].apNumber) {
            this.candidate.vendor.lanes = data[index].lanes;
            break;
          }
        }
      }
    });
  }

  onClose() {
    if (JSON.stringify(this.originalCandidate) !== JSON.stringify(this.candidate)) {
      this.candidate.candidateProducts[0] = this.candidateProduct;
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.isViewingPage = false;
        this.supplierProductService.resetService();
        this.router.navigate(['/tasks']).then();
      });
    } else {
      this.isViewingPage = false;
      this.supplierProductService.resetService();
      this.router.navigate(['/tasks']);
    }
  }

  getProductInfoString() {
    let infoString = '';

    if (this.isNonReplenishItem) {
      infoString += this.candidate.retailSize;
    } else {
      infoString += this.candidate.retailSize + ' | ' + 'Inner case cost: ' +
        this.costService.toCurrencyForCost(this.candidate.innerListCost) + ' |  Unit cost: ' +
        this.costService.toCurrencyForCost(this.candidate.unitCost);
    }

    if (this.isSellable) {
      infoString +=
        ' | Suggested Retail: '  +
        this.candidate.suggestedXFor + ' for ' + this.costService.toCurrency(this.candidate.suggestedRetailPrice);
    }

    return infoString;
  }

  getSellableString(): string {
    return this.candidate.productType === 'SELLABLE' ? 'Yes' : 'No';
  }

  get isSellable(): boolean {
    return this.candidate && this.candidate.productType === 'SELLABLE';
  }

  openWarehouseDrawer() {
    this.showWarehousePanel = true;
    this.showHistoryPanel = false;
    this.openCommentDrawer = false;
    this.showAddMoreSuppliers = false;
    this.getWarehouses(this.candidate);
    this.pmReview.openDrawer();
  }

  collapse() {
    this.warehouseData = [];
    this.showWarehousePanel = false;
    this.showHistoryPanel = false;
    this.openCommentDrawer = false;
    this.showAddMoreSuppliers = false;
    this.pmReview.closeDrawer();
  }

  addRemovedWarehouseToDrawer(warehouseId) {
    for (let x = 0; x < this.warehouses.length; x++) {
      if (this.warehouses[x].warehouseId === warehouseId) {
        this.warehouseData.push(this.warehouses[x]);
        this.warehouseData[this.warehouseData.length - 1].orderUnitConfig = this.getOrderUnitConfiguration(warehouseId);
        break;
      }
    }
  }

  /**
   * Saves candidate.
   */
  save() {
    this.candidateProduct.warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
    this.setWarehouseData();
    this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
      this.setOriginalAndCurrentCandidate(savedCandidate);
    });
  }

  /**
   * Saves candidate.
   */
  update() {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    tempCandidate.candidateProducts[0].warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
    this.candidateService.validateCandidate(tempCandidate, [CandidateValidatorType.WAREHOUSE_VALIDATOR,
      CandidateValidatorType.BICEP_VALIDATOR])
      .subscribe(() => {
        this.candidate.candidateProducts[0].warehouses = this.candidateProduct.warehouses.concat(this.getSelectedWarehouses());
        this.candidateService.saveCandidate(this.candidate, true).subscribe(savedCandidate => {
          this.setOriginalAndCurrentCandidate(savedCandidate);
          this.collapse();
        });
      }, (error) => {
        if (error.error.candidateErrors) {
          this.warehouseCandidateError = error.error.candidateErrors;
          this.warehouseCandidateProductError  = this.warehouseCandidateError.candidateProductErrors[this.candidateProduct.id];
        } else {
          this.growlService.addError(error.message);
        }
      });
  }

  getSelectedWarehouses() {
    const tempWarehouseList = [];
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        tempWarehouseList.push(this.warehouseData[x]);
      }
    }
    return tempWarehouseList;
  }

  /**
   * Retrieves all order restrictions.
   */
  private getOrderRestrictions() {
    this.lookupService.findAllOrderRestrictions().subscribe(orderRestrictions => {
      this.orderRestrictions = orderRestrictions;
      this.orderRestrictionConfiguration.collections = this.orderRestrictions;
    });
  }

  /**
   * Retrieves all warehouses by Vendor AP number and the lane ID.
   * @param candidate the candidate.
   */
  private getWarehouses(candidate: Candidate) {
    this.lookupService.findWarehousesByVendorApNumberAndLaneId(candidate.vendor.apNumber, candidate.lane.id).subscribe(warehouses => {
      this.warehouses = warehouses;
      this.setWarehouseData();
    });
  }

  /**
   * Sets the initial warehouse data for the table.
   */
  setWarehouseData() {
    this.warehouseData = [];
    let currentWarehouse: any;
    // if there's a selected product with warehouse info, don't add to warehouse list.
    if (this.candidateProduct.warehouses) {
      let isFound;
      for (let x = 0; x < this.warehouses.length; x++) {
        for (let y = 0; y < this.candidateProduct.warehouses.length; y++) {
          isFound = false;
          if (this.warehouses[x].warehouseId === +this.candidateProduct.warehouses[y].warehouseId) {
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    } else {
      if (this.warehouses) {
        for (let x = 0; x < this.warehouses.length; x++) {
          currentWarehouse = Object.assign({}, this.warehouses[x], JSON.parse(JSON.stringify(this.warehouses[x])));
          currentWarehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
          this.warehouseData.push(Object.assign({}, currentWarehouse));
        }
      }
    }

    this.warehouseData.forEach(x => {
      x.orderUnitConfig = this.getOrderUnitConfiguration(x.warehouseId);
    });
  }

  removeWarehouse(index) {
    const warehouse = this.candidateProduct.warehouses.splice(index, 1).pop();
    if (this.warehouseData && this.warehouses) {
      this.addRemovedWarehouseToDrawer(+warehouse.warehouseId);
    }
  }

  selectedWarehouseChange(event, warehouse) {
    const checked = event.checked;
    if (!checked) {
      warehouse.maxShip = WAREHOUSE_MAXSHIP_MAX;
      warehouse.orderUnitId = undefined;
      warehouse.orderUnit = undefined;
      warehouse.orderRestriction = undefined;
    } else {
      warehouse.orderRestriction = this.getDefaultOrderRestriction();
      warehouse.orderUnitId = this.CASE_ID;
      this.orderUnitChange(this.CASE_ID, warehouse);
    }
  }

  private getDefaultOrderRestriction() {
    for (let x = 0; x < this.orderRestrictions.length; x++) {
      if (this.orderRestrictions[x].id.trim() === 'N') {
        return this.orderRestrictions[x];
      }
    }
  }

  orderUnitChange(event, warehouse) {
    if (event === this.CASE_ID) {
      warehouse.orderUnit = {id: this.CASE_ID, description: 'CASE'};
    } else if (event === this.EACH_ID) {
      warehouse.orderUnit = {id: this.EACH_ID, description: 'EACH'};
    }
  }

  orderRestrictionChange(event, warehouse) {
    warehouse.orderRestriction = event;
  }

  onClickApproveOrActivate() {
    if (this.isMrtInnerNR() || this.candidateUtilService.isScaleProduct(this.candidate) || this.candidate.showCalories) {
      this.onClickApprove();
    } else {
      this.onClickActivate();
    }
  }

  /**
   * Call this method when approving a non replenishable MRT inner on the PIA Review Page
   */
  onClickApprove() {
    // don't allow second click on action buttons
    this.isActivateDisabled = true;
    this.isRejectDisabled = true;

    let validators: CandidateValidatorType[];
    if (this.candidateUtilService.isScaleProduct(this.candidate)) {
      validators = [CandidateValidatorType.PROCUREMENT_SUPPORT_PRODUCT_REVIEW_VALIDATOR, CandidateValidatorType.SCALE_ENGLISH_LABEL_VALIDATOR];
    } else if (this.candidate.showCalories) {
      validators = [CandidateValidatorType.PROCUREMENT_SUPPORT_PRODUCT_REVIEW_VALIDATOR];
    } else {
      validators = [CandidateValidatorType.NON_REPLENISH_APPROVE_VALIDATOR];
    }
    if (this.permissionService.getPermission('ROLE_IMAGE_OMISSION_REASONS')) {
      validators.push(CandidateValidatorType.IMAGE_LINKS_OMISSION_REASON_VALIDATOR);
    }
    this.workflowService.updateApNumber(
      this.candidate.vendor ? this.candidate.vendor.apNumber : null,
      this.task.processInstanceId).subscribe(() => {

      // validate page for non replenish - NON_REPLENISHABLE_VALIDATOR
      this.candidateService.validateCandidate(this.candidate, validators)
        .subscribe((validatedCandidate) => {

          // update candidate status to APPROVED
          this.candidate = validatedCandidate;
          // update candidate status to APPROVED if it's a NR and not a scale (scales always need OB Reg review).
          if (this.isMrtInnerNR() && !this.candidateUtilService.isScaleProduct(this.candidate)) {
            this.candidate.status = Candidate.APPROVED;
          }
          this.candidate.candidateProducts[0] = this.candidateProduct;

          // save candidate
          this.candidateService.saveCandidate(this.candidate, true).subscribe((candidate) => {
            this.candidate = candidate;

            if (!this.candidateUtilService.isScaleProduct(this.candidate) && !this.candidate.showCalories) {
              // completeTask
              this.completeTaskAndRouteToTasksPage(
                WorkflowService.ACTION_COMPLETE,
                TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
                'Successfully Approved Non Replenish Candidate.');
            } else {
              this.completeTaskAndRouteToTasksPage(
                WorkflowService.ACTION_COMPLETE,
                TaskDecision.PIA_SCALE_PLU_APPROVE_YES,
                'Successfully completed task.');
            }
          }, error => {
            this.isActivateDisabled = false;
            this.isRejectDisabled = false;

            this.growlService.addError(error.message);
          });
        }, (error) => {
          this.isActivateDisabled = false;
          this.isRejectDisabled = false;

          if (error.error.candidateErrors) {
            this.candidateError = error.error.candidateErrors;
            this.candidateProductError = this.candidateError.candidateProductErrors[this.candidateProduct.id];
          } else {
            this.growlService.addError(error.message);
          }
        });
    }, error => {
      this.isActivateDisabled = false;
      this.isRejectDisabled = false;

      this.growlService.addError(error.message);
    });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   */
  onClickActivate() {
    this.isActivateDisabled = true;
    this.isRejectDisabled = true;
    this.candidate.candidateProducts[0] = this.candidateProduct;
    const validators: CandidateValidatorType[] = [CandidateValidatorType.PROCUREMENT_SUPPORT_PRODUCT_REVIEW_VALIDATOR];
    if (this.permissionService.getPermission('ROLE_IMAGE_OMISSION_REASONS')) {
      validators.push(CandidateValidatorType.IMAGE_LINKS_OMISSION_REASON_VALIDATOR);
    }
    this.workflowService.updateApNumber(this.candidate.vendor ? this.candidate.vendor.apNumber : null,
      this.task.processInstanceId).subscribe(() => {
      this.candidateService.validateCandidate(this.candidate, validators)
        .subscribe(() => {
          this.workflowService.addProcessVariableViewed(this.task.processInstanceId, false).subscribe(() => {
            this.candidateService.activateCandidate(this.candidate).subscribe(() => {

              if (this.candidate.dsdSwitch && this.candidate.candidateProducts[0].invitedSuppliers &&
                (this.candidate.candidateProducts[0].invitedSuppliers.length > 0)) {
                this.createInvitedSupplierTasksAndCompleteCurrentTask();
              } else {
                this.completeTaskAndRouteToTasksPage(
                  WorkflowService.ACTION_COMPLETE,
                  TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
                  'Successfully activated candidate.'
                );
              }
            }, (error) => {
              this.growlService.addError(error);
              this.isActivateDisabled = false;
              this.isRejectDisabled = false;
            });
          });
        }, (error) => {
          if (error.error.candidateErrors) {
            this.candidateError = error.error.candidateErrors;
            this.candidateProductError = this.candidateError.candidateProductErrors[this.candidateProduct.id];
          } else {
            this.growlService.addError(error.message);
          }
          this.isActivateDisabled = false;
          this.isRejectDisabled = false;
        });
    }, (error) => {
      this.growlService.addError(error);
      this.isActivateDisabled = false;
      this.isRejectDisabled = false;
    });
  }
  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private completeTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.completeTaskWithAction(this.task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then(() => {
          this.isActivateDisabled = false;
          this.isRejectDisabled = false;
        });
      }, (error) => {
        this.growlService.addError(error);
        this.isRejectDisabled = false;
        this.isActivateDisabled = false;
      });
  }

  /**
   * Completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   * @param task the task.
   */
  private completeTaskWithTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string, task: Task) {
    this.workflowService.completeTaskWithAction(task, action, taskDecision)
      .subscribe(() => {
        this.router.navigate(['/tasks'], { queryParams: { growlMessage: growlMessage } }).then();
      }, (error) => {
        this.growlService.addError(error);
      });
  }

  /**
   * Saves the current state of the candidate, completes the given task decision, and then routes user back to task page.
   *
   * @param action Action to take for the current task.
   * @param taskDecision Decision to make for the current task.
   * @param growlMessage Message to display after routing to task page.
   */
  private saveAndCompleteTaskAndRouteToTasksPage(action: string, taskDecision: TaskDecision, growlMessage: string) {
    this.workflowService.updateApNumber(this.candidate.vendor.apNumber, this.task.processInstanceId).subscribe(() => {
      this.candidateService.saveCandidate(this.candidate, true).subscribe(() => {
        this.completeTaskAndRouteToTasksPage(action, taskDecision, growlMessage);
      }, (error) => {
        this.growlService.addError(error);
        this.isRejectDisabled = false;
      });
    }, (error) => {
      this.growlService.addError(error);
      this.isRejectDisabled = false;
    });
  }

  reject(event, panel, target) {
    this.isActivateDisabled = true;
    this.isRejectDisabled = true;
    this.candidate.vendorComment = event;
    this.candidate.status = Candidate.DECLINED;

    // if it's not a part of an MRT then just simply reject.
    if (!this.isPartOfMrt || !this.inProgressMrtParentCandidates?.length) {
      this.saveAndCompleteTaskAndRouteToTasksPage(WorkflowService.ACTION_COMPLETE,
        this.candidateUtilService.isScaleProduct(this.candidate) ? TaskDecision.PIA_SCALE_PLU_APPROVE_NO :
          TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION, 'Successfully rejected candidate.');
      panel.hide();
      return;
    }

    // // new and replenishable inners are effectively treated the same. Reject all MRTs and NR in those MRTs,
    // // unless that NR is in another MRT not affected by this reject.
    for (let x = 0; x < this.inProgressMrtParentCandidates.length; x++) {
      for (let y = 0; y < this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners.length; y++) {
        // if it's non replenish, not the current candidate (save for last) and not a draft, reject.
        // We ignore drafts because if it's replenishable, then we don't reject regardless,
        // if NR, then it belongs to another MRT, and will be rejected when that one is rejected.
        if (!this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].replenishable &&
          !this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].draft &&
          this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].candidateId !== this.candidate.candidateId) {

          // if this NR is in another MRT that isn't being rejected by this flow, don't reject, so that the other
          // MRT remains intact.
          this.isApartOfAnotherMrtNotBeingRejected(this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].candidateId,
            this.inProgressMrtParentCandidates).subscribe((dontReject) => {

            if (!dontReject) {
              this.candidateService.getCandidate(this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners[y].candidateId)
                .subscribe((candidate: Candidate) => {
                  // if the candidate isn't approved, it has a normal process instance. Since it's only a NonReplenish, it can
                  // only be in the buyer/pia phase.
                  if (candidate.status !== Candidate.APPROVED) {
                    this.rejectCandidate(candidate, event);
                  } else {
                    // if the non replenish has been approved already, update the completed historic instance to be rejected state.
                    if (candidate.status !== Candidate.APPROVED) {
                      candidate.status = Candidate.DECLINED;
                      candidate.vendorComment = event;
                      this.candidateService.saveCandidate(candidate).subscribe(() => {
                        this.workflowService.updateNonReplenishableCompletedEndStateToRejected(candidate.candidateId).subscribe();
                      });
                    }
                  }
                });
            }
          });
        }

        // reject parent mrt on last iteration of inners
        if (y === this.inProgressMrtParentCandidates[x].mrtInfo.candidateInners.length - 1) {
          this.rejectCandidate(this.inProgressMrtParentCandidates[x], event);
        }
      }
      // after everything else has been deleted, delete the current candidate.
      if (x === this.inProgressMrtParentCandidates.length - 1) {
        this.saveAndCompleteTaskAndRouteToTasksPage(
          WorkflowService.ACTION_COMPLETE, TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION, 'Successfully rejected candidate.');
        panel.hide();
      }
    }
  }

  /**
   * Rejects a candidate task and updates the candidate.
   * @param candidate
   * @param rejectReason
   */
  rejectCandidate(candidate: Candidate, rejectReason: string) {
    candidate.status = Candidate.DECLINED;
    candidate.vendorComment = rejectReason;
    this.candidateService.saveCandidate(candidate).subscribe(() => {
      this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId)
        .subscribe(task => {
          if (task.name === Task.KEY_BUYER_DATA) {
            this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
              TaskDecision.KEY_BUYER_DATA_REJECT_DECISION).subscribe();
          } else if (task.name === Task.PIA_FINAL_REVIEW || task.name === Task.PIA_NEW_PRODUCT_FLOW) {
            this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
              TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe();
          } else if (task.name === Task.ASSIGN_WAREHOUSE) {
            // if it's an sca task, move to next, then reject from PIA.
            this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE)
              .subscribe(() => {
                this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidate.candidateId)
                  .subscribe(piaTask => {
                    this.workflowService.completeTaskWithAction(piaTask, WorkflowService.ACTION_COMPLETE,
                      TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe();
                  });
              });
          } else if (task.name === Task.KEY_VENDOR_DATA) {
            this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
              TaskDecision.KEY_VENDOR_DATA_DECISION_YES).subscribe();
          }
        });
    });
  }

  /**
   * Finds all MRTs the NR candidate is a part of. If it's not a part of the MRTs being rejected, then don't reject, as to not
   * create a chain reaction of deleted MRTs.
   * @param candidateId
   * @param mrtsRejecting
   */
  isApartOfAnotherMrtNotBeingRejected(candidateId, mrtsRejecting: Candidate[]): Observable<boolean> {
    return this.candidateService.findParentMrtCandidatesForCandidateId(candidateId, [Candidate.IN_PROGRESS]).pipe(map(mrtsIncludedIn => {

      if (mrtsIncludedIn === undefined || mrtsIncludedIn === null || mrtsIncludedIn.length === 1) {
        return false;
      } else {
        for (let x = 0; x < mrtsIncludedIn.length; x++) {
          let isFound = false;
          for (let y = 0; y < mrtsRejecting.length; y++) {
            if (mrtsIncludedIn[x].candidateId === mrtsRejecting[y].candidateId) {
              isFound = true;
            }
          }
          if (!isFound) {
            return true;
          }
        }
        return false;
      }
    }));
  }

  isSelectedWarehouse() {
    for (let x = 0; x < this.warehouseData.length; x++) {
      if (this.warehouseData[x].checked) {
        return true;
      }
    }
    return false;
  }
  editMerchandiseType() {
    this.showEditCandidateModal(AttributeTypes.MerchandiseType, {collections: this.merchandiseTypes});
  }
  editQualifyingConditions() {
    this.showEditCandidateProductModal(AttributeTypes.QualifyingCondition, {collections: this.qualifyingConditions});
  }
  editWarehouseSupplier() {
    if (this.candidate.vendor && !this.candidate.vendor.lanes && this.candidate.vendor.apNumber) {
      this.candidateUtilService.setWarehouseSupplier(this.candidate).subscribe(() => {
        this.showEditCandidateModal(AttributeTypes.Warehouse, {collections: this.candidate.vendor.lanes});
      });
    } else {
      this.showEditCandidateModal(AttributeTypes.Warehouse,
        {collections: this.candidate.vendor ? this.candidate.vendor.lanes : []});
    }
  }

  editSubCommodity() {
    this.editCandidateModalService.openModal(AttributeTypes.SubCommodity, this.candidate,
      {collections: this.subCommodities}).subscribe(response => {
      if ( response ) {

        let updateTaxCategory = false;
        if (response.subCommodity !== this.candidate.subCommodity) {
          updateTaxCategory = true;
        }
        // Dispatch Update
        this.candidate = response;
        if (updateTaxCategory) {
          this.setVertexTaxCategoryModel();
        }
        this.updateRegulatoryFields();
      }
    });
  }

  editPricingType() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.RetailType},
        {type: AttributeTypes.RetailLink, overrides: {validationService: this.candidateService}},
        {type: AttributeTypes.RetailPricing}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  editCommodity() {
    this.editCandidateModalService.openModal(AttributeTypes.Commodity,
      this.candidate, {collections: this.commodities} ).subscribe(response => {
      if ( response ) {
        let updateTaxCategory = false;
        if (response.commodity !== this.candidate.commodity) {
          updateTaxCategory = true;
          this.candidate.candidateProducts[0].locationGroupStores = [];
        }
        this.candidate = response;
        this.subCommodities = this.candidate.commodity.subCommodityList;
        if (updateTaxCategory) {
          this.setVertexTaxCategoryModel();
        }
        this.getPssDepartments(this.candidate);
        if (this.candidate.commodity) {
          this.candidate.pssDepartment = this.candidate.commodity.pssDepartment;
        }
      }
    });
  }

  editPackageType() {
    this.showEditCandidateModal(AttributeTypes.PackageTypes, {collections: this.packageTypes});
  }

  editUnitOfMeasure() {
    this.showEditCandidateModal(AttributeTypes.UnitOfMeasure, {collections: this.unitsOfMeasures});
  }

  editCandidateProduct(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  editCandidate(attributeType: AttributeTypes) {
    this.showEditCandidateModal(attributeType);
  }

  editCostOwner() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.CostOwner, overrides: { collections : this.candidate.brand.costOwners}},
        {type: AttributeTypes.TopToTop, overrides: { collections : this.candidate.brand.costOwners}}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }
  editContact() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.ContactName},
        {type: AttributeTypes.ContactEmail}
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }
  editSeasonYear() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.Season},
        {type: AttributeTypes.SeasonYear},
      ], this.candidate).subscribe( response => {

      if (response) {
        this.candidate = response;
      }
    });
  }

  editProductUPC() {
    this.showEditUnitUpcModal(AttributeTypes.ProductUPC, {
      validationService: this.candidateService
    });
  }

  editCaseUPC() {
    this.showEditCandidateModal(AttributeTypes.CaseUPC, {
      validationService: this.candidateService
    });
  }

  editCaseDescription(attributeType: AttributeTypes) {
    this.showEditCandidateProductModal(attributeType);
  }

  editCostLink() {
    this.editCandidateModalService
      .openModal(AttributeTypes.CostLinkedItem, this.candidate, {
        validationService: this.candidateService
      })
      .subscribe(response => {
        if (response) {
          this.candidate = response;
          this.validateCostLink();
        }
      });
  }

  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.candidate.costLinkFromServer = undefined;
    this.candidateError.costLink = undefined;
    this.candidateError.hasErrors = null;
    this.candidateService.validateCandidate(this.candidate,
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((candidate) => {
      this.costLinkState = InputState.valid;
      this.candidate.costLinkFromServer = candidate.costLinkFromServer;
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.candidateError.costLink = error.error.candidateErrors.costLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  get attributeType() {
    return AttributeTypes;
  }
  editOrderRestrictions(warehouse) {
    this.editWarehouse(warehouse, AttributeTypes.WarehouseOrderRestriction, { collections : this.orderRestrictions});
  }

  editWarehouse(warehouse, type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, warehouse, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        const warehouseIndex = this.candidateProduct.warehouses.findIndex(x => x.warehouseId === warehouse.warehouseId);
        this.candidateProduct.warehouses[warehouseIndex] = response;
      }
    });
  }


  showEditCandidateProductModal(type: AttributeTypes,  overrides?: any) {
    this.editCandidateModalService.openModal(type, this.candidateProduct, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
        this.candidate.candidateProducts[0] = this.candidateProduct;
      }
    });
  }

  editImages() {
    const candidateProduct = JSON.parse(JSON.stringify(this.candidateProduct));
    this.editCandidateModalService.openModal(AttributeTypes.ProductImages, candidateProduct, {},
      {contentStyle: { 'width': '750px', overflow: 'auto' }}).subscribe(response => {
      if ( response ) {
        this.candidateProduct = response;
        this.candidate.candidateProducts[0] = this.candidateProduct;
        this.productImages = this.candidateUtilService.getImages(this.candidate.candidateProducts[0].imageLinks);
      }
    });
  }

  showEditCandidateProductUpcModal(type: AttributeTypes,  overrides?: any) {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    this.editCandidateModalService.openModal(type, tempCandidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidateProduct = this.candidate.candidateProducts[0];
      }
    });
  }

  showEditUnitUpcModal(type: AttributeTypes,  overrides?: any) {
    const tempCandidate = JSON.parse(JSON.stringify(this.candidate));
    this.editCandidateModalService.openModal(type, tempCandidate, overrides ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidateProduct = this.candidate.candidateProducts[0];
        if (this.supplierProductService.isDsdOnlyParms(this.candidate)) {
          this.candidate.candidateProducts[0].caseUpc = this.candidate.candidateProducts[0].upc;
          this.candidate.candidateProducts[0].caseUpcCheckDigit = this.candidate.candidateProducts[0].upcCheckDigit;
        }
      }
    });
  }

  showEditCandidateModal(type: AttributeTypes, overrides?: any) {
    this.editCandidateModalService.openModal(type, JSON.parse(JSON.stringify(this.candidate)), overrides ).subscribe(response => {
      if ( response ) {
        let updateTaxCategory = false;
        if (response.taxable !== this.candidate.taxable) {
          updateTaxCategory = true;
        }
        // Dispatch Update
        this.candidate = response;

        if (updateTaxCategory) {
          this.setVertexTaxCategoryModel();
        }
        if (this.candidate.retailSize !== undefined && this.candidate.retailSize !== null && this.candidate.retailSize.length === 0) {
          this.candidate.retailSize = null;
        }
        if (type === AttributeTypes.Vendor) {
          this.candidate.candidateProducts[0].locationGroupStores = [];
        } else if (type === AttributeTypes.Channel) {
          this.findMerchandiseTypesAndSetDefault();
          this.setupMatAttributes();
          this.candidateUtilService.handleChannelChange(this.candidate);
          this.candidate.missingWarehouses = null;
          this.isWarehouseMissingResolved = false;
        } else if (type === AttributeTypes.Sellable) {
          this.findMerchandiseTypesAndSetDefault();
        } else if (type === AttributeTypes.CaseUPC) {
          const currentCandidateProduct = CandidateUtilService.getCurrentCandidateProduct(response);
          this.candidateProduct.caseUpc = currentCandidateProduct.caseUpc;
          this.candidateProduct.caseUpcCheckDigit = currentCandidateProduct.caseUpcCheckDigit;
        }
      }
    });
  }

  editBuyer() {
    this.editCandidateModalService.openModal(AttributeTypes.Buyer, this.candidate).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidate.commodity = null;
        this.candidate.subCommodity = null;
        this.candidate.candidateProducts[0].locationGroupStores = [];
        if (response.buyer && response.buyer.buyerId) {
          this.setCommoditiesAndSubCommodities(response.buyer.buyerId);
        } else {
          this.commodities = [];
          this.subCommodities = [];
        }
      }
    });
  }

  /**
   * Returns whether a buyer is tied to departments that are variable or catch weight departments (2, 6, 9).
   */
  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.candidate.commodity && this.candidate.commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.candidate.commodity.departmentId);
    } else {
      return false;
    }
  }

  hasReviewerComment() {
    // if else boolean in typescript
    return !!this.candidate.piaComment;
  }

  onEditComment() {
    this.openCommentDrawer = true;
    this.showHistoryPanel = false;
    this.showWarehousePanel = false;
    this.showAddMoreSuppliers = false;
    this.pmReview.openDrawer();
  }

  collapseCommentDrawer() {
    this.openCommentDrawer = false;
    this.showHistoryPanel = false;
    this.showWarehousePanel = false;
    this.showAddMoreSuppliers = false;
    this.pmReview.closeDrawer();
  }

  saveComment() {
    this.candidate.piaComment = this.tempCommentHolder;
    this.candidate.piaCommentUser = this.authService.getUser();
    this.openCommentDrawer = false;
    this.showHistoryPanel = false;
    this.showWarehousePanel = false;
    this.showAddMoreSuppliers = false;
    this.pmReview.closeDrawer();
  }

  getTaxCategory() {
    if (this.candidate.subCommodity) {
      if (this.candidate.taxable) {
        return this.candidate.subCommodity.taxableVertexTaxCategory;
      } else {
        return this.candidate.subCommodity.nonTaxableVertexTaxCategory;
      }
    }
  }

  getPageTitle() {
    if (this.isSellable) {
      return 'Review new product';
    } else if (this.candidate && !this.isSellable) {
      return 'Review non-sellable product';
    }
  }


  editProductDescription() {
    this.editCandidateModalService.openMultiEditModal(
      [
        {type: AttributeTypes.ProductDescription},
        {type: AttributeTypes.Receipt}
      ], this.candidateProduct).subscribe( response => {

      if (response) {
        this.candidateProduct = response;
      }
    });
  }

  isActivateButtonDisabled() {
    if (this.isActivateDisabled) {
      return true;
    } else if (!this.candidate) {
      return true;
      // TODO DSD Activate implementation
    } else {
      return this.candidate.warehouseSwitch && this.candidate.missingWarehouses && !this.isWarehouseMissingResolved;
    }
  }

  isMrtInnerNR() {
    return this.isMrtInner() && this.isNonReplenishItem;
  }

  getActivateButtonTitle() {
    if (!this.candidate) {
      return 'Approve';
    }

    let buttonNotClicked = 'Activate';
    let activatingButton = 'Activating';
    if (this.isMrtInnerNR() || this.candidateUtilService.isScaleProduct(this.candidate) || this.candidate.showCalories) {
      buttonNotClicked = 'Approve';
      activatingButton = 'Approving';
    }
    if (this.isActivateDisabled) {
      return activatingButton;
    } else {
      return buttonNotClicked;
    }
  }

  editBrandModal() {
    this.editCandidateModalService.openModal(AttributeTypes.Brand, this.candidate).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidateProduct = this.candidate.candidateProducts[0];
      }
    });
  }

  /**
   * Code date is editable by the pia if dsdSwitch set to false ==> warehouse
   */
  codeDateEditable() {
    return !this.candidate.dsdSwitch;
  }

  /**
   * Pss Department is editable by the pia if dsdSwitch set to true ==> DSD
   */
  pssDepartmentEditable() {
    return this.candidate.dsdSwitch === true;
  }

  editPssDepartment() {
    this.editCandidateModalService.openModal(
      AttributeTypes.PssDepartment, this.candidate, {collections: this.pssDepartments}).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
        this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
          this.supplierProductService.setCandidate(savedCandidate);
        });
      }
    });
  }

  getPssDepartments(candidate: Candidate) {
    if (!candidate.overrideDepartment) {
      if (candidate.commodity && candidate.commodity.subDepartment == null) {
        // Make call to get sub department with pss departments when commodity does not have department info.
        this.lookupService.findSubDepartment(candidate.commodity.departmentId + candidate.commodity.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments = subDepartment[0].pssDepartments;
        });
      } else {
        this.pssDepartments = candidate?.commodity?.subDepartment?.pssDepartments;
      }
    } else {
      if (candidate.overrideSubDepartment?.pssDepartments?.length > 0) {
        this.pssDepartments = candidate.overrideSubDepartment?.pssDepartments;
      } else {
        this.lookupService.findSubDepartment(candidate.overrideSubDepartment.departmentId + candidate.overrideSubDepartment.subDepartmentId).subscribe((subDepartment) => {
          this.pssDepartments = subDepartment[0].pssDepartments;
        });
      }
    }
  }

  isMrtInner() {
    return this.candidate && this.candidate.candidateType === Candidate.MRT_INNER;
  }

  setMrtCaseInfo() {
    this.candidateService.findParentMrtCandidatesForCandidateId(this.candidate.candidateId,
      [Candidate.IN_PROGRESS, Candidate.COMPLETED, Candidate.ACTIVATED, Candidate.DECLINED, Candidate.DELETED]).subscribe((mrtCandidates) => {
      if (!mrtCandidates?.length) {
        this.isPartOfMrt = false;
        return;
      }
      this.isPartOfMrt = true;
      if (!this.findMrtParentAndSetMrtCaseInfo(mrtCandidates)) {
        this.setMrtCaseInfoFromWorkflow(mrtCandidates);
      }
    });
  }

  private findMrtParentAndSetMrtCaseInfo(mrtCandidates: Candidate[]): boolean {
    const mrtParentCandidate = mrtCandidates.filter(mrt => {
      return !!mrt.mrtInfo.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId && !inner.draft);
    })[0];

    if (!mrtParentCandidate) {
      return false;
    }
    this.inProgressMrtParentCandidates = mrtCandidates.filter(mrt => mrt.status === Candidate.IN_PROGRESS);
    const candidateInner = mrtParentCandidate.mrtInfo?.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId);
    if (!candidateInner.replenishable) {
      this.isNonReplenishItem = true;
      this.mrtParentDescription = mrtParentCandidate.description;
      this.candidateUtilService.setMrtInnerCandidateInfoFromMrtCandidate(this.candidate, this.candidateProduct, mrtParentCandidate);
    }
    return true;
  }

  private setMrtCaseInfoFromWorkflow(mrtCandidates: Candidate[]) {
    this.workflowService.getIsNonReplenishableMrtInnerCandidateId(this.candidate.candidateId).subscribe(
      (isNonReplenishable) => {
        if (!isNonReplenishable) {
          return;
        }
        this.isNonReplenishItem = true;
        this.inProgressMrtParentCandidates = mrtCandidates.filter(mrt => mrt.status === Candidate.IN_PROGRESS);
        let mrtParent: Candidate = mrtCandidates.filter(mrt => {
          return !!mrt.mrtInfo.candidateInners.find(inner => inner.candidateId === this.candidate.candidateId && !inner.draft);
        })[0];
        // if the MRT parent isn't in a valid state (missing parent), find a valid one to set the data. Note: since this is an IN PROGRESS NR,
        // there should always be an IN PROGRESS MRT.
        if (!mrtParent) {
          mrtParent = mrtCandidates.filter(mrt => [Candidate.IN_PROGRESS, Candidate.COMPLETED, Candidate.ACTIVATED].includes(mrt.status))[0];
        }

        this.mrtParentDescription = mrtParent.description;
        this.candidateUtilService.setMrtInnerCandidateInfoFromMrtCandidate(this.candidate, this.candidateProduct, mrtParent);
      }
    );
  }

  showWarehouseCasePackInfo() {
    if (this.isMrtInner()) {
      return !this.isNonReplenishItem;
    } else {
      return this.candidate.warehouseSwitch;
    }
  }

  getPennyProfit() {
    return (this.candidate.suggestedRetailPrice * this.candidate.retailSize) - this.candidate.masterListCost;
  }

  getPennyProfitDisplay() {
    return this.costService.toCurrency(this.getPennyProfit());
  }

  getMarginPercent() {
    return 100 * (this.getPennyProfit() / this.candidate.masterListCost);
  }

  getMrtItemHebPennyProfit() {
    return (this.candidate.retailPrice * this.candidate.retailXFor) - this.candidate.masterListCost;
  }

  editVertexTaxCategory() {
    this.editCandidateModalService.openModal(AttributeTypes.VertexTaxCategory, this.candidate,
      {collections: this.defaultTaxCategories},
      {contentStyle: { 'max-height': '550px', overflow: 'none' }}
    ).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidate = response;
      }
    });
  }

  editDepartment() {
    this.editCandidateModalService.openModal(AttributeTypes.SubDepartment, this.candidate, {}).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        if (this.candidate.overrideDepartment !== response.overrideDepartment ||
          this.candidate.commodity.departmentId !== response.commodity.departmentId ||
          this.candidate.overrideSubDepartment?.departmentId !== response.overrideSubDepartment?.departmentId) {
          this.candidate = response;
          this.candidate.pssDepartment = null;
          this.getPssDepartments(this.candidate);
          this.candidate.candidateProducts[0].locationGroupStores = [];
          this.supplierProductService.setAuthGroups(null);
          this.supplierProductService.setSelectedAuthGroups(null);
          this.supplierProductService.setNotFoundStores(null);
          this.candidateService.saveCandidate(this.candidate).subscribe(savedCandidate => {
            this.supplierProductService.setCandidate(savedCandidate);
          });
        }
      }
    });
  }


  showPanel(event, panel, target) {
    event.stopPropagation();
    panel.show(event, target);
  }

  showStoreSearchPanel(event, panel, target) {
    event.stopPropagation();
    this.supplierProductService.setCandidate(JSON.parse(JSON.stringify(this.candidate)));
    this.supplierProductService.setAuthGroups(null);
    this.supplierProductService.setSelectedAuthGroups(null);
    panel.show(event, target);
    this.showStoreOverlay = true;
  }

  isStoreSearchDisabled() {
    return !this.candidate?.commodity?.commodityId;
  }

  hideStoreSearchPanel(target) {
    this.supplierOverlayCandidateError = new CandidateError();
    this.supplierOverlayCandidateProductError = new CandidateProductError();
    this.showStoreOverlay = false;
    target.hide();
  }

  toggleResolved() {
    this.isWarehouseMissingResolved = !this.isWarehouseMissingResolved;

    if (this.isWarehouseMissingResolved) {
      this.candidate.missingWarehousesResolveUser = this.authService.getUser();
    } else {
      this.candidate.missingWarehousesResolveUser = null;
    }
  }

  toggleExpandCollapseMissingWarehouse() {
    if (this.expandCollapseMissingWarehouseButtonText === 'Collapse') {
      this.expandCollapseMissingWarehouseButtonText = 'Expand';
      this.isExpandedWarehouse = false;
    } else {
      this.expandCollapseMissingWarehouseButtonText = 'Collapse';
      this.isExpandedWarehouse = true;
    }
  }

  historyPanelOpen() {
    this.candidateHistoryService.historyPanelOpen();
    this.showHistoryPanel = true;
    this.pmReview.openDrawer();
  }

  historyPanelClose() {
    this.candidateHistoryService.historyPanelClose();
    this.showHistoryPanel = false;
    this.openCommentDrawer = false;
    this.showWarehousePanel = false;
    this.showAddMoreSuppliers = false;
    this.pmReview.closeDrawer();
  }

  setAuthGroups() {
    if (!this.supplierProductService.getAuthGroups()) {
      this.supplierProductService.findAllAuthGroupsByParms(this.candidate.vendor.apNumber, this.candidate.commodity.departmentId,
        this.candidate.commodity.subDepartmentId).subscribe((authGroups) => {
        this.supplierProductService.setAuthGroups(authGroups);
        this.loadVendors();
        for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
          this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
        }
      });
    } else {
      this.loadVendors();
      for (let x = 0; x < this.supplierProductService.getAuthGroups().length; x++) {
        this.supplierProductService.getAuthGroups()[x].configuration = this.getAuthGroupConfiguration(x);
      }
    }
  }

  getAuthGroupConfiguration(index): AttributeConfig  {
    return {
      label: 'Group ' + this.supplierProductService.getAuthGroups()[index].splrLocationGroupId,
      description: this.getAuthGroupLabel(this.supplierProductService.getAuthGroups()[index]),
      isRequired: true,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: `authGroup_${index}`,
      defaultValue: false
    };
  }

  getAuthGroupLabel(authGroup: LocationGroupStores): string {
    if (authGroup && authGroup.stores) {
      if (authGroup.stores.length < 2) {
        return authGroup.stores.length + ' store';
      } else {
        return authGroup.stores.length + ' stores';
      }
    }
  }

  loadVendors() {
    for (let x = 0; x < this.candidate.candidateProducts[0].locationGroupStores.length; x++) {
      this.lookupService.findDsdVendor(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber).subscribe(vendor => {
        this.suppliers.set(this.candidate.candidateProducts[0].locationGroupStores[x].splrLocationNumber, vendor[0]);
      });
    }
  }

  getVendor(vendorId: number) {
    return this.suppliers.get(vendorId);
  }

  getNumberOfStoresForGroup(groupId) {
    if (this.supplierProductService.authGroups) {
      for (let x = 0; x < this.supplierProductService.authGroups.length; x++) {
        if (this.supplierProductService.authGroups[x].splrLocationGroupId === groupId) {
          return this.supplierProductService.authGroups[x].stores.length;
        }
      }
    }
  }

  saveClicked(candidate: Candidate, panel) {
    this.canClickSave = false;
    this.candidateService.validateCandidate(candidate,
      [CandidateValidatorType.LOCATION_GROUP_STORES_VALIDATOR]).toPromise().then(() => {
      // Re-apply CP to base candidate;
      this.candidate.candidateProducts = [candidate.candidateProducts[0]];
      this.candidate = candidate;
      this.supplierOverlayCandidateError = new CandidateError();
      this.supplierOverlayCandidateProductError = new CandidateProductError();
      this.candidateService.saveCandidate(candidate).subscribe(savedCandidate => {
        this.setOriginalAndCurrentCandidate(savedCandidate);
        this.showStoreOverlay = false;
        panel.hide();
        this.canClickSave = true;
      });
    }, (error) => {
      this.canClickSave = true;
      this.supplierProductService.scrollToTop();
      if (error.error.candidateErrors.hasErrors) {
        this.supplierProductService.updatePageErrors(error.error.candidateErrors);
        this.supplierOverlayCandidateError = this.supplierProductService.getStoreAuthError();
        this.supplierOverlayCandidateProductError =
          this.supplierProductService.getCurrentCandidateProductError(this.supplierOverlayCandidateError);
      }
    });
  }


  onClickAddDistributors() {
    this.setInitialSupplierConfiguration();
    this.pmReview.openDrawer();
    this.showAddMoreSuppliers = true;
    this.openCommentDrawer = false;
    this.showHistoryPanel = false;
    this.showWarehousePanel = false;
  }

  closeInviteSuppliersPanel() {
    this.vendorsToInvite = [];
    this.isViewingInvitedVendors = false;
    this.collapse();
  }

  inviteSuppliers() {
    if (!this.hasVendorsToInvite()) {
      this.closeInviteSuppliersPanel();
      return;
    }

    // if there's no invited suppliers, add all to the list.
    if (!this.candidate.candidateProducts[0].invitedSuppliers || this.candidate.candidateProducts[0].invitedSuppliers.length === 0) {
      this.candidate.candidateProducts[0].invitedSuppliers = [];
      for (let x = 0; x < this.vendorsToInvite.length; x++) {
        this.candidate.candidateProducts[0].invitedSuppliers.push({vendor: this.vendorsToInvite[x]});
      }
      this.save();
      // if there's invited suppliers, find all the vendors to invite that aren't already invited and add them to the invitedSuppliers list.
    } else {
      const notFoundVendors = [];
      let isFound;
      for (let x = 0; x < this.vendorsToInvite.length; x++) {
        isFound = false;
        for (let y = 0; y < this.candidate.candidateProducts[0].invitedSuppliers.length; y++) {
          if (this.vendorsToInvite[x].apNumber === this.candidate.candidateProducts[0].invitedSuppliers[y].vendor.apNumber) {
            isFound = true;
          }
        }
        if (!isFound) {
          notFoundVendors.push({vendor: this.vendorsToInvite[x]});
        }
      }
      if (notFoundVendors.length === 0) {
        this.closeInviteSuppliersPanel();
        return;
      }

      this.candidate.candidateProducts[0].invitedSuppliers =
        this.candidate.candidateProducts[0].invitedSuppliers.concat(notFoundVendors);
      this.save();
    }
    this.closeInviteSuppliersPanel();
  }

  hasVendorsToInvite() {
    return this.vendorsToInvite && this.vendorsToInvite.length > 0;
  }

  onInvitedVendorSelection(event) {
    this.vendorsToInvite = event;
    if (event && event.length > 0) {
      const selectedApString = this.getSelectedApNumberString(event);

      let url = '/lookup/vendordsd/exclude';
      if (this.invitedSuppliersString) {
        url +=  this.invitedSuppliersString + ',' + selectedApString;
      } else {
        url += '/' + selectedApString;
      }

      this.supplierConfiguration = {
        label: 'Suppliers to invite',
        isRequired: false,
        isHidden: () => false,
        isDisabled: () => false,
        isReadOnly: () => false,
        name: 'vendorTypeAhead',
        displaySubRef: '',
        displayRef: 'displayName',
        placeholderText: 'Select or search for a supplier...',
        searchUrl: url,
        idRef: 'apNumber',
        showAddlInfo: true,
        allowMultiple: true
      };
    }
  }

  getSelectedApNumberString(vendors: Vendor[]): string {
    if (vendors && vendors.length > 0) {
      let apNumberString = '';
      for (let x = 0; x < vendors.length; x++) {
        apNumberString += '' + vendors[x].apNumber;
        if (x !== vendors.length - 1) {
          apNumberString += ',';
        }
      }
      return apNumberString;
    }
    return '';
  }

  getSupplierHeaderText() {
    if (this.candidate && this.candidate.candidateProducts[0].invitedSuppliers &&
      this.candidate.candidateProducts[0].invitedSuppliers.length > 0) {
      if (this.candidate.candidateProducts[0].invitedSuppliers.length === 1) {
        return '1 Supplier';
      } else {
        return '' + this.candidate.candidateProducts[0].invitedSuppliers.length + ' Suppliers';
      }
    }
    return '';
  }

  removeInvitedSupplier(apNumber) {
    for (let x = 0; x < this.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
      if (apNumber === this.candidate.candidateProducts[0].invitedSuppliers[x].vendor.apNumber) {
        this.candidate.candidateProducts[0].invitedSuppliers.splice(x, 1);
        this.save();
        this.setInitialSupplierConfiguration();
        this.onInvitedVendorSelection(this.vendorsToInvite);
        return;
      }
    }
  }

  setInitialSupplierConfiguration() {
    let url = '/lookup/vendordsd/exclude';
    this.invitedSuppliersString = '';
    if (this.candidate.candidateProducts[0].invitedSuppliers && this.candidate.candidateProducts[0].invitedSuppliers.length > 0) {
      this.invitedSuppliersString += '/';
      for (let x = 0; x < this.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
        this.invitedSuppliersString += '' + this.candidate.candidateProducts[0].invitedSuppliers[x].vendor.apNumber;
        if (x !== this.candidate.candidateProducts[0].invitedSuppliers.length - 1) {
          this.invitedSuppliersString += ',';
        }
      }
      url += this.invitedSuppliersString;
    }
    this.supplierConfiguration = {
      label: 'Suppliers to invite',
      isRequired: false,
      isHidden: () => false,
      isDisabled: () => false,
      isReadOnly: () => false,
      name: 'vendorTypeAhead',
      displaySubRef: '',
      displayRef: 'displayName',
      placeholderText: 'Select or search for a supplier...',
      searchUrl: url,
      idRef: 'apNumber',
      showAddlInfo: true,
      allowMultiple: true
    };
  }

  addAttachments(event) {
    this.candidate.attachments = event;
    this.save();
  }

  /**
   * Creates the invited supplier candidates and tasks, applies the candidate ids on the parents invited suppliers,
   * and completes current tasks
   */
  async createInvitedSupplierTasksAndCompleteCurrentTask() {

    // set updated candidate w/ activated statis
    this.candidateService.getCandidate(this.candidate.candidateId).subscribe(async (candidate) => {
      this.setOriginalAndCurrentCandidate(candidate);

      let inviteCandidate: Candidate;
      const description = 'Request stores – UPC: ' + this.candidate.candidateProducts[0].upc;

      // Create a candidate and task for each invited supplier, and set the candidate ids on the invited suppliers.
      // On the last iteration, save the parent candidate with new Ids, and complete the task and route.
      for (let x = 0; x < this.candidate.candidateProducts[0].invitedSuppliers.length; x++) {
        inviteCandidate = new Candidate();
        inviteCandidate.description = description;
        inviteCandidate.candidateType = Candidate.ADDITIONAL_DISTRIBUTOR;
        inviteCandidate.vendor = this.candidate.candidateProducts[0].invitedSuppliers[x].vendor;
        inviteCandidate.buyer = this.candidate.buyer;
        inviteCandidate.brand = this.candidate.brand;
        inviteCandidate.commodity = this.candidate.commodity;
        inviteCandidate.dsdSwitch = true;
        inviteCandidate.candidateProducts = [];
        inviteCandidate.candidateProducts.push({id: UUID.UUID(), candidateProductType: CandidateProduct.ADDITIONAL_DISTRIBUTOR});
        inviteCandidate.candidateProducts[0].upc = this.candidate.candidateProducts[0].upc;

        await this.createInvitedSupplierTasks(inviteCandidate, x);
        this.emailService.sendInvitedSupplierEmail(inviteCandidate.vendor.apNumber, this.candidate.candidateProducts[0].description,
          this.candidate.candidateProducts[0].upc);

        if (x === this.candidate.candidateProducts[0].invitedSuppliers.length - 1) {
          this.save();
          this.completeTaskAndRouteToTasksPage(
            WorkflowService.ACTION_COMPLETE,
            TaskDecision.PIA_FINAL_REVIEW_APPROVE_DECISION,
            'Successfully activated candidate.'
          );
        }
      }
    });
  }

  async createInvitedSupplierTasks(inviteCandidate: Candidate, index) {
    const candidate = await this.candidateService.createNewCandidate(inviteCandidate).toPromise();
    this.candidate.candidateProducts[0].invitedSuppliers[index].candidateId = candidate.candidateId;

    this.workflowService.createProcessInstanceWithCandidateId(candidate.candidateId, this.DSD_INVITED_SUPPLIER_WORKFLOW)
      .subscribe(taskId => {
        this.workflowService.getTaskByIdWithVariables(taskId).subscribe((task) => {
          this.workflowService.updateApNumber(candidate.vendor.apNumber, task.processInstanceId).subscribe();
        });
      });
  }

  setVertexTaxCategoryModel() {
    this.candidateUtilService.setVertexTaxCategoryModel(this.candidate).subscribe();
  }

  getSubDepartment() {
    if (this.candidate.overrideDepartment && this.candidate.overrideSubDepartment) {
      return this.candidate.overrideSubDepartment.subDepartmentDescription;
    } else if (this.candidate.commodity) {
      return this.candidate.commodity.subDepartmentDescription;
    } else {
      return '';
    }
  }

  editDepositUPC() {
    this.showEditCandidateProductModal(AttributeTypes.DepositUpc, {
      validationService: this.candidateService
    });
  }

  hasStores() {
    return this.candidate.candidateProducts[0].locationGroupStores &&
      (this.candidate.candidateProducts[0].locationGroupStores.length > 0);
  }

  editScaleAttributeModal(attributeType) {
    this.editCandidateModalService.openModal(attributeType, this.candidateProduct.scaleInformation).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct.scaleInformation = response;
        this.candidate.candidateProducts[0] = this.candidateProduct;
      }
    });
  }


  editPrePackTareModal() {
    if (!this.candidateProduct.scaleInformation.forceTare) {
      this.editCandidateModalService.openModal(AttributeTypes.PrePackTare, this.candidateProduct.scaleInformation).subscribe(response => {
        if ( response ) {
          // Dispatch Update
          this.candidateProduct.scaleInformation = response;
          this.candidate.candidateProducts[0] = this.candidateProduct;
        }
      });
    }
  }

  editForceTareModal() {
    this.editCandidateModalService.openModal(AttributeTypes.ForceTare, this.candidateProduct.scaleInformation).subscribe(response => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct.scaleInformation.forceTare = response.forceTare;
        if (this.candidateProduct.scaleInformation.forceTare) {
          this.candidateProduct.scaleInformation.prePackTare = 0.00;
        }
        this.candidate.candidateProducts[0] = this.candidateProduct;
      }
    });
  }


  editPlu() {
    this.editCandidateModalService.openModal(AttributeTypes.Plu, JSON.parse(JSON.stringify(this.candidateProduct)), {saveDisabled: false}).subscribe(response => {
      if (!response) {
        return;
      }
      const wasScale = this.candidateUtilService.isScaleProduct(this.candidate);
      const previousPlu = this.candidateProduct.upc;
      const previousPluType = this.candidateProduct.pluType;

      this.candidate.candidateProducts[0] = response;
      const scaleChange = wasScale !== this.candidateUtilService.isScaleProduct(this.candidate);

      if (scaleChange) {
        this.candidate.candidateProducts[0].scaleInformation = { id: UUID.UUID() };
      }
      this.candidateProduct = this.candidate.candidateProducts[0];

      if (!response.keepPlu) {
        this.updatePluAndSave(previousPlu, previousPluType);
      }
      if (scaleChange) {
        this.updateProcessInstanceForPluTypeChange(response.keepPlu);
      }
    });
  }

  private updatePluAndSave(previousPlu, previousPluType) {
    const upcRequest = new NewUpcRequest();
    upcRequest.requestType = this.candidateProduct.pluType;
    upcRequest.candidate = this.candidate;
    upcRequest.rangeId = this.candidateProduct.pluRange.pluRangeId;
    this.ownBrandService.releaseAndFetchNewPlu(previousPlu, previousPluType, upcRequest).subscribe((plu) => {
      this.candidateProduct.upc = plu;
      this.candidate.candidateProducts[0].upc = plu;
      this.save();
    });
  }


  updateProcessInstanceForPluTypeChange(saveCandidate: boolean) {
    let processDefinition;

    if (this.candidateUtilService.isScaleProduct(this.candidate)) {
      processDefinition = this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_SCALE_WORKFLOW :
        ProcessInstanceConstants.NEW_PRODUCT_DSD_SCALE_WORKFLOW;
    } else {
      processDefinition = this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_WORKFLOW :
        ProcessInstanceConstants.NEW_PRODUCT_DSD_WORKFLOW;
    }

    const apNumber = this.candidate.vendor?.apNumber ? this.candidate.vendor.apNumber : null;
    this.workflowService.deleteProcessInstanceAndCreateNewFlow(this.task.processInstanceId, this.candidate.candidateId, processDefinition,
      apNumber, Task.PROCUREMENT_SUPPORT_REVIEW_ACTIVITY_ID).subscribe((task) => {
      this.task = task;
      // update url with new task id.
      window.history.replaceState({}, 'Title', '#/procurementSupportProductReview?taskId=' + this.task.id);
      // if the plu stayed the same (we didn't save yet), and the workflow changed, save the change to the plu type so there's no mismatch
      // between the plu type and flow (scale vs non scale).
      if (saveCandidate) {
        this.save();
      }
    });
  }

  editCategory() {
    this.editCandidateModalService.openModal(AttributeTypes.CategorySelection,  this.candidateProduct, {},
      {contentStyle: { 'min-width': '700px', overflow: 'none' }}).subscribe((response) => {
      if ( response ) {
        // Dispatch Update
        this.candidateProduct = response;
        this.candidate.candidateProducts[0] = this.candidateProduct;
        this.isLoadingMatHierarchyData = true;
        this.supplierProductService.hasHierarchyUpdateChanges = false;
        this.supplierProductService.hierarchyAttributes = [];
        this.supplierProductService.hierarchyNumberToAttributesMap = new Map();
        this.matUtilService.setMatHierarchyList(this.candidate, this.supplierProductService.hierarchyAttributes).pipe(
          tap(() => {
            this.matUtilService.setHierarchyNumberToAttributesMap(this.supplierProductService.hierarchyAttributes,
              this.supplierProductService.hierarchyNumberToAttributesMap);
            this.matUtilService.updateMatHierarchyErrors(this.candidateProductError,
              this.supplierProductService.globalAttributes, this.supplierProductService.hierarchyAttributes);
          }),
          finalize(() => { this.isLoadingMatHierarchyData = false; } )
        ).subscribe();
      }
    });
  }

  private rejectTaskByCandidateId(candidateId) {
    this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidateId).subscribe(task => {
      if (task.name === Task.KEY_BUYER_DATA) {
        this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.KEY_BUYER_DATA_REJECT_DECISION).subscribe();
      } else if (task.name === Task.PIA_FINAL_REVIEW) {
        this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe();
      } else if (task.name === Task.ASSIGN_WAREHOUSE) {
        // if it's an sca task, move to next, then reject from PIA.
        this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE)
          .subscribe(() => {
            this.workflowService.getTaskByCandidateIdWithVariablesForInternalUser(candidateId)
              .subscribe(piaTask => {
                this.workflowService.completeTaskWithAction(piaTask, WorkflowService.ACTION_COMPLETE,
                  TaskDecision.PIA_FINAL_REVIEW_REJECT_DECISION).subscribe();
              });
          });
      } else if (task.name === Task.KEY_VENDOR_DATA) {
        this.workflowService.completeTaskWithAction(task, WorkflowService.ACTION_COMPLETE,
          TaskDecision.KEY_VENDOR_DATA_DECISION_YES).subscribe();
      }
    });
  }

  editShowCalories() {
    this.editCandidateModalService
      .openModal(AttributeTypes.ShowCalories, this.candidate,
        {description: `If checked, we'll pull nutritional information for the Own Brand Regulatory team to review.`,
          showWarningMessage: false})
      .subscribe(response => {
        if (response) {
          if (response.showCalories !== this.candidate.showCalories) {
            this.candidate = response;
            let processDefinitionToUse;
            if (this.candidateUtilService.isScaleProduct(this.candidate)) {
              if (response.showCalories) {
                if (this.task.name === Task.PIA_OB_REG_FLOW_ACTIVATE) {
                  // already been approved by OB reg, so route to first state of PS
                  this.processStateChange(true);
                }
              }
            } else if (!this.candidateUtilService.isScaleProduct(this.candidate)) {
              if (response.showCalories) {
                processDefinitionToUse =
                  this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_SCALE_WORKFLOW : ProcessInstanceConstants.NEW_PRODUCT_DSD_SCALE_WORKFLOW;
                this.processFlowChange(true, processDefinitionToUse);
              }
              if (!response.showCalories) {
                if (this.task.name === Task.PIA_OB_REG_FLOW_ACTIVATE) {
                  // already been approved by OB reg, so route to first state of PS
                  this.processStateChange(true);
                } else {
                  processDefinitionToUse =
                    this.candidate.warehouseSwitch ? ProcessInstanceConstants.NEW_PRODUCT_WAREHOUSE_WORKFLOW : ProcessInstanceConstants.NEW_PRODUCT_DSD_WORKFLOW;
                  this.processFlowChange(true, processDefinitionToUse);
                }
              }
            } else {
              this.save();
            }
          }
        }
      });
  }

  processStateChange(saveCandidate: boolean) {
    const apNumber = this.candidate.vendor?.apNumber ? this.candidate.vendor.apNumber : null;
    const processVariables: ProcessVariables[] = [];
    processVariables.push({
      candidateId: this.candidate.candidateId, apNumber: apNumber, moveToWorkflowState: Task.PROCUREMENT_SUPPORT_REVIEW_ACTIVITY_ID
    });
    this.workflowService.updateCandidateTasksStates(processVariables).subscribe((task) => {
      this.task = task;
      // update url with new task id.
      window.history.replaceState({}, 'Title', '#/procurementSupportProductReview?taskId=' + this.task[0].id);
      // if the plu stayed the same (we didn't save yet), and the workflow changed, save the change to the plu type so there's no mismatch
      // between the plu type and flow (scale vs non scale).
      if (saveCandidate) {
        this.save();
      }
    });
  }

  processFlowChange(saveCandidate: boolean, processDefinition) {
    const apNumber = this.candidate.vendor?.apNumber ? this.candidate.vendor.apNumber : null;
    this.workflowService.deleteProcessInstanceAndCreateNewFlow(this.task.processInstanceId, this.candidate.candidateId, processDefinition,
      apNumber, Task.PROCUREMENT_SUPPORT_REVIEW_ACTIVITY_ID).subscribe((task) => {
      this.task = task;
      // update url with new task id.
      window.history.replaceState({}, 'Title', '#/procurementSupportProductReview?taskId=' + this.task.id);
      // if the plu stayed the same (we didn't save yet), and the workflow changed, save the change to the plu type so there's no mismatch
      // between the plu type and flow (scale vs non scale).
      if (saveCandidate) {
        this.save();
      }
    });
  }

}
