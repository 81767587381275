import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  BaseAttributeDisplayComponent
} from 'pm-components/lib/shared/attribute-display/base-attribute-display/base-attribute-display.component';
import { ImageLinksOmissionReason } from 'pm-models/lib/imageLinksOmissionReason';
import { AttributeConfig, AttributeProductImagesConfig, AttributeTextInputConfig, CandidateProduct } from 'pm-models';
import { ImageLinkService } from '../../../../../../../src/app/2.0.0/service/image-link.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'pm-attribute-display-product-images',
  templateUrl: './attribute-display-product-images.component.html',
  styleUrls: ['./attribute-display-product-images.component.scss'],
})
export class AttributeDisplayProductImagesComponent extends BaseAttributeDisplayComponent implements OnInit {
  @Input()
  attribute: AttributeProductImagesConfig;

  public imageLinkOmissionReasons: ImageLinksOmissionReason[] = [];
  public selectedOmissionReason: ImageLinksOmissionReason;
  public otherOmissionReason: string;

  constructor(private imageLinkService: ImageLinkService, public permissionService: NgxPermissionsService) {
    super();
  }

  imageUploadConfiguration: AttributeConfig = {
    label: '',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isHidden: () => false,
    isRequired: false
  };

  imageLinksOtherOmissionReasonConfig: AttributeTextInputConfig = {
    placeholderText: 'Provide a custom reason...',
    maxLength: 100,
    isReadOnly: () => !this.isOtherOmissionReasonSelected(),
    isRequired: false,
    name: 'imageLinksOtherOmissionReasonConfig'
  };

  dateRequiredConfiguration: AttributeConfig = {
    placeholderText: 'Enter date...',
    isReadOnly: () => !this.isDateRequiredOmissionReasonSelected(),
    name: 'dateRequired',
    isRequired: false
  };

  ngOnInit(): void {
    this.initializeOmissionReasons();
  }

  initializeOmissionReasons() {
    if (!this.model) {
      return;
    }
    if (!this.attribute?.showOmissionReasons) {
      this.clearImageLinksOmissionReason();
    } else {
      if (this.permissionService.getPermission('ROLE_IMAGE_OMISSION_REASONS')) {
        this.setImageLinksOmissionReasons();
      } else {
        this.clearImageLinksOmissionReason();
      }
    }
  }

  displayOmissionReasons(): boolean {
    return this.attribute?.showOmissionReasons && !this.hasImages() && !!this.imageLinkOmissionReasons.length;
  }

  imageChange(e) {
    this.modelChange.emit(this.model);
  }

  hasImages(): boolean {
    return !!this.model?.imageLinks?.length;
  }

  isOtherOmissionReasonSelected(): boolean {
    return this.model?.imageLinksOmissionReasonCode === CandidateProduct.OTHER_OMISSION_REASON_CODE;
  }

  isDateRequiredOmissionReasonSelected(): boolean {
    return !!this.model?.imageLinksOmissionDateRequired;
  }

  private setImageLinksOmissionReasons() {
    if (this.isOtherOmissionReasonSelected()) {
      this.otherOmissionReason = this.model?.imageLinksOmissionReasonDescription;
    }
    this.imageLinkService.getImageLinksOmissionReasons().subscribe((imageLinksOmissionReasons) => {
      this.imageLinkOmissionReasons = imageLinksOmissionReasons;
      if (this.model?.imageLinksOmissionReasonCode) {
        this.selectedOmissionReason = this.imageLinkOmissionReasons?.find(reason =>
          reason.imageLinksOmissionReasonCode === this.model?.imageLinksOmissionReasonCode);
      } else {
        this.selectedOmissionReason = null;
      }
    });
  }

  public clearImageLinksOmissionReason() {
    this.selectedOmissionReason = null;
    this.otherOmissionReason = null;
    if (this.model) {
      this.model.imageLinksOmissionReasonCode = null;
      this.model.imageLinksOmissionReasonDescription = null;
      this.model.imageLinksOmissionDateRequired = null;
      this.model.imageLinksOmissionDate = null;
      this.modelChange.emit(this.model);
    }
    if (this.attributeError) {
      this.attributeError.imageLinks = null;
      this.attributeError.imageLinksOmissionDate = null;
    }
  }

  public onImageLinksOmissionReasonSelection(event: ImageLinksOmissionReason) {
    // if nothing has changed, only update the omission metadata as necessary.
    if (event?.imageLinksOmissionReasonCode === this.model.imageLinksOmissionReasonCode) {
      this.model.imageLinksOmissionDateRequired = event.imageLinksOmissionDateRequired;
      if (!this.isOtherOmissionReasonSelected()) {
        this.model.imageLinksOmissionReasonDescription = event.imageLinksOmissionReasonDescription;
      }
    } else {
      this.model.imageLinksOmissionReasonCode = event.imageLinksOmissionReasonCode;
      this.model.imageLinksOmissionDateRequired = event.imageLinksOmissionDateRequired;
      this.model.imageLinksOmissionDate = null;
      this.otherOmissionReason = null;
      if (this.isOtherOmissionReasonSelected()) {
        this.model.imageLinksOmissionReasonDescription = null;
      } else {
        this.model.imageLinksOmissionReasonDescription = event.imageLinksOmissionReasonDescription;
      }
      if (this.attributeError) {
        this.attributeError.imageLinks = null;
        this.attributeError.imageLinksOmissionDate = null;
      }
    }
    this.selectedOmissionReason = event;
    this.modelChange.emit(this.model);
  }

  setOtherOmissionReason() {
    this.model.imageLinksOmissionReasonDescription = this.otherOmissionReason;
    this.modelChange.emit(this.model);
  }

  dateRequiredChange(event) {
  }

  showGenericOmissionReasonError() {
    return !this.isOtherOmissionReasonSelected() && !this.isDateRequiredOmissionReasonSelected() && this.attributeError?.imageLinks;
  }

  getOtherOmissionReasonError() {
    return this.isOtherOmissionReasonSelected() ? this.attributeError?.imageLinks : null;
  }

  protected readonly CandidateProduct = CandidateProduct;
}
