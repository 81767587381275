import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {
  AttributeConfig, AttributeTextInputConfig,
  CandidateProduct,
  CandidateProductError,
  LabelProductImagesCardErrors,
  LabelProductImagesCardModel,
  LabelProductImagesCardPermissions
} from 'pm-models';
import {DisabledPermissionsMapper, ReadOnlyPermissionsMapper} from '../attribute-permissions-mapper';
import {CandidateUtilService} from '../../../../../../src/app/2.0.0/service/candidate-util.service';
import {LabelInsightService} from '../../../../../../src/app/2.0.0/service/label-insight.service';

@Component({
  selector: 'pm-label-insights-image-card',
  templateUrl: './label-insights-image-card.component.html',
  styleUrls: ['./label-insights-image-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LabelInsightsImageCardComponent implements OnInit {

  termsAndConditionsVisible: boolean = false;
  checkboxLabel: string = 'I have read and accept the ';
  imageGuideUrl = '/candidates/training_guides/LI_Image_Guide.pdf';

  @ViewChild('termsAndConditionsOverlay') termsAndConditionsOverlay;

  @Input()
  model: LabelProductImagesCardModel | CandidateProduct;

  @Output() modelChange = new EventEmitter<any>();

  @Output() omissionReasonChange = new EventEmitter<any>();

  @Input()
  permissions: LabelProductImagesCardPermissions;

  @Input()
  errorModel: LabelProductImagesCardErrors | CandidateProductError;

  public LABEL_INSIGHT_REASONS = [];
  public otherReason;

  constructor(public candidateUtilService: CandidateUtilService, public labelInsightService: LabelInsightService) {}

  ngOnInit() {
    this.validateLabelInsights();
    this.getLabelInsightReasons();
  }

  labelImageUploadConfiguration: AttributeConfig = {
    label: '',
    description: '',
    isDisabled: () => DisabledPermissionsMapper(this.permissions, this.permissions.labelInsightsImageLinks),
    isReadOnly: () => ReadOnlyPermissionsMapper(this.permissions, this.permissions.labelInsightsImageLinks),
    isRequired: false
  };

  reasonConfiguration: AttributeConfig = {
    label: 'Reason',
    description: '',
    isDisabled: () => false,
    isReadOnly: () => false,
    isRequired: true,
    inputGroupClass: 'attribute-radios-block',
    options: this.LABEL_INSIGHT_REASONS,
  };

  labelInsightOtherReasonConfig: AttributeTextInputConfig = {
    placeholderText: 'Provide a custom reason...',
    maxLength: 100,
    isReadOnly: () => !this.isOther(),
    isRequired: false,
    name: 'labelInsightOtherConfig'
  };



  imageChange(e) {
    this.modelChange.emit(this.model);
    this.validateLabelInsights();
  }

  termsAndConditionsChange(e) {
    this.modelChange.emit(this.model);
    this.validateLabelInsights();
  }

  validateLabelInsights() {
    if (this.model.labelInsightsImageLinks?.length > 0 && !this.model.termsAndConditions) {
      this.errorModel.termsAndConditions = 'You must accept the terms and conditions to upload an image for label insights. Read and accept the terms or remove the image.';
    } else {
      this.clearLabelInsightsError();
    }
  }

  public clearLabelInsightsReason() {
    this.model.labelInsightOmissionReasonDesc = null;
    this.model.labelInsightOmissionReasonCode = null;
    this.model.labelInsightOmissionReason = null;
    this.otherReason = null;
    this.modelChange.emit(this.model);
  }

  private clearLabelInsightsError() {
    if (this.errorModel) {
      this.errorModel.termsAndConditions = undefined;
    }
  }

  toggleTermsAndConditions(event) {
    this.termsAndConditionsOverlay.show('card', null);
    this.termsAndConditionsVisible = !this.termsAndConditionsVisible;
  }

  declineTermsAndConditions(event) {
    this.termsAndConditionsOverlay.hide();
    this.model.termsAndConditions = false;
    this.validateLabelInsights();
    return event;
  }

  acceptTermsAndConditions(event) {
    this.termsAndConditionsOverlay.hide();
    this.model.termsAndConditions = true;
    this.validateLabelInsights();
    return event;
  }

  private getLabelInsightReasons() {
    this.model.labelInsightOmissionReason = this.model.labelInsightOmissionReasonCode;
    if (this.isOther()) {
      this.otherReason = this.model.labelInsightOmissionReasonDesc;
    }
    this.labelInsightService.getLabelInsightReasons().subscribe((labelInsightReasonsList) => {
      for (let x = 0; x <= labelInsightReasonsList.length; x++) {
        const labelInsightReason = labelInsightReasonsList[x];
        if (labelInsightReason) {
          this.LABEL_INSIGHT_REASONS.push({
            label: labelInsightReason.description,
            value: labelInsightReason.reasonCode
          });
        }
      }
    });
  }

  public onLabelInsightOmissionReasonSelection(event) {
    // if nothing has changed, do nothing (e.g. user clicked other after already selected, don't clear out the reason).
    if (event === this.model.labelInsightOmissionReasonCode) {
      return;
    }
    this.model.labelInsightOmissionReason = event;
    this.model.labelInsightOmissionReasonCode = this.model.labelInsightOmissionReason;
    const currentReason = this.LABEL_INSIGHT_REASONS.find(entry => entry.value === event);
    if (!this.isOther()) {
      if (currentReason) {
        this.model.labelInsightOmissionReasonDesc = currentReason.label;
        this.otherReason = '';
      }
    } else {
      this.model.labelInsightOmissionReasonDesc = null;
    }
    this.modelChange.emit(this.model);
  }

  hasImages() {
    return this.model.labelInsightsImageLinks?.length > 0;
  }

  isOther() {
    return this.model.labelInsightOmissionReason === CandidateProduct.OTHER_OMISSION_REASON_CODE;
  }

  setOtherReason() {
    this.model.labelInsightOmissionReasonDesc = this.otherReason;
    this.modelChange.emit(this.model);
  }

  getOtherError() {
    if (this.isOther()) {
      return this.errorModel?.labelInsightsImageLinks;
    } else {
      return null;
    }
  }

  getReasonError() {
    if (!this.isOther()) {
      return this.errorModel?.labelInsightsImageLinks;
    } else {
      return null;
    }
  }
}
