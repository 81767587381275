import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { CandidateProduct } from 'pm-models';

@Injectable({
  providedIn: 'root'
})
export class LabelInsightService {
  constructor(private http: HttpClient) {
  }

  private LABEL_INSIGHT_BASE_URL = 'candidates/labelInsight';
  private OMISSION_REASONS_URL = '/omissionReasons';

  public getLabelInsightReasons(): Observable<any> {
    return this.http.get<any>(this.LABEL_INSIGHT_BASE_URL + this.OMISSION_REASONS_URL);
  }

  public getLabelInsightReason(candidateProduct: CandidateProduct) {
    const reasonPrefix = 'None uploaded — ';
    if (!candidateProduct.labelInsightOmissionReasonCode || !candidateProduct.labelInsightOmissionReasonDesc) {
      return reasonPrefix + ' No reason provided.';
    } else if (candidateProduct.labelInsightOmissionReasonCode === CandidateProduct.OTHER_OMISSION_REASON_CODE) {
      return reasonPrefix + ' "' + candidateProduct.labelInsightOmissionReasonDesc + '"';
    } else {
      return reasonPrefix + ' "' + candidateProduct.labelInsightOmissionReasonDesc + '."';
    }
   }

  hasLabelInsightImages(labelInsightImages: any[]) {
    return (labelInsightImages && (labelInsightImages.length > 0));
  }

  isPdf(file) {
    if (file?.type === 'application/pdf') {
      return true;
    }
  }
}
