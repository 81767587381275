<pm-review #pmReview *ngIf="isViewingPage" (closeEvent)="onClose()">

  <!-- Page Title -->
  <pm-title>{{candidate.description}}</pm-title>
  <pm-sub-title>{{getTitleSubHeading()}}</pm-sub-title>
  <pm-app-reject-status-header [candidate]="candidate"></pm-app-reject-status-header>
  <div class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages.length > 0 ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div *ngIf="!candidateUtilService.isPlu(candidate)" class="upc-header">
            UPC: {{candidate.candidateProducts[0].upc}}-{{candidate.candidateProducts[0].upcCheckDigit}}
          </div>
          <div *ngIf="candidateUtilService.isPlu(candidate)" class="upc-header">
            <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
              PLU: {{candidate.candidateProducts[0].upc | upc}}-{{candidate.candidateProducts[0].upc | upcCheckDigit}}<br>
            </div>
            <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
              UPC: {{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc)}}-{{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc) | upcCheckDigit}}
            </div>
          </div>
          <div class="attribute-description">
            <p>{{candidateProduct.description}}</p>
            <p>{{getProductInfoString()}}</p>
          </div>
          <button pButton type="button"
                  [label]="'View candidate history'"
                  class="ui-button-link" (click)="historyPanelOpen()">
          </button>
        </div>
      </div>

      <!--   Cost set by supplier  -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Cost set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'prePrice')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'costLinked')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="false"[hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'costLink')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'suggestedRetailPrice')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterListCost')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
                Penny profit: {{costService.getPennyProfit(candidate)}}<br>
                Margin: {{costService.getMargin(candidate)}}%
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'mapRetail')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Cost set by supplier  -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Cost set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterListCost')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate.unitCost)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--Case summary-->
      <pm-attribute-grid [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'caseDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct,  'channel')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct,  'caseUpc')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct,  'countryOfOrigin', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct,  'vendorProductCode')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--Retail set by buyer-->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Retail set by buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate,'retailType')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.retailType}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              {{costService.getHebMargin(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate,'retailXFor') ||
                                        candidateHistoryService.hasBuyerValueChangedForCandidate(candidate,'retailPrice')"
                          (historyClicked)="historyPanelOpen()">
              {{costService.getRetailPricing(candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{costService.getHebPennyProfit(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid *ngIf="candidate.dsdSwitch" [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterPack')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.masterPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell *ngIf="!supplierProductService.isDsdOnlyParms(candidate)" [label]="'Weight type'">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false" >
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false">
              {{candidate | codeDateDays: candidate.maxShelfLife}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="false">
              {{candidate | codeDateDays: candidate.inboundSpecDays}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid *ngIf="!candidate.dsdSwitch" [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterPack')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTie') || candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendorTier')"
                          (historyClicked)="historyPanelOpen()">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'itemWeightType')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'masterWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'cubeAdjustedFactor')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'codeDate')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'maxShelfLife')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate | codeDateDays: candidate.maxShelfLife}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'inboundSpecDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate | codeDateDays: candidate.inboundSpecDays}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--  inner packs -->
      <pm-attribute-grid [title]="'Inner packs'" *ngIf="!candidate.dsdSwitch">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs'" [canEdit]="false">
              {{candidate.innerPackSelected | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerPack')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.innerPack }}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getInnerDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'innerWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnit')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnit | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group *ngIf="candidate.displayReadyUnit">
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitOrientation')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsDeep') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsFacing') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'displayReadyUnitRowsHigh')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.displayReadyUnit ? candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh : 0}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit type'" [canEdit]="false">
              {{candidateUtilService.getDruType(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Display ready units, not showing for non sellable -->

      <!-- Product details from buyer -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'merchandiseType', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.merchandiseType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'PSS department'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate,'pssDepartment', 'displayName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'commodity', 'commodityName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'subCommodity', 'displayName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.subCommodity?.subCommodityName}} [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>

          </pm-grid-column>

          <pm-grid-column>

            <pm-grid-cell [label]="'Like item code'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'likeId')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.likeId}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season & Year'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'seasonYear') || candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'season', 'seasonDescription')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>


            <pm-grid-cell [label]="'Estimated store count'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'numberOfStores')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.numberOfStores}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Sold by weight'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'weightSwitch')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.weightSwitch | yesNo}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'warehouseReactionDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'guaranteeToStoreDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Product details from buyer -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'merchandiseType', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.merchandiseType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'warehouseReactionDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'PSS department'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'pssDepartment', 'displayName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'commodity', 'commodityName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.commodity?.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'guaranteeToStoreDays')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'subCommodity', 'displayName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.subCommodity?.subCommodityName}}  [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--  Warehouses -->
      <pm-attribute-grid *ngIf="!candidate.dsdSwitch"  [title]="'Warehouses'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Max ship'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasScaValueChangedForCandidate(candidate, 'maxShip')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.maxShip}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <ng-template ngFor let-warehouse [ngForOf]="candidateProduct.warehouses" let-index="index">

          <pm-grid-group>
            <pm-grid-column>
              <pm-grid-cell [label]="'Warehouse'" [canEdit]="false" class="warehouse-grid-cell">
                {{warehouse.name}}
                <pm-grid-cell-info>
                  ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
                </pm-grid-cell-info>
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order unit'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasScaValueChangedForWarehouse(candidateProduct, 'orderUnit', 'description')"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderUnit?.description}}
              </pm-grid-cell>
            </pm-grid-column>
            <pm-grid-column>
              <pm-grid-cell [label]="'Order restriction'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasScaValueChangedForWarehouse(candidateProduct, 'orderRestriction', 'description')"
                            (historyClicked)="historyPanelOpen()">
                {{warehouse.orderRestriction?.displayName}}
              </pm-grid-cell>
            </pm-grid-column>
          </pm-grid-group>
          <div *ngIf="index !== candidateProduct.warehouses.length - 1" class="grid-divider"></div>
        </ng-template>
      </pm-attribute-grid>

      <!-- Authorized stores -->
      <pm-attribute-grid *ngIf="supplierProductService.isDsdOnlyParms(candidate)" [title]="'Authorized stores'">
        <pm-grid-group *ngFor="let locationGroupStore of candidate.candidateProducts[0].locationGroupStores">
          <pm-grid-column>
            <pm-grid-cell [label]="'Distributor'">
              <div>{{getVendor(locationGroupStore.splrLocationNumber)?.displayName}}</div>
              <pm-grid-cell-info>
                <b>Total stores: {{locationGroupStore.stores.length}} of {{getNumberOfStoresForGroup(locationGroupStore.splrLocationGroupId)}} stores</b>
              </pm-grid-cell-info>
            </pm-grid-cell>

            <pm-grid-cell [label]="'Authorization group'">
              <div>{{locationGroupStore.splrLocationGroupId}}</div>
              <pm-grid-cell-info>
                <div *ngIf="locationGroupStore.listCost">${{locationGroupStore.listCost| number : '1.2-2'}}</div>
                <div *ngIf="!locationGroupStore.listCost">${{candidate?.masterListCost| number : '1.2-2'}}</div>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Stores'">
              <div class="mb-2">{{getAuthGroupLabel(locationGroupStore)}}</div>
              <div *ngFor="let store of locationGroupStore.stores">
                <div class="ui-grid-col-2 mr-2 mb-2" style="background: #f2f2f2; text-align: center;">{{store.custLocationNumber}}</div>
              </div>
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'brand', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.brand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'subBrand')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCostOwner(candidate)"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.costOwner?.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'contactName') ||
                          candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'contactEmail')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForTopToTop(candidate)"
                          (historyClicked)="historyPanelOpen()">
              <input type="text" pInputText value="{{candidate.costOwner?.topToTopName}}"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'vendor', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.vendor?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="!supplierProductService.isDsdOnlyParms(candidate)" [label]="'Warehouse supplier'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'lane', 'name')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.lane?.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'buyer', 'buyerName')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.buyer?.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Sellable'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productType')"
                          (historyClicked)="historyPanelOpen()">
              {{getSellableString()}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product line, packaging, & regulatory info'">
        <pm-grid-group>
          <pm-grid-column>

            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'foodStamp')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'taxable')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'FSA/HSA eligible'" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'flexibleSpendingAccount')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'packageType', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'unitOfMeasure', 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'retailSize')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>

            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productWeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'totalVolume')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Qualifying conditions'" [canEdit]="false">
              {{candidateProduct?.qualifyingCondition?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Show Calories'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'showCalories')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.showCalories | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Tax category'"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'vertexTaxCategoryModel', 'id')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.vertexTaxCategoryModel?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line & Packaging -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product line & Packaging'">
        <pm-grid-group>

          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{candidate.packageType?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{candidate.unitOfMeasure?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Qualifying conditions'">
              {{candidateProduct?.qualifyingCondition?.description}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false"
                          [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productLength') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productWidth') ||
                                        candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'productHeight')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Show Calories'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'showCalories')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.showCalories | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'totalVolume')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.totalVolume}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidate(candidate, 'retailSize')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.retailSize}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Tax category'"
                          [hasChanged]="candidateHistoryService.hasBuyerValueChangedForCandidate(candidate, 'vertexTaxCategoryModel', 'id')"
                          (historyClicked)="historyPanelOpen()">
              {{candidate.vertexTaxCategoryModel?.displayName}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Import Info  -->
      <pm-attribute-grid *ngIf="!candidateProduct?.imported" [title]="'Imported product'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Imported product'" [canEdit]="false">
              {{candidateProduct?.imported | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Import Info  -->
      <pm-attribute-grid *ngIf="candidateProduct?.imported" [title]="'Imported product'">
        <pm-grid-group>
          <pm-grid-column>

            <pm-grid-cell [label]="'Imported product'" [canEdit]="false">
              {{candidateProduct?.imported | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Pickup point'" [canEdit]="false">
              {{candidateProduct?.importInfo?.pickupPoint}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Duty %'" [canEdit]="false">
              {{candidateProduct?.importInfo?.dutyPercent}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HTS1'" [canEdit]="false">
              {{candidateProduct?.importInfo?.hts1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Carton marking'" [canEdit]="false">
              {{candidateProduct?.importInfo?.cartonMarking}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Instore date'" [canEdit]="false">
              {{candidateProduct?.importInfo?.inStoreDate | date}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Container size'" [canEdit]="false">
              {{candidateProduct?.importInfo?.containerSize.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Agent %'" [canEdit]="false">
              {{candidateProduct?.importInfo?.agentPercent}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Duty confirmed'" [canEdit]="false">
              {{candidateProduct?.importInfo?.dutyConfirmed | date}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HTS2'" [canEdit]="false">
              {{candidateProduct?.importInfo?.hts2}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Product color'" [canEdit]="false">
              {{candidateProduct?.importInfo?.productColor}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'WHS flush date'" [canEdit]="false">
              {{candidateProduct?.importInfo?.whsFlushDate | date}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Inco terms'" [canEdit]="false">
              {{candidateProduct?.importInfo?.incoTerms.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Proraction date'" [canEdit]="false">
              {{candidateProduct?.importInfo?.prorationDate | date}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Duty info'" [canEdit]="false">
              {{candidateProduct?.importInfo?.dutyInfo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HTS3'" [canEdit]="false">
              {{candidateProduct?.importInfo?.hts3}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Freight confirmed'" [canEdit]="false">
              {{candidateProduct?.importInfo?.freightConfirmedDate | date}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Import factory'" [canEdit]="false">
              {{candidateProduct?.importInfo?.factory.factoryName}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Minimum order'" [canEdit]="false">
              {{candidateProduct?.importInfo?.minimumOrderQty}}, {{candidateProduct?.importInfo?.minimumOrderDesc}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--Scale information-->
      <pm-attribute-grid *ngIf="candidateUtilService.isScaleProduct(candidate)" [title]="'Scale information'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Ingredient stmt #'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.ingredientStatementNumber}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Pre-pack tare'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.prePackTare}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf life'" [canEdit]="false">
              {{candidateUtilService.getDaysStringFromValue(candidateProduct?.scaleInformation?.shelfLifeDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Graphics code 1'" [canEdit]="false">
              {{(candidateProduct?.scaleInformation?.graphicsCodeOne ? candidateProduct?.scaleInformation?.graphicsCodeOne : candidateProduct?.scaleInformation?.graphicsCode) | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Action code'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.prePackTare}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Nutrition stmt #'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.nutritionStatementNumber | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Service counter tare'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.serviceCounterTare | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Mechanically tend.'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.mechanicallyTenderized | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Graphics code 2'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.graphicsCodeTwo | emptyToValue: '-'}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Grade #'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.gradeNumber | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Force tare'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.forceTare | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Net weight'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.netWeight | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Graphics code 3'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.graphicsCodeThree | emptyToValue: '-'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <pm-attribute-grid *ngIf="candidateUtilService.isScaleProduct(candidate)" [title]="'Scale labels'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'English label 1'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.englishLabelOne | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'English label 3'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.englishLabelThree | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Bilingual label'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.bilingual | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="candidateProduct?.scaleInformation?.bilingual" [label]="'English label 1 (bilingual)'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.englishLabelOneBilingual | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="candidateProduct?.scaleInformation?.bilingual" [label]="'Spanish label 1'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.spanishLabelOne | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Label format 1'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.labelFormatOne | emptyToValue: '-'}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'English label 2'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.englishLabelTwo | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'English label 4'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.englishLabelFour | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
            <pm-grid-cell *ngIf="candidateProduct?.scaleInformation?.bilingual" [label]="'English label 2 (bilingual)'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.englishLabelTwoBilingual | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell *ngIf="candidateProduct?.scaleInformation?.bilingual" [label]="'Spanish label 2'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.spanishLabelTwo | emptyToValue: '-'}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Label format 2'" [canEdit]="false">
              {{candidateProduct?.scaleInformation?.labelFormatTwo | emptyToValue: '-'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Unit Details -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'upc')"
                            (historyClicked)="historyPanelOpen()">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'upc')"
                            (historyClicked)="historyPanelOpen()">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'customerFriendlyDescription1')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.customerFriendlyDescription1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'customerFriendlyDescription2')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.customerFriendlyDescription2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'romanceCopy')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.romanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div *ngIf="!!productImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!productImages?.length">
                {{candidateUtilService.getImageLinksOmissionReasons(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Unit Details -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'upc')"
                            (historyClicked)="historyPanelOpen()">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'upc')"
                            (historyClicked)="historyPanelOpen()">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'" [canEdit]="false" [hasChanged]="candidateHistoryService.hasSupplierValueChangedForCandidateProduct(candidateProduct, 'description')"
                          (historyClicked)="historyPanelOpen()">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div *ngIf="!!productImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!productImages?.length">
                {{candidateUtilService.getImageLinksOmissionReasons(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <div *ngIf="showMatAttributes">
        <div *ngIf="candidateUtilService.isInProgressCandidate(candidate)">
          <!--  Global Attributes    -->
          <div>
            <pm-attribute-grid [title]="'Extended attributes: Product'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid [title]="'Extended attributes: UPC'">
              <div *ngIf="isLoadingMatData" style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="supplierProductService?.candidate?.warehouseSwitch && isLoadingMatData" [title]="'Extended attributes: Case'">
              <div style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
            </pm-attribute-grid>
            <pm-attribute-grid *ngIf="supplierProductService?.candidate?.warehouseSwitch && !isLoadingMatData && !!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>

          <!--   Mat Hierarchy   -->
          <div>
            <pm-attribute-grid *ngIf="isLoadingMatData && !!matUtilService.getMatHierarchyList(supplierProductService?.candidate)?.length" [title]="'Category attributes'">
              <div style="display: flex; align-items: center">
                <pm-progress-spinner [showSpinner]="isLoadingMatData" [strokeWidth]="'2'"
                                     [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
                <h5 class="ml-3">Fetching extended attribute data...</h5>
              </div>
            </pm-attribute-grid>
            <div *ngIf="!!hierarchyNumberToAttributesMap?.size">
              <div *ngFor="let hierarchy of candidate.candidateProducts[0].matHierarchyList">
                <pm-attribute-grid *ngIf="hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
                  <pm-grid-group>
                    <pm-grid-column>
                      <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>
                    <pm-grid-column>
                      <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                        <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                          <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                              {{value.description}} <br>
                            </div>
                          </div>
                          <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                          </div>

                        </pm-grid-cell>
                      </div>
                    </pm-grid-column>

                  </pm-grid-group>
                </pm-attribute-grid>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!candidateUtilService.isInProgressCandidate(candidate)">
          <div>
            <pm-attribute-grid *ngIf="!!productAttributes?.length" [title]="'Extended attributes: Product'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of productAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="!!upcAttributes?.length" [title]="'Extended attributes: UPC'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of upcAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>

            <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && !!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
              <pm-grid-group>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>
                <pm-grid-column>
                  <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                    <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                      <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                        <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                          {{value.description}} <br>
                        </div>
                      </div>
                      <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                        {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                      </div>

                    </pm-grid-cell>
                  </div>
                </pm-grid-column>

              </pm-grid-group>
            </pm-attribute-grid>
          </div>


          <!--   Mat Hierarchy   -->
          <div *ngIf="!!candidate?.candidateProducts[0]?.matHierarchyList?.length">
            <div *ngFor="let hierarchy of candidate.candidateProducts[0].matHierarchyList">
              <pm-attribute-grid *ngIf="!!hierarchy.attributes?.length" [title]="'Category attributes: ' + hierarchy.name">
                <pm-grid-group>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>
                  <pm-grid-column>
                    <div *ngFor="let attribute of hierarchy.attributes; let i = index">
                      <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                        <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                            {{value.description}} <br>
                          </div>
                        </div>
                        <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                          {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                        </div>

                      </pm-grid-cell>
                    </div>
                  </pm-grid-column>

                </pm-grid-group>
              </pm-attribute-grid>
            </div>
          </div>
        </div>
      </div>


      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'" *ngIf="!candidate.dsdSwitch">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="false">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="false">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="false"  [hasReviewerComment]="hasReviewerComment()">
              <div *ngIf="!!candidate.supplierComment">
                <b>{{candidate.contactEmail}}</b> <br>
                {{candidate.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>

              <br>

              <div *ngIf="!!candidate.scaComment">
                <b>{{candidate.scaName}}</b> <br>
                {{candidate.scaComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.piaComment">
                <b>{{candidate.piaCommentUser}}</b> <br>
                {{candidate.piaComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

    </div>
  </div>

  <pm-review-drawer-container>
    <!--  Audit History -->
    <ng-container *ngIf="showHistoryPanel">
      <div class="container p-3">
        <h2>History</h2>
        <div>Below is the candidate's edit history</div>
        <br>
        <div class="attributes-stacked">

          <ng-template ngFor let-candidateAudits [ngForOf]="candidateHistoryService.currentHistoryResults">
            <div *ngIf="candidateHistoryService.showCandidateAudits(candidateAudits)">
              <div>{{candidateAudits.changedBy}}</div>
              <div class="attribute-description"><p>{{candidateAudits.changedOn | date:'MM/dd/yyyy, h:mm a' : 'CT'}}</p></div>
              <br>
              <div style="font-size: small;">{{candidateAudits.attributeName}}</div>
              <div>{{candidateHistoryService.displayAudit(candidateAudits.changedTo)}}</div>
              <hr/>
            </div>
          </ng-template>

        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="historyPanelClose()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button class="ghost m-2" label="Back to home" (onClick)="onClickBackToHome()"></p-button>
    <p-button class="m-2" label="Print" (onClick)="onClickPrint()"></p-button>
  </pm-footer>
</pm-review>
